import React from 'react';
import Header1 from '../../Components/Common/Header1/Header1';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import classes from './InstitutePrivacyPolicy.module.scss';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';

function InstitutePrivacyPolicy() {
    return (
        <>
             <CustomHelmet page="InstituteTermsofservice" />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.insppHeading}>
                            <span>Privacy Policy</span>
                        </h1>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <div className={classes.body_section}>
                            <div className={classes.section_title}>
                                INTRODUCTION
                            </div>
                            <p>
                                We, at Grayquest Education Finance Private
                                Limited &#40;“GrayQuest”&#41; are committed to
                                respecting your online privacy and recognize
                                your need for appropriate protection and
                                management of any personally identifiable
                                information &#40;"Personal Information"&#41; you
                                share with us.
                            </p>
                            <p>
                                "Personal Information" means any information
                                that may be used to identify an individual,
                                including, but not limited to, a first and last
                                name, a home or other physical address and an
                                email address or other contact information,
                                whether at work or at home. In general, you can
                                visit our Web pages without telling us who you
                                are or revealing any Personal Information about
                                yourself.
                            </p>
                            <div className={classes.section_title}>
                                THIRD PARTY SERVICES
                            </div>
                            <p>
                                Third parties provide certain services available
                                on our behalf. Grayquest may provide
                                information, including Personal Information,
                                that we collects on the Web to third-party
                                service providers to help us deliver services to
                                you. Grayquest will take reasonable steps to
                                ensure that these third-party service providers
                                are obligated to protect Personal Information on
                                Grayquest behalf.
                            </p>
                            <p>
                                Grayquest does not intend to transfer Personal
                                Information without your consent to third
                                parties who are not bound to act on Grayquest
                                behalf unless such transfer is legally required.
                                Similarly, it is against Grayquest policy to
                                sell Personal Information collected online
                                without consent.
                            </p>
                            <div className={classes.section_title}>
                                YOUR CONSENT
                            </div>
                            <p>
                                By using this Web site, you consent to the terms
                                of our Online Privacy Policy and to Grayquest
                                processing of Personal Information.
                            </p>
                            <div className={classes.section_title}>
                                INFORMATION SECURITY
                            </div>
                            <p>
                                We take appropriate security measures to protect
                                against unauthorized access to or unauthorized
                                alteration, disclosure or destruction of data.
                            </p>
                            <p>
                                We restrict access to your personally
                                identifying information to employees who need to
                                know that information in order to operate,
                                develop or improve our services.
                            </p>
                            <div className={classes.section_title}>
                                UPDATING YOUR INFORMATION
                            </div>
                            <p>
                                We provide mechanisms for updating and
                                correcting your personally identifying
                                information for many of our services.
                            </p>
                            <div className={classes.section_title}>
                                INFORMATION SHARING AND DISCLOSURE
                            </div>
                            <p>
                                Grayquest does not rent, sell, or share personal
                                information about you with other people (save
                                with your consent) or non- affiliated companies
                                except to provide products or services you've
                                requested, when we have your permission, or
                                under the following circumstances.
                            </p>
                            <p>
                                We provide the information to trusted partners
                                who work on behalf of or with Grayquest under
                                confidentiality agreements. These companies may
                                use your personal information to help Grayquest
                                communicate with you about offers from
                                Grayquest. However, these companies do not have
                                any independent right to share this information.
                            </p>
                            <p>
                                We believe it is necessary to share information
                                in order to investigate, prevent, or take action
                                regarding illegal activities, suspected fraud,
                                situations involving potential threats to the
                                physical safety of any person, violations of
                                Grayquest terms of use, or as otherwise required
                                by law.
                            </p>
                            <p>
                                We transfer information about you if Grayquest
                                is acquired by or merged with another company.
                            </p>
                            <div className={classes.section_title}>
                                CONFIDENTIALITY AND SECURITY
                            </div>
                            <p>
                                To update, correct or review your Personal
                                Information that was previously provided by you
                                to use, you have the following options:
                            </p>
                            <p>
                                If you have signed up with us and have an
                                account, you may make the changes on your
                                account profile page by signing in and saving
                                the said changes/ modfications. Any change/
                                modification made by you, will be reflected on
                                the website.
                            </p>
                            <p>
                                In the alternative, you may write to us at{' '}
                                <a href='mailto:support@grayquest.com'>
                                    support@grayquest.com
                                </a>
                            </p>
                            <p>
                                Please mention the changes to be made clearly
                                and we will try and update the requite changes/
                                modifications as requested by you in your
                                letter.
                            </p>
                            <div className={classes.section_title}>
                                SENSITIVE INFORMATION
                            </div>
                            <p>
                                Kindly do not send us or disclose any sensitive
                                personal information for example information
                                relating to your racial or ethnic origin,
                                political opinions, religion or other beliefs,
                                health etc. on or through the site or otherwise
                                to us.
                            </p>
                            <div className={classes.section_title}>
                                AUTHORIZED STATEMENT
                            </div>
                            <p>
                                This Privacy Policy represents the sole privacy
                                statement of our practices with respect to the
                                collection of Personal Information through the
                                Sites and our use and disclosure of this
                                information. Any other statement or summary with
                                respect to any privacy policy published by any
                                third party shall have no binding or legal
                                effect whatsoever.
                            </p>
                            <div className={classes.section_title}>
                                CHANGES TO THIS PRIVACY POLICY
                            </div>
                            <p>
                                Grayquest reserves the right to update, change
                                or modify this policy at any time. The policy
                                shall come to effect from the date of such
                                update, change or modification.
                            </p>
                            <p>
                                Grayquest shall not be liable for any loss or
                                damage sustained by reason of any disclosure
                                (inadvertent or otherwise) of any information
                                concerning the user's account and / or
                                information relating to or regarding online
                                transactions using credit cards / debit cards
                                and / or their verification process and
                                particulars nor for any error, omission or
                                inaccuracy with respect to any information so
                                disclosed and used whether or not in pursuance
                                of a legal process or otherwise.
                            </p>
                            <p className={classes.insppBottom}>
                                Grayquest welcomes your comments regarding this
                                privacy statement at the contact address given
                                at the website. Should there be any concerns
                                about contravention of this Privacy Policy,
                                Grayquest will employ all commercially
                                reasonable efforts to address the same.
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default InstitutePrivacyPolicy;
