import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import { ENV_MODE, ERP_SDK_ENV, ERP_SDK_SCRIPT_URL, LANDING_BASE_URL } from '../../../../constants';
import { useExternalScript } from '../../../hooks';
import { SpinnerLoader } from '../../atoms';
import sendGA4Event from '../../../googleAnalytics/googleAnalytics';
import classes from './SdkForm.module.scss';
import { generateRandomStudentId } from '../../../utils/utils';

const useQuery = (searchString) => new URLSearchParams(searchString);

const SdkForm = ({ pageData }) => {
	const { sdk_block } = pageData;
	const script_state = useExternalScript(ERP_SDK_SCRIPT_URL);
	if (!sdk_block?.student_uuid_visible && !sdk_block?.mobile_visible) return null;

	const [results, setResults] = useState([]);
	const query = useQuery(location?.search);
	const [feeDetails, setFeeDetails] = useState({
		payable_amount: 0,
		annual_amount: 0,
	});
	const [isCallbackAPILoading, setIsCallbackAPILoading] = useState(false);
	const [isDisablePayButton, setIsDisablePayButton] = useState(false);
	const [selectedFeeTypeResult, setSelectedFeeTypeResult] = useState(null);
	const [allSelectedOptions, setAllSelectedOptions] = useState([]);
	const [isHideStudentIdToggle, setIsHideStudentIdToggle] = useState(false);
	const checkboxContainer = useRef();
	const [feeTypeError, setFeeTypeError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const validationSchema = Yup.object().shape({
		student_id: Yup.string().when("no_id", {
			is: false,
			then: Yup.string().required("Please enter Application number/Student ID"),
			otherwise: Yup.string(),
		}),
		mobile: Yup.string().when("no_id", {
			is: true,
			then: Yup.string()
				.required('Please enter mobile number')
				.length(10, 'Mobile number must contain 10 digits')
				.matches('^[6-9][0-9]{9}$', 'Please enter valid mobile Number'),
			otherwise: Yup.string(),
		}),
	});

	const getNotesObject = () => {
		let notesObj = {};
		notesObj['source'] = `${LANDING_BASE_URL}${window?.location?.pathname}` || "https://grayquest.com";
		notesObj['total_fee_amount'] = {
			total_annual_amount: feeDetails?.annual_amount || "-",
			total_payable_amount: feeDetails?.payable_amount || "-",
		};
		if (selectedFeeTypeResult) {
			notesObj['student_first_name'] = selectedFeeTypeResult?.student_first_name || "-";
			notesObj['student_last_name'] = selectedFeeTypeResult?.student_last_name || "-";
			notesObj['customer_first_name'] = selectedFeeTypeResult?.customer_first_name || "-";
			notesObj['customer_last_name'] = selectedFeeTypeResult?.customer_last_name || "-";
			notesObj['customer_mobile'] = selectedFeeTypeResult?.customer_mobile || "-";
			notesObj['customer_email'] = selectedFeeTypeResult?.customer_email || "-";
			notesObj['student_type'] = selectedFeeTypeResult?.student_type || "-";
		}
		if (allSelectedOptions?.length > 0) {
			let allFeeTypes = [];
			allSelectedOptions.map(option => {
				const feeObj = {};
				if (option.fee_type) {
					feeObj['fee_type'] = option?.fee_type || "-";
					feeObj['annual_amount'] = option?.annual_amount || "-";
					feeObj['payable_amount'] = option?.payable_amount || "-";
					allFeeTypes.push(feeObj);
				}
				notesObj['student_id'] = option?.student_uuid || "-";
			});
			notesObj['Fee_Types'] = allFeeTypes || [];
		}

		return notesObj;
	}

	const isObjectEmpty = (tempObj) => {
		return tempObj && Object.keys(tempObj).length === 0 && Object.getPrototypeOf(tempObj) === Object.prototype;
	}

	const formik = useFormik({
		initialValues: {
			student_id: query.get('sid') || '',
			mobile: query.get('m') || '',
			no_id: !sdk_block?.student_uuid_visible,
		},
		validationSchema,
		onSubmit: (values) => {
			setIsLoading(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 2000);
			const { student_id, mobile } = values;
			let studentDetailsObj = {};
			let customerDetailsObj = {};
			let kycDetailsObj = {};
			let residentialDetailsObj = {};
			let notesObj = getNotesObject();
			let customizationsObj = {};

			if (pageData?.logo_block?.images?.length > 0) {
				customizationsObj['logo_url'] = pageData?.logo_block?.images[0];
			}
			if (pageData?.sdk_block?.fee_helper_text) {
				const textsObj = { fee_helper_text: pageData.sdk_block.fee_helper_text }
				customizationsObj['texts'] = textsObj;
			}
			if (pageData?.sdk_block?.payable_helper_text) {
				customizationsObj['texts'] = { ...customizationsObj['texts'], payable_helper_text: pageData.sdk_block.payable_helper_text };
			}
			if (selectedFeeTypeResult?.student_first_name) {
				studentDetailsObj['student_first_name'] = selectedFeeTypeResult?.student_first_name;
			}
			if (selectedFeeTypeResult?.student_last_name) {
				studentDetailsObj['student_last_name'] = selectedFeeTypeResult?.student_last_name;
			}
			if (selectedFeeTypeResult?.student_type) {
				studentDetailsObj['student_type'] = selectedFeeTypeResult?.student_type;
			}

			if (selectedFeeTypeResult?.customer_first_name) {
				customerDetailsObj['customer_first_name'] = selectedFeeTypeResult?.customer_first_name;
			}
			if (selectedFeeTypeResult?.customer_last_name) {
				customerDetailsObj['customer_last_name'] = selectedFeeTypeResult?.customer_last_name;
			}
			if (selectedFeeTypeResult?.customer_dob) {
				customerDetailsObj['customer_dob'] = selectedFeeTypeResult?.customer_dob;
			}
			if (selectedFeeTypeResult?.customer_gender) {
				customerDetailsObj['customer_gender'] = selectedFeeTypeResult?.customer_gender;
			}
			if (selectedFeeTypeResult?.customer_email) {
				customerDetailsObj['customer_email'] = selectedFeeTypeResult?.customer_email;
			}

			if (selectedFeeTypeResult?.customer_pan_number) {
				kycDetailsObj['pan_number'] = selectedFeeTypeResult?.customer_pan_number;
			}

			if (selectedFeeTypeResult?.customer_addr_line_1) {
				residentialDetailsObj['residential_addr_line_1'] = selectedFeeTypeResult?.customer_addr_line_1;
			}
			if (selectedFeeTypeResult?.customer_addr_line_2) {
				residentialDetailsObj['residential_addr_line_2'] = selectedFeeTypeResult?.customer_addr_line_2;
			}
			if (selectedFeeTypeResult?.customer_pincode) {
				residentialDetailsObj['residential_pincode'] = selectedFeeTypeResult?.customer_pincode;
			}
			if (selectedFeeTypeResult?.customer_city) {
				residentialDetailsObj['residential_city'] = selectedFeeTypeResult?.customer_city;
			}
			if (selectedFeeTypeResult?.customer_state) {
				residentialDetailsObj['residential_state'] = selectedFeeTypeResult?.customer_state;
			}

			if (student_id || mobile) {
				const optionsObject = {
					auth: {
						client_id: pageData?.institute_config?.client_id,
						client_secret_key: pageData?.institute_config?.client_secret,
						gq_api_key: pageData?.institute_config?.api_key,
					},
					customizations: customizationsObj,
					// student_id: student_id || selectedFeeTypeResult?.student_uuid || mobile || selectedFeeTypeResult?.customer_mobile || "",
					student_id: student_id || selectedFeeTypeResult?.student_uuid || generateRandomStudentId(10) || "",
					customer_mobile: mobile || (selectedFeeTypeResult?.customer_mobile || ""),
					fee_editable: (feeDetails?.annual_amount && feeDetails?.payable_amount) ? false : true,
					fee_amount: `${feeDetails?.annual_amount?.toString() || feeDetails?.payable_amount?.toString()}` || "0",
					payable_amount: `${feeDetails?.payable_amount?.toString()}` || "0",
					_s: 'landing',
					env: ERP_SDK_ENV,
					notes: notesObj,
				}
				if (!isObjectEmpty(studentDetailsObj)) {
					optionsObject['student_details'] = studentDetailsObj;
				}
				if (!isObjectEmpty(customerDetailsObj)) {
					optionsObject['customer_details'] = customerDetailsObj;
				}
				if (!isObjectEmpty(kycDetailsObj)) {
					optionsObject['kyc_details'] = kycDetailsObj;
				}
				if (!isObjectEmpty(residentialDetailsObj)) {
					optionsObject['residential_details'] = residentialDetailsObj;
				}
				if (student_id) {
					// GA Event
					sendGA4Event({
						type: 'event',
						event_category: 'Institute Landing Page',
						event_action: 'Student ID Submitted',
						event_label: 'Student ID Submitted',
					});
				}
				if (mobile) {
					// GA Event
					sendGA4Event({
						type: 'event',
						event_category: 'Institute Landing Page',
						event_action: 'Mobile Number Submitted',
						event_label: 'Mobile Number Submitted',
					});
				}
				// GA Event
				sendGA4Event({
					type: 'event',
					event_category: 'Institute Landing Page',
					event_action: 'Proceed to Pay CTA Clicked',
					event_label: 'Proceed to Pay CTA Clicked',
				});
				
				GqErpSDK.setOptions(optionsObject);
				GqErpSDK.render();
			} else {
				setIsLoading(false);
				console.error("No student ID/ mobile number submitted!");
			}
		},
	});

	const handleCheckboxChange = (e) => {
		formik.resetForm();
		setResults([]);
		setSelectedFeeTypeResult(null);
		setFeeDetails({
			payable_amount: 0,
			annual_amount: 0,
		});
		formik.setFieldValue('no_id', !formik.values.no_id);
	}

	const search = async (searchObject) => {
		const {
			student_id,
			mobile,
		} = searchObject;

		setIsCallbackAPILoading(true)
		setFeeTypeError("");
		let paramsObj = {}
		const paramConfig = pageData?.callback_config?.params_config || null;
		const instituteConfig = pageData?.institute_config || null;

		if (paramConfig?.mobile && mobile) {
			paramsObj[paramConfig.mobile] = mobile;
		}
		if (paramConfig?.student_uuid && student_id) {
			paramsObj[paramConfig.student_uuid] = student_id;
		}

		if (paramConfig?.group_id && instituteConfig?.group_id) {
			paramsObj[paramConfig.group_id] = instituteConfig.group_id;
		}
		if (paramConfig?.institute_id && instituteConfig?.institute_id) {
			paramsObj[paramConfig.institute_id] = instituteConfig.institute_id;
		}
		if (paramConfig?.location_id && instituteConfig?.location_id) {
			paramsObj[paramConfig.location_id] = instituteConfig.location_id;
		}
		if (paramConfig?.education_type_id && instituteConfig?.education_type_id) {
			paramsObj[paramConfig.education_type_id] = instituteConfig.education_type_id;
		}

		try {
			const responseData = await axios({
				url: pageData?.callback_config?.url,
				method: pageData?.callback_config?.method,
				params: paramsObj,
				data: pageData?.callback_config?.body_config,
				headers: pageData?.callback_config?.headers,
			})
			if (responseData?.data?.status_code === 200) {
				setResults(responseData.data.data || []);
				if (responseData?.data?.data?.length > 0) {
					// GA Event
					sendGA4Event({
						type: 'event',
						event_category: 'Institute Landing Page',
						event_action: 'Fee Card Selected',
						event_label: 'Fee Card Selected',
					});
					setSelectedFeeTypeResult(responseData.data.data[0]);
					setAllSelectedOptions([responseData.data.data[0]]);
					setFeeDetails({
						payable_amount: responseData.data.data[0]?.payable_amount,
						annual_amount: responseData.data.data[0]?.annual_amount,
					});
				} else {
					setResults([]);
					setSelectedFeeTypeResult(null);
					setFeeDetails({
						payable_amount: 0,
						annual_amount: 0,
					});
				}
			} else {
				setResults([]);
				setSelectedFeeTypeResult(null);
				setFeeDetails({
					payable_amount: 0,
					annual_amount: 0,
				});
			}
		} catch (error) {
			// console.error(error)
			setResults([]);
			setSelectedFeeTypeResult(null);
			setFeeDetails({
				payable_amount: 0,
				annual_amount: 0,
			});
		}
		setIsCallbackAPILoading(false);
		setIsDisablePayButton(false);
	}

	const hasSameStudentID = ({ student_uuid }) => {
		for (var i = 0; i < allSelectedOptions.length; i++) {
			if (allSelectedOptions[i].student_uuid !== student_uuid) {
				return false;
			}
		}
		return true;
	}

	const handleFeeTypeChange = (e, result) => {
		if (result?.payable_amount || result?.annual_amount) {
			const hasSameID = hasSameStudentID(result);
			if (!hasSameID) {
				document?.getElementById('checkboxContainer')?.querySelectorAll('input[type=checkbox]')?.forEach(el => el.checked = false);
				e.target.checked = true;
			}
			if (e.target.checked) {
				if (hasSameID) {
					setAllSelectedOptions([...allSelectedOptions, result]);
					setFeeDetails({
						payable_amount: feeDetails?.payable_amount + result?.payable_amount,
						annual_amount: feeDetails?.annual_amount + result?.annual_amount,
					});
				} else {
					setAllSelectedOptions([result]);
					setFeeDetails({
						payable_amount: result?.payable_amount,
						annual_amount: result?.annual_amount,
					});
				}
				// GA Event
				sendGA4Event({
					type: 'event',
					event_category: 'Institute Landing Page',
					event_action: 'Fee Card Selected',
					event_label: 'Fee Card Selected',
				});
				setSelectedFeeTypeResult(result);
				setIsDisablePayButton(false);
				setFeeTypeError("");
			} else {
				const newPayableAmount = feeDetails?.payable_amount - result?.payable_amount;
				const newAnnualAmount = feeDetails?.annual_amount - result?.annual_amount;

				if (newPayableAmount > 0 || newAnnualAmount > 0) {
					setIsDisablePayButton(false);
				} else {
					setSelectedFeeTypeResult(null);
					setAllSelectedOptions([]);
					setIsDisablePayButton(true);
				}
				setFeeDetails({
					payable_amount: feeDetails?.payable_amount - result?.payable_amount,
					annual_amount: feeDetails?.annual_amount - result?.annual_amount,
				});
				const filteredArray = allSelectedOptions?.filter(obj => obj?.fee_type !== result?.fee_type)
				setAllSelectedOptions(filteredArray);
				setFeeTypeError("To continue, please select a fee type");
			}
		}
	}

	const searchResultsMapped =
		(results?.length === 0) ? "" : results.map((result, index) => {
			const isChecked = index === 0 ? true : false;
			return (
				<div className={classes.FeeTypeOption} key={result?.code}>
					<input onChange={(e) => handleFeeTypeChange(e, result)} className="sr-only" type="checkbox" name="fee_type" id={`fee_type_${index}`} defaultChecked={isChecked ? "checked" : null} />
					<label htmlFor={`fee_type_${index}`}>
						<span className={classes.FeeType}>{result?.fee_type || "-"}</span>
						<span className={classes.StudentDetails}>
							<span className={classes.StudentName}>
								<span className={classes.Label}>Name</span>
								<span className={classes.Value}>
									{`${result?.student_first_name ? result.student_first_name : ""} ${result?.student_last_name ? result.student_last_name : ""}`}
								</span>
							</span>
							<span className={classes.StudentID}>
								<span className={classes.Label}>Student ID</span>
								<span className={classes.Value}>
									{result?.student_uuid ? result.student_uuid : "-"}
								</span>
							</span>
						</span>
						<span className={classes.FeeDetails}>
							<span className={classes.Annual}>
								<span className={classes.Label}>Total Fee Due</span>
								<span className={classes.Value}>
									{result?.annual_amount?.toString() ? `₹${result.annual_amount?.toString()}` : "-"}
								</span>
							</span>
							<span className={classes.Payable}>
								<span className={classes.Label}>Current Fee Due</span>
								<span className={classes.Value}>
									{result?.payable_amount?.toString() ? `₹${result.payable_amount?.toString()}` : "-"}
								</span>
							</span>
						</span>
					</label>
				</div>
			)
		})

	const handleInputChange = async (e) => {
		const { name, value } = e.target;
		if (name === "student_id") {
			await formik.setFieldValue('student_id', value.trim());
		}
		if (name === "mobile") {
			await formik.setFieldValue('mobile', value.trim());
		}

		setResults([]);
		setSelectedFeeTypeResult(null);
		setAllSelectedOptions([]);
		setFeeDetails({
			payable_amount: 0,
			annual_amount: 0,
		});

		if ((name === "student_id" && value) ||
			(name === "mobile" && value && value.length === 10)
		) {
			search({
				student_id: name === "student_id" ? value : "",
				mobile: name === "mobile" ? value : "",
			});
		}
	}

	useEffect(() => {
		if (query.get('sid')) {
			formik.setFieldValue('no_id', false);
			search({
				student_id: query.get('sid'),
				mobile: "",
			});
			setIsHideStudentIdToggle(true);
		} else if (query.get('m')) {
			formik.setFieldValue('no_id', true);
			search({
				student_id: "",
				mobile: query.get('m'),
			});
			setIsHideStudentIdToggle(true);
		}
	}, [])

	return (
		<form className={classes.SDKForm} onSubmit={formik.handleSubmit}>
			<div className="row">

				{(!formik.values.no_id && sdk_block?.student_uuid_visible) ?
					(<div className="col-lg-12">
						<div className={classes.FormFieldCol}>
							<label htmlFor='student_id'>Application number/Student ID<span className={classes.required}>*</span></label>
							<input
								id='student_id'
								name='student_id'
								type='text'
								placeholder='Enter Application number/Student ID'
								onChange={handleInputChange}
								value={formik.values.student_id.trim()}
								disabled={false}
							/>
							{formik.touched.student_id && formik.errors.student_id ? (
								<div className='form-error'>{formik.errors.student_id}</div>
							) : null}
						</div>
					</div>) : null
				}

				{(formik.values.no_id && sdk_block?.mobile_visible) ?
					(<div className="col-lg-12">
						<div className={classes.FormFieldCol}>
							<label htmlFor='mobile'>Mobile Number<span className='required'>*</span></label>
							<label htmlFor="mobile" className={classes.MobileInputWrap}>
								<span className={classes.InputAddon}>+91 -</span>
								<input
									id='mobile'
									name='mobile'
									type='text'
									placeholder='Enter mobile number'
									maxLength={10}
									autoComplete="off"
									inputMode="numeric"
									className={classes.MobileInput}
									onChange={handleInputChange}
									value={formik.values.mobile.trim()}
									disabled={false}
								/>
							</label>
							{formik.touched.mobile && formik.errors.mobile ? (
								<div className='form-error'>{formik.errors.mobile}</div>
							) : null}
						</div>
					</div>) : null
				}

				{(
					(sdk_block?.student_uuid_visible && formik?.values?.student_id) ||
					(sdk_block?.mobile_visible && formik?.values?.mobile?.length === 10)) ?
					(<div className='col-lg-12'>
						<div ref={checkboxContainer} id="checkboxContainer" className={classes.SelectFeeTypeContainer}>
							{!isCallbackAPILoading ? searchResultsMapped : "Loading..."}
						</div>
						{feeTypeError ? <p className={classes.ErrorMessage}>{feeTypeError}</p> : null}
					</div>) : null
				}

				{(sdk_block?.student_uuid_visible && sdk_block?.mobile_visible && !isHideStudentIdToggle) ?
					(<div className="col-lg-12">
						<label htmlFor="no_id" className={classes.Checkbox}>
							<input
								type="checkbox"
								onChange={handleCheckboxChange}
								name="no_id"
								id="no_id"
								className={classes.Checkbox__Input}
								checked={formik.values.no_id}
							/>
							&nbsp;
							<span className={classes.Checkbox__Text}>Don't have student ID?</span>
						</label>
					</div>) : null
				}

				{(sdk_block?.student_uuid_visible || sdk_block?.mobile_visible) ?
					(<div className="col-lg-12">
						<div className={classes.FormActionCol}>
							<button
								className='Inst_ActionButton'
								style={{ padding: '8px 24px' }}
								type='submit'
								disabled={
									results.length === 0 &&
										pageData?.sdk_block.hasOwnProperty('no_data_bypass')
										? pageData.sdk_block?.no_data_bypass
											? false
											: true
										: isDisablePayButton
								}
							>
								{sdk_block?.cta_config?.label || 'Proceed'}
								{isLoading ? (
									<span className={classes.LoaderWrap}>
										<SpinnerLoader
											size='tiny'
											trackColor='#ccc'
											spinnerColor='#5667ff'
										/>
									</span>
								) : null}
							</button>
						</div>
					</div>) : null
				}
			</div>
		</form>
	)
};

export default SdkForm;
