import React from 'react';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import Header1 from '../../Components/Common/Header1/Header1';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';
import classes from './GrievanceOfficer.module.scss';

function GrievanceOfficer() {
    return (
        <>
             <CustomHelmet page="grievanceofficer" />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.goHeading}>
                            <span>Grievance Officer</span>
                        </h1>
                    </div>
                </section>
                <section className={classes.body_section}>
                    <div className={classes.first_para}>
                        <div className={classes.first_para_content}>
                            <p>
                                At Grayquest, our continual focus is on
                                providing our customers the best service
                                possible.{' '}
                            </p>
                            <p>
                                If for any reason, you have been subject to any
                                distress from our end, we request that you
                                report grievances (including concerns about
                                staff behaviour). You can email us at
                                <a href='mailto:support@grayquest.com'>
                                    {' '}
                                    support@grayquest.com
                                </a>{' '}
                            </p>
                            <p>
                                Our team will be available between 10:00 AM to
                                7:00 PM on weekdays (except on public holidays).
                                Our team will ensure that your grievances are
                                resolved at the earliest.
                            </p>
                        </div>
                        <div className={classes.second_para_content}>
                            <h3 className={classes.NodalGOHeading}>
                                Nodal Grievance Redressal Official
                            </h3>
                            <p>
                                Our customer services team works diligently to
                                ensure that all your complaints are resolved.
                                However, if for any reason you don’t receive a
                                satisfactory response from us within 14 days, we
                                request that you reach out to our Nodal
                                Grievance Redressal Official.
                            </p>
                            <p>
                                We assure you that our team will do their best
                                to address your issue.
                            </p>
                            <br/>
                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Ranjeet Singh Rawat
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:nodalofficer@grayquest.com'>
                                    {' '}
                                    nodalofficer@grayquest.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}+91 8356843634
                            </p>
                            <br/>
                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Garvit Mathur
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:nodalofficer@grayquest.com'>
                                    {' '}
                                     nodalofficer@grayquest.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}+91 8356843634
                            </p>
                            <br/>
                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Bhavik Ajugiya
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:nodalofficer@grayquest.com'>
                                    {' '}
                                    nodalofficer@grayquest.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}+91 8356843634
                            </p>
                            <br/>
                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Address:
                                </strong>
                                {' '}Grayquest, 2nd Floor, Office no. 202, Center
                                Point Bldg, Parel, Mumbai - 400 012
                            </p>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Aditya Birla Finance Ltd.</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Mr. Arijit Sen
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {' '}Grievance redressal officer
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:grievance.finance@adityabirlacapital.com'>
                                    {' '}
                                    grievance.finance@adityabirlacapital.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}080 45860196
                            </p>
                            </div>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Axis Finance Limited</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Ms. Mangal Sarang
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {' '}Grievance redressal officer
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:grievance@axisfinance.in'>
                                    {' '}
                                    grievance@axisfinance.in
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}91-22-62260000
                            </p>
                            </div>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Arka Fincap</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Mr. Jitendra Chaturvedi
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {/* {' '}Nodal Officer-Customer Grievance */}
                                    {' '}Cheif Compliance Officer
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:grievanceredressal@arkaholdings.com'>
                                    {' '}
                                    grievanceredressal@arkaholdings.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}+91-22-40471000
                            </p>
                            </div>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Ratnaafin Capital Private Limited</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Bhavesh Patel
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {' '}VP Operations
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:grievance@ratnaafin.com'>
                                    {' '}
                                    grievance@ratnaafin.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}91-9512011220
                            </p>
                            </div>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Western Capital Advisors Pvt Ltd</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Mr. Shushant Dash
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {' '}Grievance Redressal Officer
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:contact@westerncap.in'>
                                    {' '}
                                    contact@westerncap.in
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}022-28256772
                            </p>
                            </div>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Avanse Financial Services</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Mr. Gaurao Patil
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {' '}Vice President
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:gro@avanse.com'>
                                    {' '}
                                    gro@avanse.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}022-68599999
                            </p>
                            </div>

                            <div className={classes.ABFL_Block}>
                            <div className={classes.details} style={{ marginBottom: '1rem' }}>
                                <h3 className={classes.detail_title}>Mirae Asset Financial Services</h3>
                            </div>

                            <p className={classes.details}>
                                <strong className={classes.detail_title}>
                                    Name:
                                </strong>
                                {' '}Mr. Govind Lalwani
                                <br />
                                <strong className={classes.detail_title}>
                                    Designation:
                                </strong>
                                    {' '}Cheif Compliance Officer
                                <br />
                                <strong className={classes.detail_title}>
                                    Officer Email:
                                </strong>
                                <a href='mailto:grievance@miraeassetfin.com'>
                                    {' '}
                                    grievance@miraeassetfin.com
                                </a>
                                <br />
                                <strong className={classes.detail_title}>
                                    Phone Number:
                                </strong>
                                {' '}022-69005030
                            </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default GrievanceOfficer;
