import React from 'react';
import Carousel from 'react-bootstrap/Carousel' 
import classes from './InstituteDetailsBannerBlock.module.scss';

const InstituteDetailsBannerBlock = ({ pageData }) => {
    if (!pageData?.banner_block?.visible) return null;

    if (pageData?.banner_block?.images?.length === 0) return null;

    return (
        <div className={classes.InstituteDetailsBannerBlock}>
            {
                pageData?.banner_block?.images?.length === 1 ?
                    (<img src={pageData.banner_block.images[0]} 
                        className={`${classes.BannerImage} mx-auto`} 
                        alt={pageData?.institute_config?.institute_label || ''} 
                    />)
                    : (<Carousel variant="dark">
                        {
                            pageData.banner_block.images.map((img, index) => (
                                <Carousel.Item key={`banner-image-${index}`} interval={4000}>
                                    <img src={img} 
                                        className={classes.BannerImage} 
                                        alt={pageData?.institute_config?.institute_label || ''} 
                                    />     
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>)
            }
        </div>
    )
}

export default InstituteDetailsBannerBlock;
