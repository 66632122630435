import React, { useEffect, useRef, useState } from 'react';
import titleStar from '../../assets/images/career/title-star.svg';
import office from '../../assets/images/career/office.jpg';
import office1 from '../../assets/images/career/office1.jpeg';
import impossible from '../../assets/images/career/impossible.webp';
import workSmart from '../../assets/images/career/work-smart.webp';
import player from '../../assets/images/career/player.webp';
import upskill from '../../assets/images/career/upskill.webp';
import respect from '../../assets/images/career/respect.webp';
import serious from '../../assets/images/career/serious.webp';
import Header1 from '../Common/Header1/Header1';
import Footer1 from '../Common/Footer1/Footer1';
import Accordion from 'react-bootstrap/Accordion';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { display } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import CustomHelmet from '../CustomHelmet/CustomHelmet';
import './Career.css';
import {JobListingsGrid} from '../molecules'

function Career() {
    const [help1, setHelp1] = useState(false);
    const [help2, setHelp2] = useState(false);
    const [help3, setHelp3] = useState(false);
    const [hide, setHide] = useState(false);

    const swiperRef = useRef();
    const [hideRightArrow, setHideRightArrow] = useState(false);

    const options = {
        loop: true,
        autoplayTimeout: 2000,
        autoplay: true,
        nav: false,
        dots: false,
        center: true,
        responsive: {
            0: {
                items: 2,
                center: true,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    };
    const teamSlider = {
        loop: true,
        autoplay: true,
        margin: 10,
        nav: false,
        dots: false,
        items: 1,
    };

    const sliderSettings = {
        0: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        425: {
            slidesPerView: 1,
            spaceBetween: 50,
        },
        680: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CustomHelmet page='careers' />
            <Header1 theme='careers' />
            <main className='parents career'>
                <section className='bg--lightBlue hero'>
                    <div className='container'>
                        <p className='section__label section__label-center blue'>
                            <img
                                className='img-fluid'
                                src={titleStar}
                                alt='star icon'
                            />
                            We are hiring
                        </p>
                        <h1 className='text--bold section__title text-center'>
                            Join us to become a valuable part of{' '}
                            <span className='text--blueGradient'>
                                our mission
                            </span>
                        </h1>
                        <p className='text--regular text--black section__desc text-center'>
                            We want passionate individuals to join us in our
                            mission. We are always on the look out for fresh and
                            amazing talent.
                        </p>
                        <div className='hero__banner'>
                            <div className='left'>
                                <div className='hori'>
                                    <img
                                        className='img-fluid'
                                        src={office}
                                        alt=''
                                    />
                                </div>
                            </div>
                            <div className='right'>
                                <div className='hori'>
                                    <img
                                        className='img-fluid'
                                        src={office1}
                                        alt=''
                                    />
                                </div>
                                <div className='hori'>
                                    <img
                                        className='img-fluid'
                                        src='https://gq-public-assets.s3.ap-south-1.amazonaws.com/images/gqlanding_website/career/office7.png'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--withPadding mission'>
                    <div className='container'>
                        <h2 className='section__title text--bold'>
                            We’re an ambitious team with a{' '}
                            <span className='text--blueGradient'>
                                shared mission
                            </span>
                        </h2>
                        <p className='section__desc text--black text--regular'>
                            Our shared values keep us connected and driven,
                            working together as a team.
                        </p>
                        <div className='mission__container row'>
                            <div className='col-md-4'>
                                <div className='mission__card'>
                                    <img
                                        className='img-fluid'
                                        src={impossible}
                                        alt='impossible icon'
                                    />
                                    <p className='text--black text--semi mission__title'>
                                        Do the impossible
                                    </p>
                                    <p className='text--regular mission__desc'>
                                        We are constantly striving to break
                                        boundaries and reinvent.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mission__card'>
                                    <img
                                        className='img-fluid'
                                        src={workSmart}
                                        alt='work smart icon'
                                    />
                                    <p className='text--black text--semi mission__title'>
                                        Work smart
                                    </p>
                                    <p className='text--regular mission__desc'>
                                        While it’s important to work hard, it
                                        also essential to work smart.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mission__card'>
                                    <img
                                        className='img-fluid'
                                        src={player}
                                        alt='player'
                                    />
                                    <p className='text--black text--semi mission__title'>
                                        Be a team player
                                    </p>
                                    <p className='text--regular mission__desc'>
                                        Collaboration and team work are at the
                                        core of our organization.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mission__card'>
                                    <img
                                        className='img-fluid'
                                        src={upskill}
                                        alt='upskill icon'
                                    />
                                    <p className='text--black text--semi mission__title'>
                                        Upskill & improve
                                    </p>
                                    <p className='text--regular mission__desc'>
                                        We believe in reinventing with the times
                                        and constantly bettering ourselves!
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mission__card'>
                                    <img
                                        className='img-fluid'
                                        src={respect}
                                        alt='respect icon'
                                    />
                                    <p className='text--black text--semi mission__title'>
                                        Respect our customers
                                    </p>
                                    <p className='text--regular mission__desc'>
                                        Customer is king! And our goal here is
                                        to ensure we are able to cater to their
                                        needs with honesty.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mission__card mb-0'>
                                    <img
                                        className='img-fluid'
                                        src={serious}
                                        alt='serious icon'
                                    />
                                    <p className='text--black text--semi mission__title'>
                                        Never get too serious!
                                    </p>
                                    <p className='text--regular mission__desc'>
                                        Work hard, play hard is our mantra at
                                        GQ. A healthy balance of work and chill
                                        is what we encourage.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--yellow collaborative'>
                    <div className='container'>
                        <div className='d-flex justify-content-center'>
                            <h2 className='section__title text--bold'>
                                Open &{' '}
                                <span className='text--blueGradient'>
                                    collaborative
                                </span>{' '}
                                spaces
                            </h2>
                        </div>
                    </div>

                    <OwlCarousel
                        className='collaborative__slider owl-carousel'
                        {...options}
                    >
                        <div className='item'>
                            <img
                                className='img-fluid'
                                src='https://gq-public-assets.s3.ap-south-1.amazonaws.com/images/gqlanding_website/career/office3.png'
                                alt='office image'
                            />
                        </div>
                        <div className='item'>
                            <img
                                className='img-fluid'
                                src='https://gq-public-assets.s3.ap-south-1.amazonaws.com/images/gqlanding_website/career/office5.png'
                                alt='office image'
                            />
                        </div>
                        <div className='item'>
                            <img
                                className='img-fluid'
                                src='https://gq-public-assets.s3.ap-south-1.amazonaws.com/images/gqlanding_website/career/office6.png'
                                alt='office image'
                            />
                        </div>
                        <div className='item'>
                            <img
                                className='img-fluid'
                                src='https://gq-public-assets.s3.ap-south-1.amazonaws.com/images/gqlanding_website/career/office2.png'
                                alt='office image'
                            />
                        </div>
                    </OwlCarousel>
                </section>
                <section className='bg--withPadding mission'>
                    <div className='container'>
                        <h2 className='section__title text--bold'>
                        <span className='text--blueGradient'>
                                Open positions
                            </span>
                        </h2>
                        <div className='position__container'>
                            <div className='row'>
                                <div style={{marginBottom:"2rem"}} className='col-lg-6'>
                                    <div className='left'>
                                        <p className='text--black text--bold position__link-title'>
                                            Manager - Key Accounts
                                        </p>
                                        <p style={{marginBottom: "1rem"}} className='text--black'>
                                            Team - Tech
                                        </p>
                                    </div>
                                    <div style={{color: "#efefef", border:"0.5px solid"}} />
                                </div>
                                <div style={{marginBottom:"2rem"}} className='col-lg-6'>
                                    <div className='left'>
                                        <p className='text--black text--bold position__link-title'>
                                            Associate - Operation
                                        </p>
                                        <p style={{marginBottom: "1rem"}} className='text--black'>
                                            Team - Operations
                                        </p>
                                    </div>
                                    <div style={{color: "#efefef", border:"0.5px solid"}} />
                                </div>
                                <div style={{marginBottom:"2rem"}} className='col-lg-6'>
                                    <div className='left'>
                                        <p className='text--black text--bold position__link-title'>
                                            Intern - Operations
                                        </p>
                                        <p style={{marginBottom: "1rem"}} className='text--black'>
                                            Team - Operations
                                        </p>
                                    </div>
                                    <div style={{color: "#efefef", border:"0.5px solid"}} />
                                </div>
                                <div style={{marginBottom:"2rem"}} className='col-lg-6'>
                                    <div className='left'>
                                        <p className='text--black text--bold position__link-title'>
                                            Intern - Fleet on Street
                                        </p>
                                        <p style={{marginBottom: "1rem"}} className='text--black'>
                                            Team - Business
                                        </p>
                                    </div>
                                    <div style={{color: "#efefef", border:"0.5px solid"}} />
                                </div>
                                <div style={{marginBottom:"2rem"}} className='col-lg-6'>
                                    <div className='left'>
                                        <p className='text--black text--bold position__link-title'>
                                            Product Designer
                                        </p>
                                        <p style={{marginBottom: "1rem"}} className='text--black'>
                                            Team - Design
                                        </p>
                                    </div>
                                    <div style={{color: "#efefef", border:"0.5px solid"}} />
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default Career;
