import React, { useRef, useState } from 'react';
import experience from '../../assets/images/home/experience.png';
import SliderComp from './Slider/Slider';
import yellowstaricon from '../../assets/images/home/yellow-star.svg';
import homehero from '../../assets/images/home/home-hero-img.png';
import artwork from '../../assets/images/home/artWork.png';
import titlestar from '../../assets/images/home/title-star.svg';
import payment from '../../assets/images/home/payment-icon.svg';
import feeCollection from '../../assets/images/home/fee-collection.svg';
import feeCollectionIcon from '../../assets/images/home/fee-collection-icon.svg';
import mobileproductweb from '../../assets/images/home/mobileproductweb.png';
import transfromBannerweb from '../../assets/images/home/transform-banner.webp';
import ryanInsititueLogo from '../../assets/images/home/mask2.png';
import cnbcLogo from '../../assets/images/home/cnbcLogo.png';
import podarInstituteLogo from '../../assets/images/home/podarInstituteLogo.png';
import sliderArrow from '../../assets/images/home/slider-arrow.svg';
import BWDisrupt from '../../assets/images/home/BWDisrupt.png';
import business1 from '../../assets/images/home/business1.png';
import entracker from '../../assets/images/home/entracker.png';
import business2 from '../../assets/images/home/business2.png';
import business3 from '../../assets/images/home/business3.png';
import business4 from '../../assets/images/home/business4.png';
import flyingCoins from '../../assets/images/home/flyingCoins.png';
import fist from '../../assets/images/home/fist.png';
import gqForInstitutes from '../../assets/images/home/gqForInstitutes.png';
import tio from '../../assets/images/home/yourstory.png';
import vccircle from '../../assets/images/home/vccircle.png';
import yourstory from '../../assets/images/home/tio.png';
import radio from '../../assets/images/home/radio.png';
import qrCode from '../../assets/images/home/window.png';
import rupee from '../../assets/images/home/rupee.png';
import mobile from '../../assets/images/home/mobile.png';
import note from '../../assets/images/home/note.png';
import wallet from '../../assets/images/home/wallet.png';
import bankImageP from '../../assets/images/home/bankImageP.png';
import swipe from '../../assets/images/home/swipe.png';
import illustration from '../../assets/images/home/illustration.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'video-react/dist/video-react.css';
import Footer1 from '../Common/Footer1/Footer1';
import Header1 from '../Common/Header1/Header1';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import clientTestimonial from '../../assets/video/test.mp4';
import quote from '../../assets/images/home/quote.png';
import thumbnailRyan from '../../assets/images/home/thumbnail_Ryan.png';
import './Home.css';
import TextAnimation from './TextAnimation/TextAnimation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import CustomHelmet from '../CustomHelmet/CustomHelmet';

function Home() {
    const swiperRef = useRef();

    const [hideRightArrow, setHideRightArrow] = useState(false);

    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
    };

    const sliderSettings = {
        0: {
            slidesPerView: 1,
        },
        425: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 1.3,
            spaceBetween: 20,
        },
    };

    return (
        <>
            <CustomHelmet page="home" />
            <div>
                <Header1 theme={'home'} />
                <main id='HomePageWrapper' className='home hero'>
                    <section className='bg--blue pb-0 hero'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <p className='section__label yellow'>
                                        <img
                                            className='img-fluid'
                                            src={yellowstaricon}
                                            alt='yellow star icon'
                                        />
                                        Fees Made Fantastic!
                                    </p>
                                    <h1 className='section__title text--bold text--white home-title'>
                                        India’s most advanced fee payment
                                        platform for your <TextAnimation />
                                    </h1>
                                    <div className='section__action-container'>
                                        <Link to='/contact'>
                                            <div
                                                href='#'
                                                className='custom__btn custom__btn--blue'
                                                style={{
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Schedule a demo
                                            </div>
                                        </Link>
                                        <Link to={'/institute'}>
                                            <divxs
                                                href='#'
                                                className='custom__arrowBtn custom__arrowBtn--white'
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Our products
                                            </divxs>
                                        </Link>
                                    </div>
                                    <div id='artwork'>
                                        <img
                                            src={artwork}
                                            style={{
                                                width: '154px',
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className='col-lg-5'
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div
                                        className='fi-box-title'
                                        style={{
                                            left: '300px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: 'Poppins',
                                            }}
                                        >
                                            <img
                                                src={illustration}
                                                style={{
                                                    marginRight: '7px',
                                                }}
                                            />
                                            For Institutes
                                        </span>
                                    </div>
                                    <div
                                        id='forInstituteContent'
                                        className='fi-box-desc'
                                        style={{
                                            top: '52px',
                                            left: '300px',
                                        }}
                                    >
                                        <span>
                                            A plethora of features to track and{' '}
                                            <span
                                                style={{
                                                    color: '#fd65eb',
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                manage fee collection
                                            </span>{' '}
                                            with ease!
                                        </span>
                                    </div>
                                    <div className='hero__banner'>
                                        <img
                                            className='img-fluid'
                                            src={
                                                window.innerwidth <= 768
                                                    ? herohome1
                                                    : homehero
                                            }
                                            alt='home hero'
                                        />
                                    </div>
                                    <div
                                        className='fi-box-title'
                                        style={{
                                            bottom: '136px',
                                            right: '400px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: 'Poppins',
                                            }}
                                        >
                                            <img
                                                src={illustration}
                                                style={{
                                                    marginRight: '7px',
                                                }}
                                            />
                                            For Parents
                                        </span>
                                    </div>

                                    <div
                                        id='forParentsContent'
                                        className='fi-box-desc'
                                    >
                                        <span>
                                            Pay your child’s{' '}
                                            <span
                                                style={{
                                                    color: '#fd65eb',
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                fee monthly or all upfront
                                            </span>{' '}
                                            - your choice! And never miss a
                                            deadline again.
                                        </span>
                                    </div>
                                </div>

                                <div
                                    className='col-lg-666'
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className='hero__banner'>
                                        <img
                                            className='img-fluid'
                                            src={
                                                window.innerwidth <= 768
                                                    ? herohome1
                                                    : homehero
                                            }
                                            alt='home hero'
                                        />
                                    </div>
                                    <div
                                        className='for_ '
                                        style={{ paddingBottom: '1.25rem' }}
                                    >
                                        <Swiper
                                            autoplay={{
                                                delay: 3000,
                                                disableOnInteraction: true,
                                            }}
                                            slidesPerView={1.2}
                                            pagination={{
                                                clickable: true,
                                                // el:"my-button-next my-button-prev",
                                                // bulletClass:"my-button-bullet",
                                                renderBullet: function (
                                                    index,
                                                    className
                                                ) {
                                                    return (
                                                        '<span class="' +
                                                        className +
                                                        '"><img class="pagination-bullet"/></span>'
                                                    );
                                                },
                                            }}
                                            modules={[Autoplay, Pagination]}
                                        >
                                            <SwiperSlide>
                                                <div className='for_slider_home'>
                                                    <div className='for_heading_slider'>
                                                        <div>✨</div>
                                                        For Institutes
                                                    </div>
                                                    <div className='for_body'>
                                                        A plethora of features
                                                        to track and{' '}
                                                        <span
                                                            style={{
                                                                color: '#fd65eb',
                                                            }}
                                                        >
                                                            manage fee
                                                            collection
                                                        </span>{' '}
                                                        with ease!
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className='for_slider_home'>
                                                    <div className='for_heading_slider'>
                                                        <div>✨</div>For Parents
                                                    </div>
                                                    <div className='for_body'>
                                                        Pay your child’s{' '}
                                                        <span
                                                            style={{
                                                                color: '#fd65eb',
                                                            }}
                                                        >
                                                            fee monthly or all
                                                            upfront
                                                        </span>{' '}
                                                        - your choice! And never
                                                        miss a deadline again.
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className='my-button-next'>
                                            <div className='my-button-bullet'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container'></div>

                                <Slider
                                    {...settings1}
                                    style={{
                                        position: 'relative',
                                        top: '20px',
                                        zIndex: '1',
                                    }}
                                >
                                    <div
                                        className='fi-box-title'
                                        style={{
                                            position: 'none',
                                        }}
                                    >
                                        <span>For Institutes</span>
                                    </div>
                                    <div
                                        className='fi-box-desc'
                                        style={{
                                            position: 'none',
                                        }}
                                    >
                                        <span>
                                            A plethora of features to track and{' '}
                                            <span
                                                style={{
                                                    color: '#fd65eb',
                                                }}
                                            >
                                                manage fee collection
                                            </span>{' '}
                                            with ease!
                                        </span>
                                    </div>
                                    <div
                                        className='fi-box-title'
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <span>For Parents</span>
                                    </div>

                                    <div
                                        className='fi-box-desc'
                                        style={{
                                            position: 'none',
                                        }}
                                    >
                                        <span>
                                            A plethora of features to track and{' '}
                                            <span
                                                style={{
                                                    color: '#fd65eb',
                                                }}
                                            >
                                                manage fee collection
                                            </span>{' '}
                                            with ease!
                                        </span>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </section>

                    <SliderComp theme={'partner'} />

                    <section className='bg--yellow pb-0 experience'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <p className='section__label blue'>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        GQ for parents
                                    </p>
                                    <h6 className='section__title text--bold text--black section-name'>
                                        Experience the most
                                        <span className='text--blueGradient section-home'>
                                            convenient, affordable and rewarding
                                            mode of paying your education fees.
                                        </span>
                                    </h6>

                                    <p className='text--black text--regular section__desc'>
                                        Stay informed and never miss a payment
                                        with our user friendly and
                                        cost-effective options, coupled with
                                        convenient payment reminders.
                                    </p>
                                    <ul>
                                        <p
                                            id='manageFontSize1'
                                            className='text--black text--regular section__desc'
                                        >
                                            <li>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='8'
                                                    height='8'
                                                    fill='currentColor'
                                                    className='bi bi-circle-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <circle
                                                        cx='8'
                                                        cy='8'
                                                        r='8'
                                                    />
                                                </svg>{' '}
                                                Options to convert Annual Fees
                                                into Zero Cost EMIs.
                                            </li>{' '}
                                        </p>
                                        <p
                                            id='manageFontSize2'
                                            className='text--black text--regular section__desc'
                                        >
                                            <li>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='8'
                                                    height='8'
                                                    fill='currentColor'
                                                    className='bi bi-circle-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <circle
                                                        cx='8'
                                                        cy='8'
                                                        r='8'
                                                    />
                                                </svg>{' '}
                                                Instant Reconciliation with Your
                                                Educational Institute.
                                            </li>{' '}
                                        </p>
                                        <p
                                            id='manageFontSize3'
                                            className='text--black text--regular section__desc'
                                        >
                                            <li>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='8'
                                                    height='8'
                                                    fill='currentColor'
                                                    className='bi bi-circle-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <circle
                                                        cx='8'
                                                        cy='8'
                                                        r='8'
                                                    />
                                                </svg>{' '}
                                                WhatsApp based reminders.
                                            </li>{' '}
                                        </p>
                                    </ul>
                                    <div className='section__action-container'>
                                        <Link
                                            to={
                                                'https://customer.grayquest.com/'
                                            }
                                        >
                                            <div
                                                href='https://customer.grayquest.com/'
                                                className='custom__btn custom__btn--blue'
                                                style={{
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Sign up today!
                                            </div>
                                        </Link>
                                        <Link to={'/parents'}>
                                            <div
                                                href='#'
                                                className='custom__arrowBtn custom__arrowBtn--white'
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Learn more
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='experience__banner'>
                                        <img
                                            className='img-fluid'
                                            src={experience}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='bg--blue why'>
                        <div className='container'>
                            <p className='section__label section__label-center yellow'>
                                <img
                                    className='img-fluid'
                                    src={yellowstaricon}
                                    alt='yellow star icon'
                                />
                                GQ for Institutes
                            </p>
                            <h2
                                id='gqinstitle'
                                className='section__title text--bold text--white'
                            >
                                Transform your{' '}
                                <span className='text--pinkGradient'>
                                    fee collection
                                </span>
                            </h2>
                            <p className='section__desc text--white-7 text--regular'>
                                Let us manage your fee collection activities, so
                                you can focus on the education of India’s youth!
                            </p>
                            <div className='why__container'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <img
                                            className='img-fluid'
                                            src={gqForInstitutes}
                                            alt='cashflow'
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='why__container--icon'>
                                            <img
                                                className='img-fluid'
                                                src={flyingCoins}
                                                alt='cashflow icon'
                                            />
                                        </div>
                                        <p className='text--white why__container--title'>
                                            Upfront cashflow from GQ on behalf
                                            of parents
                                        </p>
                                        <p className='text--white why__container--desc'>
                                            Allows parents to pay in instalments
                                            but we give you the full payment
                                            upfront.
                                        </p>
                                        <Link to={'/institute'}>
                                            <a
                                                href='#'
                                                className='custom__arrowBtn custom__arrowBtn--blue'
                                                style={{
                                                    color: 'white',
                                                    marginTop: '25px',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Learn more
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 order-lg-2'>
                                        <img
                                            className='img-fluid'
                                            src={mobileproductweb}
                                            alt='mobile product'
                                        />
                                    </div>
                                    <div className='col-lg-6 order-lg-1'>
                                        <div className='why__container--icon'>
                                            <img
                                                className='img-fluid'
                                                src={payment}
                                                alt='payment icon'
                                            />
                                        </div>
                                        <p className='text--white why__container--title'>
                                            One payment platform with{' '}
                                            <span className='text-circle'>
                                                8+ payment options
                                            </span>
                                        </p>

                                        <div className='payment-logo-section'>
                                            <div className='rapidOptions'>
                                                <div>
                                                    <img src={rupee} />
                                                </div>
                                                <div>
                                                    <img src={radio} />
                                                </div>
                                                <div>
                                                    <img src={mobile} />
                                                </div>
                                                <div>
                                                    <img src={note} />
                                                </div>
                                                <div>
                                                    <img src={qrCode} />
                                                </div>
                                                <div>
                                                    <img src={wallet} />
                                                </div>
                                                <div>
                                                    <img src={bankImageP} />
                                                </div>
                                                <div>
                                                    <img src={swipe} />
                                                </div>
                                            </div>
                                        </div>

                                        <p className='text--white why__container--desc'>
                                            Give parents a rewarding experience
                                            by allowing multiple easy payment
                                            options
                                        </p>
                                        <Link to='/institute'>
                                            <a
                                                href='#'
                                                className='custom__arrowBtn custom__arrowBtn--blue'
                                                style={{
                                                    color: 'white',
                                                    marginTop: '25px',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Our Products
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <img
                                            className='img-fluid'
                                            src={feeCollection}
                                            alt='fee collection'
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='why__container--icon'>
                                            <img
                                                className='img-fluid'
                                                src={feeCollectionIcon}
                                                alt='fee collection icon'
                                            />
                                        </div>
                                        <p className='text--white why__container--title'>
                                            Eliminate administrative hassles
                                            related to fee collection
                                        </p>
                                        <p className='text--white why__container--desc'>
                                            No more chasing the parent, every
                                            month, for timely fee collection.
                                            Set up auto debit.
                                        </p>
                                        <Link to={'/contact'}>
                                            <a
                                                href='#'
                                                className='custom__btn custom__btn--white'
                                                style={{
                                                    marginTop: '15px',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                Schedule a demo
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='bg--lightGray testimonial'>
                        <div className='container'>
                            <div className='testimonial__heading'>
                                <h2 className='section__title text--bold text--black'>
                                    Hear it from our{' '}
                                    <span className='text--blueGradient'>
                                        amazing partners
                                    </span>
                                </h2>
                                <img
                                    className='img-fluid'
                                    src={fist}
                                    alt='testimonial icon'
                                />
                            </div>
                        </div>

                        <div className='HomePageSlider'>
                            <Swiper
                                id='slider_margin'
                                className='testimonial__slider'
                                onBeforeInit={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                                centeredSlides={true}
                                breakpoints={sliderSettings}
                                onSlideChange={(e) =>
                                    e.activeIndex === 2
                                        ? setHideRightArrow(true)
                                        : setHideRightArrow(false)
                                }
                            >
                                <SwiperSlide>
                                    <div className='HomePageSlider__Slide'>
                                        <div className='Slide__MediaWrap HasVideo'>
                                            <div className='VideoWrap'>
                                                <iframe src="https://drive.google.com/file/d/1nCsFZishK8dcXYZHwmEYkywoHO4wu1J4/preview" 
                                                    width="640" 
                                                    height="380" 
                                                    allow="autoplay; encrypted-media" 
                                                    allowFullScreen
                                                    style={{
                                                        maxWidth: '100%'
                                                    }}>
                                            </iframe>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='HomePageSlider__Slide'>
                                        <div className='Slide__MediaWrap HasVideo'>
                                            <div className='VideoWrap'>
                                                <video
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    poster={thumbnailRyan}
                                                >
                                                    <source
                                                        src={clientTestimonial}
                                                        type='video/mp4'
                                                    />
                                                    Your browser does not
                                                    support HTML video.
                                                </video>
                                            </div>
                                        </div>

                                        <div className='Slide__ContentWrap'>
                                            <img
                                                className='InstituteLogoImage'
                                                src={ryanInsititueLogo}
                                                alt='institute'
                                            />
                                            <img
                                                className='QuoteImage'
                                                src={quote}
                                                alt=''
                                            />
                                            <p className='ContentText'>
                                                GQ has proven to be fantastic at
                                                our school. Fee collection
                                                became super easy. Also, we love
                                                using their dashboard.
                                            </p>

                                            <div className='Author'>
                                                <p className='Name'>
                                                    Mr Ryan Pinto
                                                </p>
                                                <p className='Designation'>
                                                    CEO, Ryan International
                                                    Group
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='HomePageSlider__Slide'>
                                        <div className='Slide__ContentWrap'>
                                            <img
                                                className='InstituteLogoImage'
                                                src={podarInstituteLogo}
                                                alt='institute'
                                            />
                                            <img
                                                className='QuoteImage'
                                                src={quote}
                                                alt=''
                                            />
                                            <p className='ContentText'>
                                                Podar Education Network has
                                                always supported startup
                                                initiatives - if they truly add
                                                value to our ecosystem of 100+
                                                schools. It was with this spirit
                                                that we decided to pilot
                                                GrayQuest's product in 3 of our
                                                schools more than 5 years ago
                                                when we first heard about their
                                                concept - becoming their 1st
                                                school customer. Since the
                                                pilot, we have seen GQ grow
                                                multifold in terms of their
                                                product capability, service and
                                                eventually impact on not just
                                                Podar Group but the broader
                                                Indian education ecosystem.
                                            </p>

                                            <div className='Author'>
                                                <p className='Name'>
                                                    Adhar Samria
                                                </p>
                                                <p className='Designation'>
                                                    CFO Podar Education Network
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className='container'>
                            <div className='slider-controls'>
                                <a
                                    style={{ cursor: 'pointer' }}
                                    className='slider-arrow btn-prev review-swiper-button-next'
                                >
                                    <img
                                        onClick={() =>
                                            swiperRef.current?.slidePrev()
                                        }
                                        className='img-fluid'
                                        src={sliderArrow}
                                        alt='slider arrow'
                                    />
                                    <span className='sr-only'>Prev Btn</span>
                                </a>

                                {!hideRightArrow ? (
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            swiperRef.current?.slideNext()
                                        }
                                        className='slider-arrow btn-next'
                                    >
                                        <img
                                            className='img-fluid'
                                            src={sliderArrow}
                                            alt='slider arrow'
                                        />
                                        <span className='sr-only'>
                                            Next Btn
                                        </span>
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </section>

                    <section className='partnersLogContainer'>
                        <div className='title-box'>
                            <h2 className='title'>
                                <span className='text--blueGradient'>
                                    Proudly featured{' '}
                                </span>
                                In media
                            </h2>
                        </div>
                        <div className='mediaContainer'>
                            <span className='mediaLogos'>
                                <a
                                    className='mediaLogosLink'
                                    href='https://www.cnbctv18.com/startup/grayquest-raises-rs-56-crore-in-series-a-equity-funding-round-16127821.htm'
                                    target='_blank'
                                >
                                    <img
                                        width='80%'
                                        src={cnbcLogo}
                                        alt='cnbcLogo'
                                    />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a 
                                    href='https://entrackr.com/2023/03/grayquest-raises-7-mn-in-series-a-round/
'
                                    target='_blank'
                                    className='mediaLogosLink'
                                >
                                    <img
                                        width='80%'
                                        src={entracker}
                                        alt='entracker'
                                    />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://yourstory.com/hindi/grayquest-announces-its-series-a-equity-funding-round-of-rs-56crore-7mn-education-fees-fintech
 '
                                    target='_blank'
                                >
                                    <img src={tio} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href=' https://www.vccircle.com/fintechcompany-grayquest-iot-based-probus-others-raise-early-funds
'
                                    target='_blank'
                                >
                                    <img src={vccircle} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://www.financialexpress.com/money/grayquest-this-firm-helps-convert-heavy-school-fees-into-emis-offers-insurance-of-rs-20-lakh-interview/2068499/
'
                                    target='_blank'
                                >
                                    <img src={business1} alt='medialogo' />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://mumbaimirror.indiatimes.com/coronavirus/news/pvt-school-fee-takes-the-microloan-route/articleshow/76884336.cms'
                                    target='_blank'
                                >
                                    <img src={business2} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://bfsi.economictimes.indiatimes.com/news/fintech/foundation-holdings-invest-1-2-million-in-fintech-grayquest/77592506'
                                    target='_blank'
                                >
                                    <img src={business3} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://www.moneycontrol.com/news/business/startup/grayquest-raises-1-2-mn-in-pre-series-a-funding-5718151.html'
                                    target='_blank'
                                >
                                    <img src={business4} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://bwdisrupt.businessworld.in/article/GrayQuest-Raises-1-2-Million-In-Pre-Series-A-Funding-Led-By-Foundation-Holdings/18-08-2020-310082/'
                                    target='_blank'
                                >
                                    <img
                                        width='80%'
                                        src={BWDisrupt}
                                        alt='medialogo'
                                    />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a className='mediaLogosLink'
                                    href='https://timesofindia.indiatimes.com/increasing-number-of-women-paying-for-kids-education-report/articleshow/81396500.cms
'
                                    target='_blank'
                                >
                                    <img src={yourstory} alt='medialogo' />
                                </a>
                            </span>
                        </div>
                    </section>
                    <section className='bg--withPadding pb-0 transform'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-6'>
                                    <h2 className='section__title text--bold'>
                                        <span
                                            className='text--blueGradient'
                                            style={{
                                                lineHeight: '1.25',
                                            }}
                                        >
                                            Let’s transform
                                        </span>{' '}
                                        your institute’s fee-collection process
                                        today!
                                    </h2>
                                    <Link to={'/contact'}>
                                        <div
                                            href='#'
                                            className='custom__btn custom__btn--blue hover-border'
                                            style={{
                                                color: 'white',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            Schedule a demo
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-md-6'>
                                    <div className='transform__banner'>
                                        <img
                                            className='img-fluid'
                                            src={transfromBannerweb}
                                            alt='transform banner'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer1 />
                </main>
            </div>
        </>
    );
}

export default Home;
