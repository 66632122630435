import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllGqBlogsGet } from '../../../api-cms-client';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const getGqBlogsFetchAll = createAsyncThunk(
    'getGqBlogsFetchAll/Get',
    async (body, { rejectWithValue }) => {
        try {
            const response = await fetchAllGqBlogsGet(body);
            const data = response?.data;
            return data;
        } catch (err) {
            const errObj = {
                type: 'GET_GQ_BLOGS_FETCH_ALL_FAILURE',
                error: err,
                message: 'Oops! something went wrong',
            };
            return rejectWithValue(err?.response?.data || errObj);
        }
    }
);

const getGqBlogsFetchAllSlice = createSlice({
    name: 'getGqBlogsFetchAll',
    initialState,
    extraReducers: {
        [getGqBlogsFetchAll.pending]: (state) => {
            state.loading = true;
        },
        [getGqBlogsFetchAll.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = {
                data: payload?.data,
                pagination: payload?.meta?.pagination,
            };
            state.error = null;
        },
        [getGqBlogsFetchAll.rejected]: (state, { payload }) => {
            state.loading = false;
            state.data = null;
            state.error = payload;
        },
    },
});

export const getGqBlogsFetchAllSelector = (state) => state.getGqBlogsFetchAll;
export const getGqBlogsFetchAllReducer = getGqBlogsFetchAllSlice.reducer;
