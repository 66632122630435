import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGqJobDepartmentDataGet } from '../../../api-cms-client';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const getGqJobDepartmentFetch = createAsyncThunk(
    'getGqJobDepartmentFetch/Get',
    async (body, { rejectWithValue }) => {
        try {
            const response = await fetchGqJobDepartmentDataGet(body);
            const data = response?.data;
            return data;
        } catch (err) {
            const errObj = {
                type: 'GET_GQ_BLOG_CATEGORY_FETCH_FAILURE',
                error: err,
                message: 'Oops! something went wrong',
            };
            return rejectWithValue(err?.response?.data || errObj);
        }
    }
);

const getGqJobDepartmentFetchSlice = createSlice({
    name: 'getGqJobDepartmentFetchSlice',
    initialState,
    extraReducers: {
        [getGqJobDepartmentFetch.pending]: (state) => {
            state.loading = true;
        },
        [getGqJobDepartmentFetch.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload?.data;
            state.error = null;
        },
        [getGqJobDepartmentFetch.rejected]: (state, { payload }) => {
            state.loading = false;
            state.data = null;
            state.error = payload;
        },
    },
});

export const getGqJobDepartmentFetchSelector = (state) => state.getGqJobDepartmentFetch;
export const getGqJobDepartmentFetchReducer = getGqJobDepartmentFetchSlice.reducer;
