export const ENV_MODE = (REACT_ENV && REACT_ENV.REACT_APP_ENV) || 'development';
export const CUSTOMER_BASE_URL = (REACT_ENV && REACT_ENV.REACT_APP_CUSTOMER_BASE_URL) || 'https://customer.graydev.tech';
export const INSTITUTE_BASE_URL = (REACT_ENV && REACT_ENV.REACT_APP_INSTITUTE_BASE_URL) || 'https://institute.graydev.tech';
export const LANDING_BASE_URL = (REACT_ENV && REACT_ENV.REACT_APP_LANDING_BASE_URL) || 'https://graydev.tech';
export const LANDING_API_BASE_URL = (REACT_ENV && REACT_ENV.REACT_APP_LANDING_API_BASE_URL) || 'https://lan-api.graydev.tech';
export const CMS_API_BASE_URL = (REACT_ENV && REACT_ENV.REACT_APP_CMS_API_BASE_URL) || 'https://cms.graydev.tech';
export const ERP_SDK_SCRIPT_URL = (REACT_ENV && REACT_ENV.REACT_APP_ERP_SDK_SCRIPT_URL) || 'https://erp-sdk.graydev.tech/v1/gqerp-sdk.min.js?v=latest';
export const ERP_SDK_ENV = (REACT_ENV && REACT_ENV.REACT_APP_ERP_SDK_ENV) || 'test';
export const GQ_API_KEY = (REACT_ENV && REACT_ENV.REACT_APP_GQ_API_KEY) || '90bd4fce-cd9c-49f5-b1c2-ce5f8a54420a';
export const OLD_WEBSITE = (REACT_ENV && REACT_ENV.REACT_APP_OLD_WEBSITE) || 'https://pay.graydev.tech';

export const SENTRY_ENABLED = (REACT_ENV && REACT_ENV.REACT_APP_SENTRY_ENABLED) || 'FALSE';
export const SENTRY_DSN = (REACT_ENV && REACT_ENV.REACT_APP_SENTRY_DSN) || '';
export const SENTRY_AUTH_TOKEN = (REACT_ENV && REACT_ENV.REACT_APP_SENTRY_AUTH_TOKEN) || '';
export const SENTRY_TRACES_SAMPLE_RATE= (REACT_ENV && REACT_ENV.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.25;
