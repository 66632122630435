import axios from 'axios';
import { CMS_API_BASE_URL } from '../../constants';

export const apiClient = axios.create({
    baseURL: CMS_API_BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    },
});

apiClient.defaults.trailingSlash = false;
