import React from 'react'
import classes from './LogoLoader.module.scss'

const LogoLoader = ({ text }) => {
    return (
        <div className={classes.LoaderWrap}>
            <svg xmlns="http://www.w3.org/2000/svg" className={classes.LoaderSvg} viewBox="0 0 283.76 283.39">
                <defs>
                    {/* <style>
                        .cls-1{fill:#4257dc;}
                        .cls-2{fill:#4f62de;}
                        .cls-3{fill:#4358dc;}
                    </style> */}
                </defs>
                <title>Grayquest</title>
                <path className={classes.Path} fill="white" stroke="#5667ff" strokeWidth="6" d="M58.12,192.58c2.44-6,2.4-12.54,3.83-18.78A141.64,141.64,0,0,1,188.82,64.42c76.24-5.88,143,48.55,152,124.52,4.25,35.81-4.34,68.73-25,98.39-3.92,5.63-9.35,7.14-14,4s-5.21-9-1.14-14.91C349.11,206.08,315.48,111,233.73,87.27,163.92,67,91.26,112.7,79,184.63,67.24,253.08,114.21,318,182.6,327.37c31.85,4.38,61.2-2.66,87.79-20.84.82-.57,1.6-1.21,2.45-1.73,4.77-2.86,10.4-1.77,13.25,2.55a9.44,9.44,0,0,1-2.67,12.83A139.73,139.73,0,0,1,244,340.36c-82,26.77-169.09-25.8-183.7-110.79-.69-4-.41-8.19-2.22-12v-3a32.4,32.4,0,0,0,0-18Z" transform="translate(-58.12 -63.99)"/>
                <path className={classes.Path} fill="white" stroke="#5667ff" strokeWidth="6" d="M58.12,196.58a32.4,32.4,0,0,1,0,18Z" transform="translate(-58.12 -63.99)"/>
                <path className={classes.Path} fill="white" stroke="#5667ff" strokeWidth="6" d="M200.06,306.56A101.06,101.06,0,0,1,99.13,205.42c.05-55.72,45.41-100.88,101.27-100.82,55.64.07,100.78,45.52,100.69,101.4C301,261.4,255.61,306.58,200.06,306.56Zm-.26-18.8c49,.13,86.69-41.34,82.25-90.42-.83-9.12-7.59-13.46-16.19-10.31a31.14,31.14,0,0,1-15.15,2c-8.8-1.17-15.07-6.13-21-12.34-17-17.79-44.81-16.85-61.09,1.74-15.78,18-12.63,46.18,6.78,60.56,21,15.55,50,8.25,62.31-15.68,3.06-6,7.91-8.17,12.9-5.86,5.27,2.44,6.92,7.88,3.85,14-10.36,20.5-27,32.61-49.92,34-37.81,2.32-66.1-26.32-64.46-64.18,1.47-34.08,35.49-61.23,69.06-55.28,14.66,2.6,26.92,9.21,36.74,20.44,3.82,4.37,9.33,5.31,14,2.7a11.24,11.24,0,0,0,5.25-12.44c-.78-3.24-3.07-5.5-5.29-7.74-20.14-20.29-44.4-28.78-72.73-24.51-42.86,6.47-72.4,44.91-68.93,89.27C121.39,254.84,157.56,287.65,199.8,287.76Z" transform="translate(-58.12 -63.99)"/>
                <path className={classes.Path} fill="white" stroke="#5667ff" strokeWidth="6" d="M296.13,321.71a22.36,22.36,0,0,1,44.72.23c0,12.27-10.58,22.69-22.77,22.41A22.53,22.53,0,0,1,296.13,321.71Zm15.72-.48a6.56,6.56,0,0,0,5.76,7.38,6.63,6.63,0,0,0,7.53-6.16,6.48,6.48,0,0,0-6.06-7.1A6.42,6.42,0,0,0,311.85,321.23Z" transform="translate(-58.12 -63.99)"/>
                <path className={classes.Path} fill="white" stroke="#5667ff" strokeWidth="6" d="M222.29,205.44a22.18,22.18,0,1,1-22.21-22A21.88,21.88,0,0,1,222.29,205.44Zm-22.11,7a6.86,6.86,0,1,0-6.93-6.53A7.15,7.15,0,0,0,200.18,212.44Z" transform="translate(-58.12 -63.99)"/>
            </svg>
            {/* <img src={GQLogo} className={classes.logo} alt="Loading..." /> */}
            <p className={classes.LoadingText}>{text || `Loading...`}</p>
        </div>
    )
}

export default LogoLoader;