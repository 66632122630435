import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGqBlogDataGet } from '../../../api-cms-client';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const getGqBlogDataFetch = createAsyncThunk(
    'getGqBlogDataFetch/Get',
    async (body, { rejectWithValue }) => {
        try {
            const response = await fetchGqBlogDataGet(body);
            const data = response?.data;
            return data;
        } catch (err) {
            const errObj = {
                type: 'GET_GQ_BLOG_DATA_FETCH_FAILURE',
                error: err,
                message: 'Oops! something went wrong',
            };
            return rejectWithValue(err?.response?.data || errObj);
        }
    }
);

const getGqBlogDataFetchSlice = createSlice({
    name: 'getGqBlogDataFetch',
    initialState,
    extraReducers: {
        [getGqBlogDataFetch.pending]: (state) => {
            state.loading = true;
        },
        [getGqBlogDataFetch.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload?.data;
            state.error = null;
        },
        [getGqBlogDataFetch.rejected]: (state, { payload }) => {
            state.loading = false;
            state.data = null;
            state.error = payload;
        },
    },
});

export const getGqBlogDataFetchSelector = (state) => state.getGqBlogDataFetch;
export const getGqBlogDataFetchReducer = getGqBlogDataFetchSlice.reducer;
