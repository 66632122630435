import axios from 'axios';
import { GQ_API_KEY, LANDING_API_BASE_URL } from '../../constants';

export const gqLandingApiClient = axios.create({
    baseURL: LANDING_API_BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'GQ-API-Key': GQ_API_KEY,
    },
});
