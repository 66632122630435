import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    InstituteDetailsBannerBlock,
    InstituteDetailsContentBlock,
    InstituteDetailsFooter,
    InstituteDetailsHeader,
    InstituteDetailsRewardBlock,
    InstituteDetailsSupportBlock,
} from "../../Components/molecules";
import {
    CUSTOMER_BASE_URL,
    LANDING_API_BASE_URL,
    LANDING_BASE_URL,
} from "../../../constants";
import CustomHelmet from "../../Components/CustomHelmet/CustomHelmet";
import { LogoLoader } from "../../Components/atoms";
import { gqLandingApiClient } from "../../api-landing-client/axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import sendGA4Event from "../../googleAnalytics/googleAnalytics";
import classes from "./DynamicInstituteDetails.module.scss";
import { SearchBlock } from "../../Components/organisms";

const useQuery = (searchString) => new URLSearchParams(searchString);

const DynamicInstituteDetails = () => {
    const [pageData, setPageData] = useState();
    const [isPageAPILoading, setIsPageAPILoading] = useState(true);
    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const query = useQuery(location?.search);
    

    const fetchInstitutePageData = async () => {
        gqLandingApiClient({
            method: "GET",
            url: `/v1/institute/lp-config/${param?.institute_slug}`,
        })
        .then((res) => {
            if (res?.data?.data) {
                setPageData(res.data.data);
                setIsPageAPILoading(false);
                // GA Event
                sendGA4Event({
                    type: 'event',
                    event_category: 'Institute Landing Page',
                    event_action:`LP /${param?.institute_slug} Visited`,
                    event_label: `LP /${param?.institute_slug} Visited`,
                });
            } else {
                toast.error("Oops! Something went wrong");
                navigate("/");
            }
        })
        .catch((err) => {
            console.error("res errr::", err?.response?.data);
            toast.error(err?.response?.data?.message || "Oops! Something went wrong");
            navigate("/");
        });
    };

    useEffect(() => {
        fetchInstitutePageData();
    }, []);

    const PageBreadcrumbSchema = {
    "@context": "http://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
        {
        "@type": "ListItem",
        position: "1",
        item: {
            "@id": LANDING_BASE_URL,
            name: "Home",
        },
        },
        {
        "@type": "ListItem",
        position: "2",
        item: {
            "@id": LANDING_BASE_URL + "/" + param.institute_slug,
            name:
            pageData && pageData.institute_name
                ? pageData.institute_name
                : "GrayQuest Institute Partner",
        },
        },
    ],
    };

    if (isPageAPILoading) {
        return (<div className={classes.FullPageLoader}>
            <LogoLoader />
        </div>);
    }

    return (
        <div>
            <CustomHelmet
                instituteName={pageData?.institute_config?.institute_label || "GrayQuest Institute Partner"}
                page="instituteDetails"
            >
                <script type="application/ld+json">
                {JSON.stringify(PageBreadcrumbSchema)}
                </script>
            </CustomHelmet>

            <div className={classes.InstituteDetailsPage}>
                <div className={`${classes.InstituteDetailsPageContainer} container`}>
                    {/* HEADER BLOCK */}
                    <InstituteDetailsHeader pageData={pageData} />

                    {/* SEARCH BLOCK */}
                    {pageData?.search_block?.visible ? (
                        <div className={classes.SearchBlock}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <SearchBlock pageData={pageData} />
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* BANNER BLOCK */}
                    {pageData?.banner_block?.visible ? (
                        <div className={classes.BannerBlock}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <InstituteDetailsBannerBlock pageData={pageData} />
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* CONTENT BLOCK - (Loop Array) */}
                    {pageData?.content_blocks?.length > 0 ? (
                        <div className={classes.ContentBlock}>
                            <InstituteDetailsContentBlock pageData={pageData} />
                        </div>
                    ) : null}

                    {/* REWARD BLOCK */}
                    {/* {pageData?.reward_block?.visible ? (
                        <div className={classes.RewardBlock}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <InstituteDetailsRewardBlock pageData={pageData} />
                                </div>
                            </div>
                        </div>
                    ) : null} */}

                    {/* SUPPORT BLOCK */}
                    {pageData?.support_block?.visible ? (
                        <div className={classes.SupportBlock}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <InstituteDetailsSupportBlock pageData={pageData} />
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* FOOTER BLOCK */}
                    <InstituteDetailsFooter />
                </div>
            </div>
        </div>
    );
};

export default DynamicInstituteDetails;
