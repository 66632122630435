import React from 'react';
import { CUSTOMER_BASE_URL } from '../../../../constants';
import { InstituteDetailsParagraph } from '../../atoms';
import { SdkForm } from '..';
import RyanLogoImage from '../../../assets/images/institute_details/ryan_logo.jpg';
import classes from './InstituteDetailsHeader.module.scss';

const InstituteDetailsHeader = ({ pageData }) => {
    const { logo_block, header_block, sdk_block } = pageData;

    if (!logo_block?.visible && !header_block?.visible && !sdk_block?.visible) return null;

    let rowClasses = "row";
    let logColClasses = "col-lg-4";
    let HeaderColClasses = "col-lg-8";

    if (logo_block?.visible &&  logo_block?.images?.length > 0) {
        if (logo_block?.images?.length === 1) {
            if (logo_block?.position === "top") {
                logColClasses = "col-lg-12";
                HeaderColClasses = "col-lg-12";
            } else if (logo_block?.position === "right") {
                rowClasses = "row flex-lg-row-reverse";
            } else if (logo_block?.position === "bottom") {
                logColClasses = "col-lg-12";
                HeaderColClasses = "col-lg-12";
                rowClasses = "row flex-column-reverse";
            }
        } else {
            if (logo_block?.position === "top") {
                logColClasses = "col-lg-12";
                HeaderColClasses = "col-lg-12";
            } else if (logo_block?.position === "bottom") {
                logColClasses = "col-lg-12";
                HeaderColClasses = "col-lg-12";
                rowClasses = "row flex-column-reverse";
            }
        }
    } else {
        HeaderColClasses = "col-lg-12";
    }

    let ctaButtonPostion = "";
    let ctaTarget = "_blank";
    if (sdk_block?.cta_config?.position === "left") {
        ctaButtonPostion = classes.Left;
    } else if (sdk_block?.cta_config?.position === "right") {
        ctaButtonPostion = classes.Right;
    }
    if (sdk_block?.cta_config?.new_window === false) {
        ctaTarget = "_self";
    }

    return (
        <div className={classes.InstituteDetailsHeader}>
            <div className={rowClasses}>
                {(logo_block?.visible && logo_block?.images?.length > 0) ? 
                    (<div className={logColClasses}>
                        <div className={classes.HeaderLogoWrap}>
                            {logo_block.images.map((img, index) => (
                                <img 
                                    key={`header-logo-${index}`}
                                    // src={RyanLogoImage}
                                    src={img} 
                                    className={classes.HeaderLogo}
                                    alt={pageData?.institute_config?.institute_label || "logo"} 
                                />))
                            }
                        </div>
                    </div>) : null
                }

                {(header_block?.visible || sdk_block?.visible) ? 
                    (<div className={HeaderColClasses}>
                        <div className="row">
                            {(header_block?.visible) ? 
                                (<div className="col-lg-12">
                                    <div className={classes.HeaderContentWrap}>
                                        {(header_block?.title) ? 
                                            (<h1 className={classes.Title}> {header_block.title}</h1>) 
                                            : null
                                        }
        
                                        {(header_block?.subtitle) ? 
                                            (<h3 className={classes.SubTitle}>{header_block.subtitle}</h3>) 
                                            : null
                                        }
                
                                        {(header_block?.description?.length > 0) ? 
                                            (<div className={classes.Description}>
                                                {header_block.description.map((paragraph, index) => (
                                                    <InstituteDetailsParagraph key={`header-para-${index}`} paragraph={paragraph} />
                                                ))}
                                            </div>) : null
                                        }
                                    </div>
                                </div>) : null
                            }

                            {(sdk_block?.visible) ? 
                                (<div className="col-lg-12">
                                    {(sdk_block?.student_uuid_visible || sdk_block?.mobile_visible) ? 
                                        (<div className={`${classes.HeaderActionWrap} ${ctaButtonPostion}`}>
                                            <div className="row">
                                                <div className="col-lg-7 col-md-9 mx-auto">
                                                    <SdkForm pageData={pageData} />
                                                </div>
                                            </div>
                                        </div>)
                                        : (<div className={`${classes.HeaderActionWrap} ${ctaButtonPostion}`}>
                                            <a href={sdk_block?.cta_config?.url || CUSTOMER_BASE_URL}
                                                rel="noopener noreferrer nofollow" 
                                                target={ctaTarget}
                                                className={classes.HeaderActionLink}
                                            >
                                                {sdk_block?.cta_config?.label || "Get Started"}
                                            </a>
                                        </div>)
                                    }
                                </div>) : null
                            }
                        </div>
                    </div>) : null
                }
            </div>
        </div>
    );
};

export default InstituteDetailsHeader;
