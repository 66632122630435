import React from 'react';
import { InstituteDetailsParagraph } from '../../atoms';
import classes from './InstituteDetailsContentBlock.module.scss';

const InstituteDetailsContentBlock = ({ pageData }) => {
    return (
        <div className={classes.InstituteDetailsContentBlock}>
            {
                pageData.content_blocks.map((block, index) => {
                    if (!block.visible) return null;

                    let ctaButtonPostion = "";
                    let ctaTarget = "_blank";
                    if (block?.cta_config?.position === "left") {
                        ctaButtonPostion = classes.Left;
                    } else if (block?.cta_config?.position === "right") {
                        ctaButtonPostion = classes.Right;
                    }
                    if (block?.cta_config?.new_window === false) {
                        ctaTarget = "_self";
                    }

                    return (
                        <div key={`content-block-${index}`} className="row">
                            <div className="col-lg-12">
                                <div className={classes.Content}>
                                    {(block?.title) ? 
                                        (<h2 className={classes.Title}> {block.title}</h2>) 
                                        : null
                                    }
    
                                    {(block?.subtitle) ? 
                                        (<h3 className={classes.SubTitle}>{block.subtitle}</h3>) 
                                        : null
                                    }
    
                                    {(block?.images?.length > 0) ? 
                                        (block.images.map((img, index) => (<img src={img} key={`block-image-${index}}`} className={classes.Image} alt="support" />))) 
                                        : null
                                    }
    
                                    {(block?.description?.length > 0) ? 
                                        (<div className={classes.Description}>
                                            {block.description.map((paragraph, index) => (
                                                <InstituteDetailsParagraph key={`block-para-${index}`} paragraph={paragraph} />
                                            ))}
                                        </div>) : null
                                    }
    
                                    {(block?.cta_config?.url) ? 
                                        (<div className={`${classes.BlockAction} ${ctaButtonPostion}`}>
                                            <a href={block.cta_config.url} 
                                                rel="noopener noreferrer nofollow" 
                                                target={ctaTarget} 
                                                className={classes.CATLink}
                                            >
                                                {block?.cta_config?.label || "View Details"}
                                            </a>
                                        </div>) : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default InstituteDetailsContentBlock