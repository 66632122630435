import React, { useState, useEffect, useRef, useMemo } from 'react';
import titlestar from '../../assets/images/institute/title-star.svg';
import instituteHero from '../../assets/images/institute/institute-hero.png';
import instituteMobileImg from '../../assets/images/institute/institute-mobile-img.png';
import plugin from '../../assets/images/institute/plugin.png';
import qrimage from '../../assets/images/institute/qrimage.png';
import moneytransfer from '../../assets/images/institute/moneytransfer.png';
import feature2Img from '../../assets/images/institute/feature2Img.png';
import paymentOne from '../../assets/images/institute/payment-one.webp';
import paymentsecond from '../../assets/images/institute/paymentsecond.png';
import mobileDashboard from '../../assets/images/institute/mobileDashboard.png';
import sliderArrow from '../../assets/images/institute/slider-arrow.svg';
import Accordion from 'react-bootstrap/Accordion';
import Footer1 from '../Common/Footer1/Footer1';
import Header1 from '../Common/Header1/Header1';
import paymentzero from '../../assets/images/institute/paymentzero.png';
import clientTestimonial from '../../assets/video/test.mp4';
import quote from '../../assets/images/institute/quote.png';
import thumbnailRyan from '../../assets/images/home/thumbnail_Ryan.png';
import debit0 from '../../assets/images/institute/debit0.png';
import debit1 from '../../assets/images/institute/debit1.png';
import debit2 from '../../assets/images/institute/debit2.png';
import online1 from '../../assets/images/institute/online1.png';
import online2 from '../../assets/images/institute/online2.png';
import online3 from '../../assets/images/institute/online3.png';
import thunderImage from '../../assets/images/institute/thunderImage.png';
import discountImage from '../../assets/images/institute/discountImage.png';
import instituteBannerResponsive from '../../assets/images/institute/instituteBannerResponsive.png';
import institutebanner from '../../assets/images/institute/institutebanner.png';
import introducingRapid_Desktop from '../../assets/images/institute/introducingRapid_Desktop.png';
import onlinePayment_Desktop from '../../assets/images/institute/onlinePayment_Desktop.png';
import fist from '../../assets/images/institute/fist.png';
import feature_one_desktop from '../../assets/images/institute/feature_one_desktop.png';
import pinkdiamond from '../../assets/images/institute/pinkdiamond.png';
import add from '../../assets/images/institute/add.png';
import subtract from '../../assets/images/institute/subtract.png';
import feature_four_desktop from '../../assets/images/institute/feature_four_desktop.png';
import bankImageP from '../../assets/images/institute/bankImageP.png';
import mobile from '../../assets/images/institute/mobile.png';
import note from '../../assets/images/institute/note.png';
import radio from '../../assets/images/institute/radio.png';
import rupee from '../../assets/images/institute/rupee.png';
import swipe from '../../assets/images/institute/swipe.png';
import wallet from '../../assets/images/institute/wallet.png';
import qrCode from '../../assets/images/institute/window.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import InstituteSlider from './InstituteSlider';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Institute.css';
import ryanInsititueLogo from '../../assets/images/institute/mask2.png';
import podarInstituteLogo from '../../assets/images/institute/podarInstituteLogo.png';
import CustomHelmet from '../CustomHelmet/CustomHelmet';

export function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIntersecting(entry.isIntersecting)
            ),
        [ref]
    );

    
    useEffect(() => {
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);
    
    return isIntersecting;
}

function Institue() {
    const swiperRef = useRef();
    const navigate = useNavigate();
    const [hideRightArrow, setHideRightArrow] = useState(false);

        

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    
    const [selectedIdx, setSelectedIdx] = useState(1);
    const [selectedIdx1, setSelectedIdx1] = useState(1);
    const [selectedIdx2, setSelectedIdx2] = useState(1);
    
    
    const [help1, setHelp1] = useState(false);
    const [help2, setHelp2] = useState(false);
    const [help3, setHelp3] = useState(false);
    const [help4, setHelp4] = useState(false);
    const [help5, setHelp5] = useState(false);
    const [help6, setHelp6] = useState(false);
    
    const images1 = [paymentzero, paymentOne, paymentsecond];
    const images2 = [debit0, debit1, debit2];
    const images3 = [online1, online2, online3];
    
    const paymentOption1Interval = useRef(null);
    const paymentOption1Ref = useRef(null);
    const paymentOption2Interval = useRef(null);
    const paymentOption2Ref = useRef(null);
    const paymentOption3Interval = useRef(null);
    const paymentOption3Ref = useRef(null);
    
    const startInterval = (callback, intervalRef) => {
        intervalRef.current = setInterval(callback, 3000);
    };
    
    const stopInterval = (intervalRef) => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    };
    
    const isPaymentOption1Visible = useOnScreen(paymentOption1Ref);
    const isPaymentOption2Visible = useOnScreen(paymentOption2Ref);
    const isPaymentOption3Visible = useOnScreen(paymentOption3Ref);

    useEffect(() => {
        if (isPaymentOption1Visible) {
            !paymentOption1Interval.current &&
                startInterval(() => {
                 
                    
                setSelectedIdx((oldIdx) => (oldIdx === 3 ? 1 : oldIdx + 1));
            }, paymentOption1Interval);
        } else {
            paymentOption1Interval.current &&
            stopInterval(paymentOption1Interval);
        }
    }, [isPaymentOption1Visible]);

    useEffect(() => {
        if (isPaymentOption2Visible) {
            !paymentOption2Interval.current &&
            startInterval(() => {
               
                setSelectedIdx1((oldIdx) =>
                oldIdx === 3 ? 1 : oldIdx + 1
                );
            }, paymentOption2Interval);
        } else {
            paymentOption2Interval.current &&
            stopInterval(paymentOption2Interval);
        }
    }, [isPaymentOption2Visible]);
    
    useEffect(() => {
        if (isPaymentOption3Visible) {
            !paymentOption3Interval.current &&
            startInterval(() => {
    
                setSelectedIdx2((oldIdx) =>
                oldIdx === 3 ? 1 : oldIdx + 1
                    );
                }, paymentOption3Interval);
        } else {
            paymentOption3Interval.current &&
                stopInterval(paymentOption3Interval);
        }
    }, [isPaymentOption3Visible]);

    const handlePaymentOptionClick = (optionIndex) => {
        switch (optionIndex) {
            case 1:
                setSelectedIdx(1)

                break;
            case 2:
                setSelectedIdx(2)

                break;
            case 3:
                setSelectedIdx(3)
                break;
            default:
                break;
        }
    }
    
    const handlePaymentOptionClick1 = (optionIndex) => {
        switch (optionIndex) {
            case 1:
                setSelectedIdx1(1)

                break;
            case 2:
                setSelectedIdx1(2)

                break;
            case 3:
                setSelectedIdx1(3)
                break;
            default:
                break;
        }
    }

    const handlePaymentOptionClick3 = (optionIndex) => {
        switch (optionIndex) {
            case 1:
                setSelectedIdx2(1)

                break;
            case 2:
                setSelectedIdx2(2)

                break;
            case 3:
                setSelectedIdx2(3)
                break;
            default:
                break;
        }
    }

    const sliderSettings = {
        0: {
            slidesPerView: 1,
        },
        425: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 1.3,
            spaceBetween: 20,
        },
    };

    return (
        <>
              <CustomHelmet page="institute" />
            <main className='institues institute parents instituteStyle'>
                <Header1 theme={'institute'} />
                <section className='bg--blue pb-0 hero institute'>
                    <div className='instituteContainer'>
                        <div className=''>
                            <h1 className='text--bold section__title-1 text-center text-white bannerHeading'>
                                Experience an{' '}
                                <span className='text--pinkGradient'>
                                    advanced fee management
                                </span>{' '}
                                platform for your educational institute
                            </h1>
                            <div className='center-btn-box'>
                                <a
                                    className='custom__btn custom__btn--blue'
                                    onClick={() => navigate('/contact')}
                                >
                                    Schedule demo
                                </a>
                            </div>
                            <div className='hero__banner institute_bannerImage'>
                                <img
                                    className='img-fluid image_mobile'
                                    src={instituteBannerResponsive}
                                    alt='parent hero'
                                />
                                <img
                                    className='img-fluid  image_desktop'
                                    src={institutebanner}
                                    alt='parent hero'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/*** Introducting Rapid section  ***/}
                <section className='bg--withPadding pb-0'>
                    <div className='container-fluid rapidContainer'>
                        <h1 className='text--bold section__title-1 text-center rapidHeading'>
                            Introducing{' '}
                            <span className='text--blueGradient text-spiral rapidTxt'>
                                Rapid
                            </span>{' '}
                        </h1>
                        <div className='row payment-section'>
                            <div className='col-lg-6 order-lg-1 order-2 introductingRapidImage'>
                                <div className='experience-banner'>
                                    <img
                                        className='img-fluid  image_desktop'
                                        src={introducingRapid_Desktop}
                                    />
                                    <img
                                        className='img-fluid  image_mobile'
                                        src={instituteMobileImg}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 order-lg-2 order-1 introductingRapidTxt'>
                                <h2
                                    className='section__title-1-small text--bold text--black'
                                    id='section__title-1-small-payment'
                                >
                                    All-in-one powerful fee payment solution
                                </h2>
                                <div className='pay-ontainer'>
                                    <div className='payment-desc-section'>
                                        A first-of-its-kind, all in one payment
                                        platform specially designed for the
                                        education ecosystem.
                                    </div>
                                    <div className='payment-desc-section'>
                                        RAPID enables educational institutes to
                                        collect fees in up to 8 different
                                        payment modes on one platform!
                                    </div>
                                    <div className='payment-desc-section text-spiral eightPlus'>
                                        8+ payment modes on one platform
                                    </div>
                                </div>
                                <div className='payment-logo-section'>
                                    <div className='rapidOptions'>
                                        <div>
                                            <img src={rupee} />
                                        </div>
                                        <div>
                                            <img src={radio} />
                                        </div>
                                        <div>
                                            <img src={mobile} />
                                        </div>
                                        <div>
                                            <img src={note} />
                                        </div>
                                        <div>
                                            <img src={qrCode} />
                                        </div>
                                        <div>
                                            <img src={wallet} />
                                        </div>
                                        <div>
                                            <img src={bankImageP} />
                                        </div>
                                        <div>
                                            <img src={swipe} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        marginTop: '30px',
                                    }}
                                >
                                    <Link to='/contact'>
                                        <div className='custom__btn custom__btn--blue'
                                        style={{
                                            textDecoration: 'none',
                                        }}>
                                            Schedule demo
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='testing'>
                    {/* Section 1 */}
                    <section
                        className='bg--lightGray zeroCostSection'
                        ref={paymentOption1Ref}
                    >
                        <div className='container zeroCostContainer'>
                            <div id='zeroCostEmiContainer' className='big-screen zeroCostContainerFlex'>
                                <div className='col-lg-6 zeroCostEmiTxt'>
                                    <p className='section__label blue'>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        Payment Option 1
                                    </p>
                                    <h2 className='section__title text--bold text--black'>
                                        <span className='text-spiral zeroTxt'>
                                            Zero Cost EMI
                                        </span>
                                    </h2>
                                    <p className='text--black text--regular section__desc offerparentTxt'>
                                        Offer parents the convenience of paying
                                        fees in affordable monthly instalments
                                        with a quick 2 min sign-up!
                                    </p>
                                    <ul className='custom__accordion '>
                                        <li
                                            className={(selectedIdx === 1) ? 'active': selectedIdx > 1? 'completed' : 'completed'}
                                            onClick={() => handlePaymentOptionClick(1)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right zeroCostDropdown'>
                                                <p className='text--bold custom__accordion-title'>
                                                    Get the full year’s fee,
                                                    upfront
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular custom__accordion-desc'>
                                                        Receive upfront fees at
                                                        the beginning of the
                                                        academic year from
                                                        GrayQuest, even if
                                                        parents pay in parts.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            className={(selectedIdx === 2) ? 'active': selectedIdx < 2? '' : 'completed'}
                                            onClick={() => handlePaymentOptionClick(2)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right zeroCostDropdown'>
                                                <p className='text--bold  custom__accordion-title'>
                                                    Boost institute’s cashflow
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular custom__accordion-desc'>
                                                        Use the upfront cash flow for your growth and expansion plans.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            className={(selectedIdx === 3) ? 'active': selectedIdx < 3? '' : 'completed'}
                                            onClick={() => handlePaymentOptionClick(3)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right zeroCostDropdown'>
                                                <p className='text--bold  custom__accordion-title'>
                                                    Digital and secure payments
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular  custom__accordion-desc'>
                                                        We have a simple, 2-min sign up process that’s completely digital.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-lg-6 zeroCostEmiImage'>
                                    {selectedIdx >= 1 && (
                                        <div className='custom__accordion-image'>
                                            <img
                                                className='img-fluid image_desktop'
                                                src={images1[selectedIdx-1]}
                                                alt=''
                                            />
                                            <img
                                                className='img-fluid image_mobile'
                                                src={images1[selectedIdx-1]}
                                                alt=''
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className='row'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {
                                    <div className='mobile-view-accordian-image'>
                                        <Slider {...settings}>
                                            {images1.map((img, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>
                                                            <img
                                                                className='img-fluid'
                                                                src={img}
                                                                alt=''
                                                                style={{
                                                                    width: '323px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                }
                            </div>

                            <div>
                                <div className='mobile-view-accordian-box'>
                                    <Slider {...settings1}>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Get the full year’s fee, upfront
                                            </div>
                                            <div className='mobile-box-desc'>
                                                Receive upfront fees at the
                                                beginning of the academic year
                                                from GrayQuest, even if parents
                                                pay in parts.
                                            </div>
                                        </div>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Auto-reconciliation of payments
                                            </div>
                                            <div className='mobile-box-desc'>
                                                Use the upfront cash flow for
                                                your growth and expansion plans.
                                            </div>
                                        </div>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Instant fee settlement
                                            </div>
                                            <div className='mobile-box-desc'>
                                                We have a simple, 2-min sign up
                                                process that’s completely
                                                digital.
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className='small-screen'>
                                <div className='custom__accordion-slider'>
                                    <div className='item'></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Section 2 */}
                    <section
                        className='bg--yellow autoDeptSection'
                        ref={paymentOption2Ref}
                    >
                        <div className='container autoDeptContainer'>
                            <div id='autoDebitContainer' className='big-screen autoDeptFlex'>
                                <div className='col-lg-6 zeroCostEmiImage'>
                                    {selectedIdx1 >= 1 && (
                                        <div className='custom__accordion-image'>
                                            <img
                                                className='img-fluid image_desktop'
                                                src={images2[selectedIdx1-1]}
                                                alt=''
                                            />
                                            <img
                                                className='img-fluid image_mobile'
                                                src={images2[selectedIdx1-1]}
                                                alt=''
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className='col-lg-6 autoDept_Txt'>
                                    <p className='section__label blue'>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        Payment Option 2
                                    </p>
                                    <h2 className='section__title text--bold text--black'>
                                        <span className='autoDebtTxt'>
                                            Auto-Debit Subscription Payments
                                        </span>
                                    </h2>
                                    <p className='text--black text--regular section__desc'>
                                        No more post-dates cheques! Collect fees
                                        as per due dates by automatically
                                        debiting them from the parent’s account!
                                    </p>
                                    <ul className='custom__accordion'>
                                        <li
                                            className={(selectedIdx1 === 1) ? 'active': selectedIdx1 > 1? 'completed' : 'completed'}
                                            onClick={() => handlePaymentOptionClick1(1)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right paymentOptionDropdown'>
                                                <p className='text--bold custom__accordion-title'>
                                                    50 sec sign up for auto-debit
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular custom__accordion-desc'>
                                                        A simple process for
                                                        parents to register for
                                                        auto-debit and can use
                                                        the same registration
                                                        for upto 15 years.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            className={(selectedIdx1 === 2) ? 'active': selectedIdx1 < 2? '' : 'completed'}
                                            onClick={() => handlePaymentOptionClick1(2)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right paymentOptionDropdown'>
                                                <p className='text--bold  custom__accordion-title'>
                                                    Send fee-related alerts and
                                                    reminders
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular custom__accordion-desc'>
                                                        With one click, send
                                                        custom fee reminders and
                                                        notifications to parents
                                                        through WhatsApp, email
                                                        and SMS.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                             className={(selectedIdx1 === 3) ? 'active': selectedIdx1 < 3? '' : 'completed'}
                                             onClick={() => handlePaymentOptionClick1(3)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right paymentOptionDropdown'>
                                                <p className='text--bold  custom__accordion-title'>
                                                    Eliminate fee follow-ups
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular  custom__accordion-desc'>
                                                        With auto-debit
                                                        payments, fee will be
                                                        auto-debited from
                                                        parent’s account on due
                                                        dates.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className='row'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {
                                    <div className='mobile-view-accordian-image'>
                                        <Slider {...settings}>
                                            {images2.map((img, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>
                                                            <img
                                                                className='img-fluid image_desktop'
                                                                src={
                                                                    onlinePayment_Desktop
                                                                }
                                                                alt=''
                                                                style={{
                                                                    width: '323px',
                                                                }}
                                                            />
                                                            <img
                                                                className='img-fluid'
                                                                src={img}
                                                                alt=''
                                                                style={{
                                                                    width: '323px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className='mobile-view-accordian-box'>
                                    <Slider {...settings1}>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                50 sec sign up for auto-debit
                                            </div>
                                            <div className='mobile-box-desc'>
                                                A simple process for parents to
                                                register for auto-debit and can
                                                use the same registration for
                                                upto 15 years.
                                            </div>
                                        </div>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Send fee-related alerts and
                                                reminders
                                            </div>
                                            <div className='mobile-box-desc'>
                                                With one click, send custom fee
                                                reminders and notifications to
                                                parents through WhatsApp, email
                                                and SMS.
                                            </div>
                                        </div>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Eliminate fee follow-ups
                                            </div>
                                            <div className='mobile-box-desc'>
                                                With auto-debit payments, fee
                                                will be auto-debited from
                                                parent’s account on due dates.
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>

                            <div className='small-screen'>
                                <div className='custom__accordion-slider'>
                                    <div className='item'></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Section 3 */}
                    <section
                        className='bg--lightGray onlinePaymentSection'
                        ref={paymentOption3Ref}
                    >
                        <div className='container onlinePaymentContainer'>
                            <div id='onlinePaymentContainer' className='big-screen'>
                                <div className='col-lg-6'>
                                    <p className='section__label blue'>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        Payment Option 3
                                    </p>
                                    <h2 className='section__title text--bold text--black'>
                                        <span className='text-spiral'>
                                            Online Payments
                                        </span>
                                    </h2>
                                    <p className='text--black text--regular section__desc'>
                                        Parents can simply choose to pay online
                                        through a variety of online payment
                                        methods covered under RAPID.
                                    </p>
                                    <ul className='custom__accordion'>
                                        <li
                                             className={(selectedIdx2 === 1) ? 'active': selectedIdx2 > 1? 'completed' : 'completed'}
                                             onClick={() => handlePaymentOptionClick3(1)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right'>
                                                <p className='text--bold custom__accordion-title'>
                                                    Flexible fee-payment options
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular custom__accordion-desc'>
                                                        Collect fees from
                                                        parents via UPI, debit
                                                        cards, net banking,
                                                        credit cards, and
                                                        wallets seamlessly!
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                           className={(selectedIdx2 === 2) ? 'active': selectedIdx2 < 2? '' : 'completed'}
                                           onClick={() => handlePaymentOptionClick3(2)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right'>
                                                <p className='text--bold  custom__accordion-title'>
                                                    Auto-reconciliation of
                                                    payments
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular custom__accordion-desc'>
                                                        Use the upfront cash
                                                        flow for your growth and
                                                        expansion plans.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                             className={(selectedIdx2 === 3) ? 'active': selectedIdx2 < 3? '' : 'completed'}
                                             onClick={() => handlePaymentOptionClick3(3)}
                                        >
                                            <span className='dot'></span>
                                            <div className='right'>
                                                <p className='text--bold  custom__accordion-title'>
                                                    Digital and secure payments
                                                </p>
                                                <div className='desc__container'>
                                                    <p className='text--gray text--regular  custom__accordion-desc'>
                                                        We have a simple, 2-min
                                                        sign up process that’s
                                                        completely digital.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-lg-6 zeroCostEmiImage'>
                                    {selectedIdx2 >= 1 && (
                                        <div className='custom__accordion-image'>
                                            <img
                                                className='img-fluid image_desktop'
                                                src={images3[selectedIdx2-1]}
                                                alt=''
                                            />
                                            <img
                                                className='img-fluid image_mobile'
                                                src={images3[selectedIdx2-1]}
                                                alt=''
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className='row'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {
                                    <div className='mobile-view-accordian-image'>
                                        <Slider {...settings}>
                                            {images3.map((img, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>
                                                            <img
                                                                className='img-fluid'
                                                                src={img}
                                                                alt=''
                                                                style={{
                                                                    width: '323px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className='mobile-view-accordian-box'>
                                    <Slider {...settings1}>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Flexible fee-payment options
                                            </div>
                                            <div className='mobile-box-desc'>
                                                Collect fees from parents via
                                                UPI, debit cards, net banking,
                                                credit cards, and wallets
                                                seamlessly!
                                            </div>
                                        </div>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Auto-reconciliation of payments
                                            </div>
                                            <div className='mobile-box-desc'>
                                                All payments get reconciled
                                                automatically for students and
                                                institutes. Match information
                                                easily with your accounting
                                                software.
                                            </div>
                                        </div>
                                        <div className='mobile-box-white'>
                                            <div
                                                className='mobile-box-title'
                                                style={{
                                                    width: '260px',
                                                    fontSize: '20px',
                                                    marginTop: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Instant fee settlement
                                            </div>
                                            <div className='mobile-box-desc'>
                                                Receive the fee amount instantly
                                                with online payment.
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className='small-screen'>
                                <div className='custom__accordion-slider'>
                                    <div className='item'></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='tableView'>
                    <InstituteSlider />
                </div>
                {/* /********************************************************************** * */}

                {/*** 992 Start ***/}

                {/*** 992  end ***/}

                {/* Why Radip Section */}
                <section className=' whyRadipContainer bg--blue'>
                    <div className='container  '>
                        <h2 className='rapid_section__title-1 text--bold text--white'>
                            Why is RAPID so{' '}
                            <span className='text--pinkGradient text-spiral'>
                                easy to use?
                            </span>
                        </h2>
                        <div className='rapid-icons-box'>
                            <div className='rapid-icon-section'>
                                <img src={plugin} />
                                <h3 className='rapid-icon-text'>
                                    <span className='text--white text--bold'>
                                        Instantly plugs
                                    </span>{' '}
                                    in with your existing softwares.
                                </h3>
                            </div>
                            <div className='rapid-icon-section'>
                                <img src={qrimage} />
                                <h3 className='rapid-icon-text'>
                                    Also offers, on-campus fee collection via{' '}
                                    <span className='text--white text--bold'>
                                        QR codes.
                                    </span>
                                </h3>
                            </div>
                            <div className='rapid-icon-section'>
                                <img src={moneytransfer} />
                                <h3 className='rapid-icon-text'>
                                    <span className='text--white text--bold'>
                                        Customise fee
                                    </span>{' '}
                                    collection experience for each student.
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ARC */}
                <section className='bg--lightGray arc archeadSection'>
                    <div className='container'>
                        <h2 className='text--bold section__title-1 text-center archeadHead'>
                            Introducing{' '}
                            <span className='text--blueGradient text-spiral'>
                                ARC
                            </span>
                        </h2>
                        <p className='section__desc arcTxt'>
                            A thoughtfully designed SAAS platform to cater to
                            the needs of your educational institute, ranging
                            from seamless fee collection to parent communication
                        </p>
                        <div className='center-btn-box'>
                            <Link to='/contact'>
                                <div className='custom__btn custom__btn--blue'
                                style={{
                                    textDecoration: 'none',
                                }}>
                                    Schedule demo
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <section className='bg--withPadding pb-0'>
                    <div className='container featureContainer'>
                        <div className='userFriendlyTxt'>
                            <div className='row'>
                                <p className='section__label blue mb-3'>
                                    <img src={titlestar} />
                                    FEATURE 1
                                </p>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='feature-box'>
                                        <div className='section__title text--bold text--black'>
                                            <span className='text--blueGradient'>
                                                {' '}
                                                User-friendly dashboard
                                            </span>{' '}
                                            to analyse your collection summary
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='feature-box'>
                                        <p className='text-left feature-box-text feature_oneTxt'>
                                            Arc lets you track and analyse your
                                            fee collection summary in a
                                            simplified eagle-eyed dashboard.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className='row'
                            style={{
                                marginTop: '40px',
                            }}
                        >
                            <div className='hero__banner useFriendlyImage'>
                                <img
                                    className='img-fluid image_desktop'
                                    src={feature_one_desktop}
                                    alt='parent hero'
                                />
                                <img
                                    className='img-fluid image_mobile'
                                    src={instituteHero}
                                    alt='parent hero'
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--lightGray featurekeyBenefit keyBenefit'>
                    <div className='container'>
                        <div className='row'>
                            <p className='section__label blue'>KEY BENEFITS</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='feature-box-1'>
                                    <div className='d-flex align-items-start'>
                                        <img
                                            src={pinkdiamond}
                                            style={{
                                                width: '27px',
                                                marginRight: '10px',
                                            }}
                                        />
                                        <p className='text-left feature-box-text'>
                                            <span className='text--bold'>
                                                Access comprehensive analytics
                                                and insights
                                            </span>{' '}
                                            on our dashboard
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='feature-box-1'>
                                    <div className='d-flex align-items-start'>
                                        <img
                                            src={pinkdiamond}
                                            style={{
                                                width: '27px',
                                                marginRight: '10px',
                                            }}
                                        />

                                        <p className='text-left feature-box-text'>
                                            <span className='text--bold'>
                                                Keep long-term, digital records
                                            </span>{' '}
                                            of past collection summaries.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--withPadding pb-0 personaliseContainer'>
                    <div className='container'>
                        <div className='row'>
                            <p className='section__label blue mb-3'>
                                <img src={titlestar} />
                                FEATURE 2
                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='feature-box'>
                                    <div className='section__title text--bold text--black'>
                                        <span className='text--blueGradient'>
                                            {' '}
                                            Personalise
                                        </span>{' '}
                                        fee payment links for parents
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='feature-box'>
                                    <p className='text-left feature-box-text feature_oneTxt'>
                                        Send custom payment links to parents &
                                        communicate via WhatsApp or other
                                        available options.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className='row'
                            style={{
                                marginTop: '40px',
                            }}
                        >
                            <div className='hero__banner sharePaymentLinkImg'>
                                {/* <img className="img-fluid image_desktop" src={personalise_desktop} alt="parent hero" /> */}
                                <img
                                    className='img-fluid  '
                                    src={feature2Img}
                                    alt='parent hero'
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--lightGray keyBenefit'>
                    <div className='container'>
                        <div className='row'>
                            <p className='section__label blue'>KEY BENEFITS</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='feature-box-1'>
                                    <div className='d-flex align-items-start'>
                                        {/* <img
                      src={titlestar}
                      style={{
                        marginRight: "10px",
                      }}
                    /> */}
                                        <img
                                            src={pinkdiamond}
                                            style={{
                                                width: '27px',
                                                marginRight: '10px',
                                            }}
                                        />

                                        <p className='text-left feature-box-text'>
                                            Communicate with parents instantly
                                            <span className='text--bold'>
                                                via WhatsApp, email, and SMS.
                                            </span>{' '}
                                            on our dashboard
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='feature-box-1'>
                                    <div className='d-flex align-items-start'>
                                        {/* <img
                      src={titlestar}
                      style={{
                        marginRight: "10px",
                      }}
                    /> */}
                                        <img
                                            src={pinkdiamond}
                                            style={{
                                                width: '27px',
                                                marginRight: '10px',
                                            }}
                                        />

                                        <p className='text-left feature-box-text'>
                                            Get the flexibility of
                                            <span className='text--bold'>
                                                sending custom payment links
                                            </span>{' '}
                                            to each parent based on their fee
                                            structure of past collection
                                            summaries.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--withPadding pb--0'>
                    <div className='container'>
                        <div className='row'>
                            <p className='section__label blue mb-3'>
                                <img src={titlestar} />
                                FEATURE 3
                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='feature-box'>
                                    <div className='section__title text--bold text--black'>
                                        <span className='text--blueGradient'>
                                            {' '}
                                            Smart payment solutions
                                        </span>{' '}
                                        for complex fee structures
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='feature-box'>
                                    <p className='text-left feature-box-text feature_oneTxt'>
                                        Customize and Collect fees at an
                                        individual parent level using our super
                                        configurable platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className='row keyContainer'
                            style={{
                                marginTop: '60px',
                            }}
                        >
                            <p
                                className='section__label keyTitle blue'
                                style={{
                                    fontSize: '22px',
                                }}
                            >
                                KEY BENEFITS
                            </p>
                        </div>
                        <div
                            className='row keyRow'
                            style={{
                                marginTop: '30px',
                            }}
                        >
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <img
                                            src={discountImage}
                                            className='discountImage'
                                        />
                                    </div>
                                    <div className='col-lg-12 mg--20'>
                                        <h6 className='discount-desc'>
                                            Apply{' '}
                                            <span className='text--bold'>
                                                fixed or one-time fee discounts
                                            </span>{' '}
                                            to individuals or groups of selected
                                            students
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <img
                                            src={bankImageP}
                                            className='discountImage'
                                        />
                                    </div>
                                    <div className='col-lg-12 mg--20'>
                                        <h6 className='discount-desc'>
                                            Create different types of fee
                                            headers and
                                            <span className='text--bold'>
                                                {' '}
                                                receive fees in multiple bank
                                                accounts{' '}
                                            </span>
                                            based on fee headers.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--lightGray pb-0  featureFourSection'>
                    <div className='container'>
                        <div className='row'>
                            <p className='section__label blue mb-3'>
                                <img src={titlestar} />
                                FEATURE 4
                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='feature-box'>
                                    <div className='section__title text--bold text--black'>
                                        Extract
                                        <span className='text--blueGradient'>
                                            {' '}
                                            customisable reports
                                        </span>{' '}
                                        for your institute’s fee-collection
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='feature-box'>
                                    <p className='text-left feature-box-text'>
                                        Refer to pre existing reports on the ARC
                                        dashboard to constantly keep track of
                                        your collections.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className='row'
                            style={{
                                marginTop: '40px',
                            }}
                        >
                            <div className='hero__banner feature_four_image'>
                                <img
                                    className='img-fluid image_mobile'
                                    src={mobileDashboard}
                                    alt='parent hero'
                                />
                                <img
                                    className='img-fluid image_desktop'
                                    src={feature_four_desktop}
                                    alt='parent hero'
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--lightBlue-1 keyBenefit'>
                    <div className='container'>
                        <div className='row'>
                            <p
                                className='section__label blue mb-3'
                                style={{
                                    fontSize: '22px',
                                }}
                            >
                                KEY BENEFITS
                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='feature-box-1'>
                                    <div className='d-flex align-items-start'>
                                        {/* <img src={titlestar} style={{ marginRight: "10px", }} /> */}
                                        <img
                                            src={pinkdiamond}
                                            style={{
                                                marginRight: '10px',
                                                width: '27px',
                                            }}
                                        />

                                        <p className='text-left feature-box-text'>
                                            <span className='text--bold'>
                                                {' '}
                                                Create custom reports
                                            </span>{' '}
                                            only with specific variables that
                                            you would like to highlight.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='feature-box-1'>
                                    <div className='d-flex align-items-start'>
                                        {/* <img
                      src={titlestar}
                      style={{
                        marginRight: "10px",
                      }}
                    /> */}
                                        <img
                                            src={pinkdiamond}
                                            style={{
                                                marginRight: '10px',
                                                width: '27px',
                                            }}
                                        />

                                        <p className='text-left feature-box-text'>
                                            Use our{' '}
                                            <span className='text--bold'>
                                                pre-defined{' '}
                                            </span>
                                            report formats to stay up to date
                                            with all collection-related data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--blue pb--0 collectManageSection'>
                    <div className='container'>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={thunderImage} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <h2 className='text--bold blue-section-title collectmanagehead'>
                                <span className='text--pinkGradient collectmanage'>
                                    {' '}
                                    Collect & manage
                                </span>{' '}
                                    with India’s most loved fee payments 
                                <span className='platformTxt'> platform</span>
                            </h2>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <h4
                                style={{
                                    width: '540px',
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: '22px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.27',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: 'rgba(255, 255, 255, 0.8)',
                                }}
                            >
                                A combination of ARC and RAPID ensure an
                                advanced, stand out platform for your institute.
                            </h4>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '30px',
                            }}
                        >
                            <a
                                className='custom__btn custom__btn--white'
                                onClick={() => navigate('/contact')}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                Schedule demo
                            </a>
                        </div>
                    </div>
                </section>
                <section className='bg--lightGray testimonial fellowParentsSection'>
                    <div className='container'>
                        <div className='testimonial__heading'>
                            <h2 className='section__title-1 mx-0 text--bold text--black'>
                                Hear it from our{' '}
                                <span className='text--blueGradient'>
                                    fellow partners
                                </span>
                            </h2>
                            <img
                                className='img-fluid'
                                src={fist}
                                alt='testimonial icon'
                            />
                        </div>
                    </div>

                    <div className='HomePageSlider'>
                        <Swiper
                            id='slider_margin'
                            className='testimonial__slider'
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            centeredSlides={true}
                            breakpoints={sliderSettings}
                            onSlideChange={(e) =>
                                e.activeIndex === 1
                                    ? setHideRightArrow(true)
                                    : setHideRightArrow(false)
                            }
                        >
                            <SwiperSlide>
                                <div className='HomePageSlider__Slide'>
                                    <div className='Slide__MediaWrap HasVideo'>
                                        <div className='VideoWrap'>
                                            <video
                                                width='100%'
                                                height='100%'
                                                controls
                                                poster={thumbnailRyan}
                                            >
                                                <source
                                                    src={clientTestimonial}
                                                    type='video/mp4'
                                                />
                                                Your browser does not support HTML video.
                                            </video>
                                        </div>
                                    </div>

                                    <div className='Slide__ContentWrap'>
                                        <img
                                            className='InstituteLogoImage'
                                            src={ryanInsititueLogo}
                                            alt='institute'
                                        />
                                        <img
                                            className='QuoteImage'
                                            src={quote}
                                            alt=''
                                        />
                                        <p className='ContentText'>
                                            GQ has proven to be fantastic at our school. Fee collection became super easy. Also, we love using their dashboard.
                                        </p>

                                        <div className='Author'>
                                            <p className='Name'>Mr Ryan Pinto</p>
                                            <p className='Designation'>CEO, Ryan International Group</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomePageSlider__Slide'>
                                    <div className='Slide__ContentWrap'>
                                        <img
                                            className='InstituteLogoImage'
                                            src={podarInstituteLogo}
                                            alt='institute'
                                        />
                                        <img
                                            className='QuoteImage'
                                            src={quote}
                                            alt=''
                                        />
                                        <p className='ContentText'>
                                            Podar Education Network has always supported startup initiatives - if they truly add value to our ecosystem of 100+ schools. It was with this spirit that we decided to pilot GrayQuest's product in 3 of our schools more than 5 years ago when we first heard about their concept - becoming their 1st school customer. Since the pilot, we have seen GQ grow multifold in terms of their product capability, service and eventually impact on not just Podar Group but the broader Indian education ecosystem.
                                        </p>

                                        <div className='Author'>
                                            <p className='Name'>Adhar Samria</p>
                                            <p className='Designation'>CFO Podar Education Network</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className='container'>
                        <div className='slider-controls'>
                            <a
                                style={{ cursor: 'pointer' }}
                                className='slider-arrow btn-prev review-swiper-button-next'
                            >
                                <img
                                    onClick={() =>
                                        swiperRef.current?.slidePrev()
                                    }
                                    className='img-fluid'
                                    src={sliderArrow}
                                    alt='slider arrow'
                                />
                                <span className='sr-only'>Prev Btn</span>
                            </a>

                            {!hideRightArrow ? (
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        swiperRef.current?.slideNext()
                                    }
                                    className='slider-arrow btn-next'
                                >
                                    <img
                                        className='img-fluid'
                                        src={sliderArrow}
                                        alt='slider arrow'
                                    />
                                    <span className='sr-only'>
                                        Next Btn
                                    </span>
                                </a>
                            ) : null}
                        </div>
                    </div>

                    {/* <div className='slider_margin'> */}
                    {/* <Swiper
                        id='slider_margin_ins'
                        className='testimonial__slider'
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                        }}
                        modules={[Autoplay]}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        breakpoints={sliderSettings}
                        spaceBetween={50}
                        slidesPerView={2}
                        onSlideChange={(e) =>
                            e.activeIndex === 5
                                ? setHideRightArrow(true)
                                : setHideRightArrow(false)
                        }
                    >
                        <SwiperSlide>
                                <div id='insContainer'>
                                    <div id='videoPlayerIns' className='player'>
                                        <div style={{paddingBottom:"2rem"}}>
                                    <video width="100%"
                                            height="100%" controls
                                            poster={thumbnailRyan}
                                            >

                                            <source src={clientTestimonial} type="video/mp4" />
                                            Your browser does not support HTML video.

                                        </video>
                                        </div>
                                    </div>
                                    <div
                                        id='divChild3'
                                        className='slider-content'
                                     >

                                        <div  className='institute-logo qwert'>
                                            <img
                                                className='img-fluid asdfg'
                                                src={insititue}
                                                alt='dummy institute'
                                                />
                                        </div>
                                        <div className='quote-logo'>
                                            <img src={quote} 
                                            className="img-quote"
                                            alt="blue-quote" />
                                        </div>
                                        <div>
                                        <p>
                                            GQ has proven to be fantastic at our
                                            school. Fee collection became super
                                            easy. Also, we love using their
                                            dashboard.
                                        </p>
                                        </div>
                                        <div id="user-info1" className='user-info'>
                                            <p className='text--black text--bold name'>
                                            Mr Ryan Pinto
                                            </p>
                                            <p className='text--regular location'>
                                            CEO, Ryan International Group
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        
                         <SwiperSlide>
                            <div className='item'>
                                <div className='slider-image'>
                                    <img
                                        className='img-fluid'
                                        src='http://apslawchd.com/wp-content/uploads/2021/12/06.jpg'
                                        alt='dummy user'
                                    />
                                </div>
                                <div id="insCard1" className='slider-content'>
                                    <div className='institute-logo qwert'>
                                        <img
                                            className='img-fluid asdfg'
                                            src={insititue}
                                            alt='dummy institute'
                                        />
                                    </div>
                                    <div className='quote-logo'>
                                            <img src={quote} 
                                            className="img-quote"
                                            alt="blue-quote" />
                                    </div>
                                    <div>
                                    <p className='text--regular desc'>
                                        GQ has proven to be fantastic at our
                                        school. Fee collection became super
                                        easy. Also, we love using their
                                        dashboard.
                                    </p>
                                    </div>
                                    <div className='user-info'>
                                        <p className='text--black text--bold name'>
                                            Archana Rao
                                        </p>
                                        <p className='text--regular location'>
                                            Mother, Mumbai
                                        </p>
                                    </div>
                                </div>
                            </div>
                         </SwiperSlide>
                         <SwiperSlide>
                            <div className='item'>
                                <div className='slider-image'>
                                    <img
                                        className='img-fluid'
                                        src='http://apslawchd.com/wp-content/uploads/2021/12/06.jpg'
                                        alt='dummy user'
                                    />
                                </div>
                                <div id="insCard2" className='slider-content'>
                                    <div className='institute-logo qwert'>
                                        <img
                                            className='img-fluid asdfg'
                                            src={insititue}
                                            alt='dummy institute'
                                        />
                                    </div>
                                    <div className='quote-logo'>
                                            <img src={quote} 
                                            className="img-quote"
                                            alt="blue-quote" />
                                    </div>
                                    <div>
                                    <p className='text--regular desc'>
                                        GQ has proven to be fantastic at our
                                        school. Fee collection became super
                                        easy. Also, we love using their
                                        dashboard.
                                    </p>
                                    </div>
                                    <div className='user-info'>
                                        <p className='text--black text--bold name'>
                                            Archana Rao
                                        </p>
                                        <p className='text--regular location'>
                                            Mother, Mumbai
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='item'>
                                <div className='slider-image'>
                                    <img
                                        className='img-fluid'
                                        src='http://apslawchd.com/wp-content/uploads/2021/12/06.jpg'
                                        alt='dummy user'
                                    />
                                </div>
                                <div id="insCard3" className='slider-content'>
                                    <div className='institute-logo qwert'>
                                        <img
                                            className='img-fluid asdfg'
                                            src={insititue}
                                            alt='dummy institute'
                                        />
                                    </div>
                                    <div className='quote-logo'>
                                            <img src={quote} 
                                            className="img-quote"
                                            alt="blue-quote" />
                                    </div>
                                    <div>
                                    <p className='text--regular desc'>
                                        GQ has proven to be fantastic at our
                                        school. Fee collection became super
                                        easy. Also, we love using their
                                        dashboard.
                                    </p>
                                    </div>
                                    <div className='user-info'>
                                        <p className='text--black text--bold name'>
                                            Archana Rao
                                        </p>
                                        <p className='text--regular location'>
                                            Mother, Mumbai
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='item'>
                                <div className='slider-image'>
                                    <img
                                        className='img-fluid'
                                        src='http://apslawchd.com/wp-content/uploads/2021/12/06.jpg'
                                        alt='dummy user'
                                    />
                                </div>
                                <div id="insCard4" className='slider-content'>
                                    <div className='institute-logo qwert'>
                                        <img
                                            className='img-fluid asdfg'
                                            src={insititue}
                                            alt='dummy institute'
                                        />
                                    </div>
                                    <div className='quote-logo'>
                                            <img src={quote} 
                                            className="img-quote"
                                            alt="blue-quote" />
                                    </div>
                                    <div>
                                    <p className='text--regular desc'>
                                        GQ has proven to be fantastic at our
                                        school. Fee collection became super
                                        easy. Also, we love using their
                                        dashboard.
                                    </p>
                                    </div>
                                    <div className='user-info'>
                                        <p className='text--black text--bold name'>
                                            Archana Rao
                                        </p>
                                        <p className='text--regular location'>
                                            Mother, Mumbai
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper> */}
                    {/* <div className='container'>
                        <div className='slider-controls'>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => swiperRef.current?.slidePrev()}
                                className='slider-arrow btn-prev'
                            >
                                <img
                                    className='img-fluid'
                                    src={sliderArrow}
                                    alt='slider arrow'
                                />
                                <span className='sr-only'>Prev Btn</span>
                            </div>

                            {!hideRightArrow ? (
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        swiperRef.current?.slideNext()
                                    }
                                    className='slider-arrow btn-next'
                                >
                                    <img
                                        className='img-fluid'
                                        src={sliderArrow}
                                        alt='slider arrow'
                                    />
                                    <span className='sr-only'>Next Btn</span>
                                </div>
                            ) : null}
                        </div>
                    </div> */}
                </section>
                <section className='bg--withPadding faq'>
                    <div className='container'>
                        <h2 className='section__title-1 text--bold text-center mb-3'>
                            We’re here to{' '}
                            <span className='text--blueGradient'>help you</span>
                        </h2>
                        <p className='section__desc text--regular text--black text-center'>
                            These questions will help clear some of your doubts
                            But if they don’t, we are also just a call away!
                        </p>
                        <div className='faq__container'>
                            <Accordion>
                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='0'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp1(!help1)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            What solutions does GQ offer to
                                            Educational Institutions?
                                        </p>
                                        {!help1 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_1'
                                    >
                                        <p className='text--regular faq__answerTxt '>
                                            GQ offers a range of payment
                                            solutions to boost fee collection
                                            for educational institutions.
                                            Essentially, we offer 3 payment
                                            options - EMI, Auto-Debit Payments
                                            and Online Payment Gateway
                                            Solutions. These payment options are
                                            available on our unified
                                            consolidated payments platform
                                            called Rapid. Educational
                                            institutions can activate the 1 or
                                            all of the payment options as per
                                            their requirements.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='1'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp2(!help2)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            Can GQ’s payment solutions integrate
                                            with an educational institution’s
                                            existing ERP platform?
                                        </p>
                                        {!help2 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_2'
                                    >
                                        <p className='text--regular'>
                                            Yes, All of GQ’s payment solutions
                                            can integrate seamlessly with an
                                            educational institution’s existing
                                            ERP platform, just like any other
                                            payment gateway. The integration
                                            will ensure all payments processed
                                            through GQ platform are
                                            automatically updated and reconciled
                                            with the Institute’s ERP platform.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='3'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp3(!help3)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            Our Educational Institution does not
                                            have an ERP? Can we still use GQ’s
                                            payment solutions?
                                        </p>
                                        {!help3 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_3'
                                    >
                                        <p className='text--regular'>
                                            Yes, GQ also provides it’s own fee
                                            management platform called Arc,
                                            which can be used by educational
                                            institutions to store, track, manage
                                            and collect fees for all their
                                            students. Payments collected outside
                                            GQ’s payment platforms can also be
                                            updated in the software. This is a
                                            state of the art offering, which
                                            also enables institutes to send
                                            automated reminders (on whatsapp,
                                            SMS, email) to overdue
                                            students/parents.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='4'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp4(!help4)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            How does GQ’s EMI payment solution
                                            work? What are the benefits to
                                            educational institutions and
                                            parents?
                                        </p>
                                        {!help4 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_4'
                                    >
                                        <p className='text--regular'>
                                            Our EMI platform enables
                                            parents/students to convert their
                                            annual/term fees into easy monthly
                                            installments. Institutes can choose
                                            to offer this EMI at Zero Extra Cost
                                            to their parents and in turn receive
                                            the entire annual fee upfront from
                                            GQ on behalf of their parents. Hence
                                            parents get the convenience of
                                            monthly payments while institutions
                                            can receive their entire year’s fee
                                            upfront in lumpsum - ensuring a true
                                            win-win!
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='5'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp5(!help5)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            How does GQ’s Auto-Debit Platform
                                            work? What are the benefits to
                                            educational institutions and
                                            parents?
                                        </p>
                                        {!help5 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_5'
                                    >
                                        <p className='text--regular'>
                                            In most cases, parents have to pay
                                            fees to their educational
                                            institution on a recurring basis
                                            (every quarter/semester/term). GQ
                                            has created a first of its kind Auto
                                            Debit platform, where institutions
                                            can configure their fee installment
                                            schedule. Once parents register for
                                            Auto-Debit, the fees as per the
                                            institution’s fee installment
                                            schedule will be automatically
                                            deducted from the parent’s
                                            registered bank account on the due
                                            date - saving both the institution
                                            the hassle of follow ups and the
                                            parents the hassle of remembering
                                            the due dates to pay their fee.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='6'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp6(!help6)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            How does GQ’s online payment
                                            platform work?
                                        </p>
                                        {!help6 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_6'
                                    >
                                        <p className='text--regular'>
                                            Our online Payment platform enables
                                            institutions to collect fees from
                                            parents via UPI, Debit Cards, Credit
                                            Cards, Net Banking and other popular
                                            online payment modes.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default Institue;
