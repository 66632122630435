import React, { useState, useEffect } from 'react';
import classes from './UpgradEligibilityCheck.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { RiBankLine, RiHandCoinLine } from 'react-icons/ri';
import {
    MdPermIdentity,
    MdCurrencyRupee,
    MdOutlineStickyNote2,
    MdOutlineRefresh,
} from 'react-icons/md';
import { CustomReactSelect } from '../../Components/atoms';
import {
    UPGRAD_INDIAN_STATES,
    UPGRAD_EXCLUDED_STATES,
    UPGRAD_EMPLOYMENT_TYPE,
    UPGRAD_INELIGIBILITY_REASONS,
} from '../../utils/static_data';

// icons
import CheckmarkIcon from '../../assets/icons/checkmark.svg';
import CrossIcon from '../../assets/icons/cross.svg';
import upgradLogo from '../../assets/images/institute_details_temp/upgrad_logo.svg';
import GrayQuestLogo from '../../assets/images/header/blueLogo.svg';
import { snakeCaseToNormalCaseConverter } from '../../utils/utils';

const SECTIONS_STATIC_DATA = [
    {
        section_title: 'Applicant Details',
        icon: <MdPermIdentity fontSize={'1.125rem'} />,
    },
    {
        section_title: 'Bureau Details',
        icon: <RiBankLine fontSize={'1.125rem'} />,
    },
    {
        section_title: 'Loan Details',
        icon: <RiHandCoinLine fontSize={'1.125rem'} />,
    },
    {
        section_title: 'Bank Details',
        icon: <MdOutlineStickyNote2 fontSize={'1.125rem'} />,
    },
];

const STATIC_DATA = [
    [
        {
            label: "Applicant's Age",
            input_id: 'age',
            amount: false,
            input_type: 'text',
            placeholder: 'Enter your age',
            helperText: 'Age should be between 23 and 60 years',
        },
        {
            label: 'State in which you live',
            input_id: 'state',
            amount: false,
            input_type: 'dropdown',
            placeholder: 'Choose your state',
            helperText: 'Your state must be included in the dropdown',
            options: UPGRAD_INDIAN_STATES,
        },
        {
            label: 'Employment Type',
            input_id: 'employment_type',
            amount: false,
            input_type: 'dropdown',
            placeholder: 'Select your employment type',
            helperText: '',
            options: UPGRAD_EMPLOYMENT_TYPE,
        },
        {
            label: 'Monthly Income',
            input_id: 'monthly_income',
            amount: true,
            input_type: 'text',
            placeholder: 'Enter your monthly income',
            helperText: 'Monthly income greater than 25000 is required',
        },
        {
            label: 'Required Tenure (in months)',
            input_id: 'required_tenure',
            amount: false,
            input_type: 'dropdown',
            placeholder: 'Choose your tenure',
            helperText: '',
            options: [
                { label: 'Tenure less than or equal 36 Months', value: true },
                { label: 'Tenure greater than 36 Months', value: false },
            ],
        },
    ],
    [
        {
            label: 'Bureau Score',
            input_id: 'bureau_score',
            amount: false,
            input_type: 'text',
            placeholder: 'Enter your bureau score',
            helperText: 'Bureau Score of 650 or above is required',
        },
        {
            label: 'Missed any EMI/ any CIBIL issues ?',
            input_id: 'bureau_issue',
            amount: false,
            input_type: 'radio',
            helperText: '',
            options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
            ],
        },
    ],
    [
        {
            label: 'Required loan amount',
            input_id: 'required_loan_amount',
            amount: true,
            input_type: 'text',
            placeholder: 'Enter your required loan amount',
            helperText: '',
        },
        {
            label: 'Existing EMI amount',
            input_id: 'existing_emi_amount',
            amount: true,
            input_type: 'text',
            placeholder: 'Enter your existing EMI amount',
            helperText: '',
        },
        {
            label: 'Upgrad EMI amount',
            input_id: 'upgrad_emi_amount',
            amount: true,
            input_type: 'text',
            placeholder: 'The EMI amount of upgrad',
            helperText: '',
        },
    ],
    [
        {
            label: 'Do you have a bank statement with salary / income proof for the last 3 months ?',
            input_id: 'bank_statement',
            amount: false,
            input_type: 'radio',
            helperText: '',
            options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
            ],
        },
    ],
];

const checkEligibility = (data) => {
    if (!data) return;
    const conditions = {
        age: Number(data?.age) >= 23 && Number(data?.age) <= 60,
        state: !UPGRAD_EXCLUDED_STATES.includes(data?.state?.value),
        employment_type:
            data?.employment_type?.value === 'no_income' ? false : true,
        monthly_income: Number(data?.monthly_income) >= 25000,
        required_tenure: data?.required_tenure?.value,
        bureau_score: Number(data?.bureau_score) >= 650,
        bureau_issue: data?.bureau_issue === 'no',
        required_loan_amount: Number(data?.required_loan_amount) <= 400000,
        existing_emi_amount:
            (Number(data?.existing_emi_amount) +
                Number(data?.upgrad_emi_amount)) /
                Number(data?.monthly_income) <=
            0.85,
        bank_statement: data?.bank_statement === 'yes',
    };

    let failedProperties = [];

    Object.keys(conditions).forEach(function (key, index) {
        if (conditions[key] === false) {
            failedProperties.push({
                key: key,
                reason: UPGRAD_INELIGIBILITY_REASONS[key],
            });
        }
    });

    return failedProperties;
};

// Custom Component to render input field

const InputField = ({
    formik,
    label,
    input_type,
    options,
    input_id,
    helperText,
    handleReactSelectChange,
    handleRadioSelect,
    placeholder,
    amount,
    autoFocus,
}) => {
    const [inputFocus, setInputFocus] = useState(false);

    const handleInputFocus = (e) => {
        e.stopPropagation();
        setInputFocus(true);
    };

    const handleInputBlur = (e) => {
        e.stopPropagation();
        setInputFocus(false);
    };
    return (
        <>
            <div className={` ${classes.InputField} col-md-2 col-lg-3`}>
                <label
                    htmlFor={input_id}
                    className={`${inputFocus ? classes.InputFieldFocus : ''} ${
                        classes.InputFieldLabel
                    }`}
                >
                    {label}
                </label>
                {input_type === 'dropdown' ? (
                    <div>
                        <CustomReactSelect
                            value={formik.values[input_id]}
                            placeholder={placeholder}
                            options={options}
                            name={input_id}
                            onChange={(e) =>
                                handleReactSelectChange(e, input_id)
                            }
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                    </div>
                ) : input_type === 'radio' ? (
                    <div className={classes.RadioInputContainer}>
                        {options?.map((option, index) => (
                            <div
                                className={`${classes.RadioInputOption} ${
                                    formik.values[input_id] === option?.value
                                        ? classes.HighlightSelected
                                        : ''
                                }`}
                                key={index}
                            >
                                <input
                                    type='radio'
                                    name={input_id}
                                    id={`${input_id}_${option?.value}`}
                                    value={option?.value}
                                    checked={
                                        formik.values[input_id] ===
                                        option?.value
                                            ? true
                                            : false
                                    }
                                    onChange={(e) =>
                                        handleRadioSelect(e, input_id)
                                    }
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                />
                                <label
                                    htmlFor={`${input_id}_${option?.value}`}
                                    className={`${classes.RadioInputOptionLabel}`}
                                >
                                    {option?.label}
                                </label>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={`${amount ? classes.RupeeSign : ''}`}>
                        {amount && <MdCurrencyRupee fontSize={'1.125rem'} />}
                        <input
                            autoFocus={autoFocus}
                            type='text'
                            name={input_id}
                            id={input_id}
                            value={formik.values[input_id]}
                            onChange={formik.handleChange}
                            placeholder={placeholder}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                    </div>
                )}
                <div
                    className={`  ${classes.InputFieldHelpertext} ${
                        inputFocus ? classes.InputFieldFocus : ''
                    }`}
                >
                    {helperText}
                </div>

                {formik?.touched[input_id] && formik?.errors[input_id] ? (
                    <span className={classes.FormErrors}>
                        {formik?.errors[input_id]}
                    </span>
                ) : null}
            </div>
        </>
    );
};

// main page

function UpgradEligibilityCheck() {
    const [eligibilityResult, setEligibilityResult] = useState(null);
    const [failureReasons, setFailureReasons] = useState([]);

    const initialValues = {
        age: '',
        state: '',
        monthly_income: '',
        employment_type: '',
        required_tenure: '',
        bureau_score: '',
        bureau_issue: '',
        required_loan_amount: '',
        existing_emi_amount: '',
        upgrad_emi_amount: '',
        bank_statement: '',
    };

    const regex = {
        number: /^[0-9]*$/,
    };

    const onSubmit = async (values) => {
        const failedConditionList = checkEligibility(values);
        if (failedConditionList?.length === 0) {
            setEligibilityResult('eligible');
        } else {
            setFailureReasons(failedConditionList);
            setEligibilityResult('not eligible');
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            age: Yup.string()
                .trim()
                .required('Please enter your age')
                .matches(regex.number, 'Age can only contain numbers'),
            state: Yup.string().required('Please select your state'),
            employment_type: Yup.string().required(
                'Please select your employment type'
            ),
            monthly_income: Yup.string()
                .trim()
                .required('Please enter your monthly income')
                .matches(regex.number, 'Income can only contain numbers'),
            required_tenure: Yup.string().required(
                'Please select your required tenure'
            ),
            bureau_score: Yup.string()
                .trim()
                .required('Please enter your bureau score')
                .matches(regex.number, 'Bureau Score can only contain numbers'),
            bureau_issue: Yup.string().required(
                'Please confirm if you have any EMI / CIBIL related issue'
            ),
            required_loan_amount: Yup.string()
                .trim()
                .required('Please enter your required loan amount')
                .matches(
                    regex.number,
                    'Required loan amount can only contain numbers'
                ),
            existing_emi_amount: Yup.string()
                .trim()
                .required('Please enter your existing EMI amount')
                .matches(
                    regex.number,
                    'Existing EMI amount can only contain numbers'
                ),
            upgrad_emi_amount: Yup.string()
                .trim()
                .required('Please enter the Upgrad EMI amount')
                .matches(
                    regex.number,
                    'New EMI amount can only contain numbers'
                ),
            bank_statement: Yup.string().required(
                'Please select your bank statement status'
            ),
        }),
        enableReinitialize: true,
        onSubmit,
    });

    const handleReactSelectChange = (e, context) => {
        switch (context) {
            case 'state':
                formik.setFieldValue('state', e);
                break;
            case 'required_tenure':
                formik.setFieldValue('required_tenure', e);
                break;
            case 'employment_type':
                formik.setFieldValue('employment_type', e);
                break;
        }
    };
    const handleRadioSelect = (e, context) => {
        switch (context) {
            case 'bureau_issue':
                formik.setFieldValue('bureau_issue', e.target.value);
                break;
            case 'bank_statement':
                formik.setFieldValue('bank_statement', e.target.value);
                break;
        }
    };

    const handleRefreshClick = () => {
        setEligibilityResult(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <nav className={`${classes.Banner}`}>
                <Link target='_blank' to='https://www.upgrad.com/'>
                    <img src={upgradLogo} alt='Upgrad' />
                </Link>
                <Link to='/'>
                    <img src={GrayQuestLogo} alt='GrayQuest' />
                </Link>
            </nav>
            <main className={`${classes.FormWrapper} mx-auto`}>
                <section className={classes.BackgroundBanner}></section>
                {eligibilityResult ? (
                    <div
                        className={`${classes.EligibilityForm} container mx-auto`}
                    >
                        <h3 className={`${classes.pageHeading} col-lg-12`}>
                            Eligibility Result
                        </h3>
                        <section className={classes.ResultInformation}>
                            {eligibilityResult === 'eligible' ? (
                                <>
                                    <img src={CheckmarkIcon} alt='eligible' />
                                    <div>Congratulations! You're Eligible!</div>
                                    <MdOutlineRefresh
                                        className={classes.RefreshForm}
                                        fontSize='1.125rem'
                                        onClick={handleRefreshClick}
                                    />
                                </>
                            ) : (
                                <>
                                    <img src={CrossIcon} alt='not eligible' />
                                    <div>Oops! Sorry you're not eligible.</div>
                                    <MdOutlineRefresh
                                        className={classes.RefreshForm}
                                        fontSize='1.125rem'
                                        onClick={handleRefreshClick}
                                    />

                                    <div className={classes.FailureReasons}>
                                        <h6>Reasons:</h6>
                                        {failureReasons.length > 0 &&
                                            failureReasons?.map((item) => (
                                                <div
                                                    style={{
                                                        marginTop: '0.5rem',
                                                    }}
                                                >
                                                    <span>
                                                        {`${snakeCaseToNormalCaseConverter(
                                                            item?.key
                                                        )}: `}
                                                    </span>
                                                    <span>{item?.reason}</span>
                                                </div>
                                            ))}
                                    </div>
                                </>
                            )}
                        </section>
                    </div>
                ) : (
                    <form
                        className={`${classes.EligibilityForm} container mx-auto`}
                        onSubmit={formik.handleSubmit}
                    >
                        <h3 className={`${classes.pageHeading} col-lg-12`}>
                            Eligibility Check
                        </h3>

                        {SECTIONS_STATIC_DATA?.map((section, index) => (
                            <section
                                className={`${classes.MainContainer} my-3 col-lg-12`}
                                key={index}
                            >
                                <div className={classes.sectionHeader}>
                                    <div>{section?.icon}</div>
                                    <h4 className={classes.sectionTitle}>
                                        {section?.section_title}
                                    </h4>
                                </div>
                                <div
                                    className={`${classes.sectionInputContainer} row`}
                                >
                                    {STATIC_DATA[index].map((ele, index2) => (
                                        <InputField
                                            autoFocus={
                                                index === 0 && index2 === 0
                                            }
                                            input_type={ele?.input_type}
                                            label={ele?.label}
                                            input_id={ele?.input_id}
                                            options={ele?.options}
                                            helperText={ele?.helperText}
                                            placeholder={ele?.placeholder}
                                            amount={ele?.amount}
                                            formik={formik}
                                            key={index2}
                                            handleReactSelectChange={
                                                handleReactSelectChange
                                            }
                                            handleRadioSelect={
                                                handleRadioSelect
                                            }
                                        />
                                    ))}
                                </div>
                            </section>
                        ))}
                        <div className={`${classes.ButtonSection} col-lg-12`}>
                            <button
                                className='col-md-12 col-lg-3'
                                type='submit'
                            >
                                CHECK ELIGIBILITY
                            </button>
                        </div>
                    </form>
                )}
            </main>
        </>
    );
}

export default UpgradEligibilityCheck;
