import { LANDING_BASE_URL } from '../../../constants';
import blueLogo from '../../assets/images/header/gqBlueLogo.png';

export const OrganizationSchema = {
    "@context": "http://www.schema.org",
    "@type": "Organization",
    "name": "Grayquest",
    "url": LANDING_BASE_URL,
    "sameAs": [
        "https://www.facebook.com/grayquest",
        "https://in.linkedin.com/company/grayquest",
        "https://twitter.com/gqfinance",
        "https://www.instagram.com/grayquest"
    ],
    "telephone":"+919324507084",
    "logo": {
        "@type": "ImageObject",
        "caption": "Grayquest",
        "contentUrl": LANDING_BASE_URL+blueLogo
    },
    "description": "GrayQuest provides convenient Monthly Fee Payment Plans for Parents/Students to pay their education fee",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "2nd Floor, Office no. 202, Center Point Bldg, Parel",
        "addressLocality": "Mumbai",
        "addressRegion": "Maharashtra",
        "postalCode": "400012",
        "addressCountry": "India"
    },
    "email": "support@grayquest.com",                     
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+919324507084",
        "contactType": "sales",
        "areaServed" : ["IN"],
    }
}