import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import sliderArrow from '../../assets/images/newslider/slider-arrow.svg';
import AbhinavSinha from '../../assets/images/about/AbhinavSinha.png';
import AnujTejpal from '../../assets/images/about/AnujTejpal.png';
import credcrop from '../../assets/images/about/credcrop.png';
import KunalShah from '../../assets/images/about/KunalShah.png';
import shaadicrop from '../../assets/images/about/shaadicrop.png';
import oyo from '../../assets/images/about/oyo.png';

import MitenSampat from '../../assets/images/about/MitenSampat.png';
import PravinJadhav from '../../assets/images/about/PravinJadhav.png';
import AkritVaishImage from '../../assets/images/about/AkritJadhav.jpeg';
import AnupamMittal from '../../assets/images/about/AnupamMittal.png';
import SujeetKumar from '../../assets/images/about/SujeetKumar.png';
import RevantBhate from '../../assets/images/about/RevantBhate.png';
import DhyaneshShah from '../../assets/images/about/DhyaneshShah.png';
import DhanLogoImage from '../../assets/images/about/dhan.png';
import JioHaptikLogo from '../../assets/images/about/jio_haptik_logo.png';
import udaan from '../../assets/images/about/udaan.png';
import mosaic from '../../assets/images/about/mosaic.png';

import './NewSlider.css';

const NewSlider = () => {
    const swiperRef = useRef();
    const [hideRightArrow, setHideRightArrow] = useState(false);
    const sliderSettings = {
        0: {
            slidesPerView: 1.2,
            spaceBetween: 30,
        },
        425: {
            slidesPerView: 1.3,
            spaceBetween: 30,
        },
        680: {
            slidesPerView: 2.5,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
    };

    return (
        <div className='aboutUs-slider'>
            <Swiper
                id=''
                className='testimonial__slider'
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                breakpoints={sliderSettings}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                spaceBetween={50}
                slidesPerView={2}
                onSlideChange={(e) =>
                    e.activeIndex === 8
                        ? setHideRightArrow(true)
                        : setHideRightArrow(false)
                }
            >
                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img src={KunalShah} alt='KunalShah'></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Kunal Shah</h4>
                                        <h5>Founder - CRED</h5>
                                    </div>
                                    <div className='creadSliderImg'>
                                        <img src={credcrop} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img src={MitenSampat} alt='Miten Sampat'></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Miten Sampat</h4>
                                        <h5>President at Cred</h5>
                                    </div>
                                    <div className='creadSliderImg'>
                                        <img src={credcrop} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img
                                    src={AkritVaishImage}
                                    alt=''
                                ></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Akrit Vaish</h4>
                                        <h5>Co-founder & CEO of Haptik</h5>
                                    </div>
                                    <div className='paytmSliderImg creadSliderImg'>
                                        <img src={JioHaptikLogo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img
                                    src={AnupamMittal}
                                    alt='AnupamMittal'
                                ></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Anupam Mittal</h4>
                                        <h5>Founder - Shaadi.com</h5>
                                    </div>
                                    <div className='shaadicropSliderImg creadSliderImg'>
                                        <img src={shaadicrop} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img src={AnujTejpal} alt='Anuj Tejpal'></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Anuj Tejpal</h4>
                                        <h5>CBDO at OYO</h5>
                                    </div>
                                    <div className='oyoSliderImg creadSliderImg'>
                                        <img src={oyo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img
                                    src={AbhinavSinha}
                                    alt='Abhinav Sinha'
                                ></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Abhinav Sinha</h4>
                                        <h5>COO & CPO at OYO</h5>
                                    </div>
                                    <div className='oyoSliderImg creadSliderImg'>
                                        <img src={oyo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img src={SujeetKumar} alt='SujeetKumar'></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Sujeet Kumar</h4>
                                        <h5>Co-Founder of Udaan</h5>
                                    </div>
                                    <div className='udaanSliderImg creadSliderImg'>
                                        <img src={udaan} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img src={RevantBhate} alt='Revant Bhate'></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Revant Bhate</h4>
                                        <h5>Co-Founder & CEO at Mosaic</h5>
                                    </div>
                                    <div className='mosaicSliderImg creadSliderImg'>
                                        <img src={mosaic} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='alignInvestor'>
                        <div className='slide-div '>
                            <div className='investorDetailsContainer'>
                                <img
                                    src={DhyaneshShah}
                                    alt='Dhyanesh Shah'
                                ></img>
                                <div className='investorsDetails'>
                                    <div>
                                        <h4>Dhyanesh Shah</h4>
                                        <h5>Co-Founder of Mosaic</h5>
                                    </div>
                                    <div className='mosaicSliderImg creadSliderImg'>
                                        <img src={mosaic} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

            <div id="sliderArrow" className='container'>
                <div className='slider-controls'>
                    <a
                        style={{ cursor: 'pointer' }}
                        className='slider-arrow btn-prev review-swiper-button-next'
                    >
                        <img
                            onClick={() => swiperRef.current?.slidePrev()}
                            className='img-fluid'
                            src={sliderArrow}
                            alt='slider arrow'
                        />
                        <span className='sr-only'>Prev Btn</span>
                    </a>

                    {!hideRightArrow ? (
                        <a
                            style={{ cursor: 'pointer' }}
                            onClick={() => swiperRef.current?.slideNext()}
                            className='slider-arrow btn-next'
                        >
                            <img
                                className='img-fluid'
                                src={sliderArrow}
                                alt='slider arrow'
                            />
                            <span className='sr-only'>Next Btn</span>
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default NewSlider;
