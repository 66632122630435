import React from 'react';
import { FaHeadset, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import sendGA4Event from '../../../googleAnalytics/googleAnalytics';
import { InstituteDetailsParagraph } from '../../atoms';
import classes from './InstituteDetailsSupportBlock.module.scss';

const InstituteDetailsSupportBlock = ({ pageData }) => {
    if (!pageData?.support_block?.visible) return null;

    let ctaButtonPostion = "";
    let ctaTarget = "_blank";
    if (pageData?.support_block?.cta_config?.position === "left") {
        ctaButtonPostion = classes.Left;
    } else if (pageData?.support_block?.cta_config?.position === "right") {
        ctaButtonPostion = classes.Right;
    }
    if (pageData?.support_block?.cta_config?.new_window === false) {
        ctaTarget = "_self";
    }

    const handleSupportClick = (evnt, type) => {
        const classList = Object.values(evnt.target.classList);

        if (classList.includes('mobile')) {
            // GA Event
            sendGA4Event({
                type: 'event',
                event_category: 'Institute Landing Page',
                event_action: 'Support Phone Number Clicked',
                event_label: 'Support Phone Number Clicked',
            });
        }
        if (classList.includes('email')) {
            // GA Event
            sendGA4Event({
                type: 'event',
                event_category: 'Institute Landing Page',
                event_action: 'Suport Email ID clicked',
                event_label: 'Suport Email ID clicked',
            });
        }
    }

    return (
        <div className={classes.InstituteDetailsSupportBlock}>
            {(pageData?.support_block?.title) ?
                (<h2 className={classes.Title}> <FaHeadset /> {pageData.support_block.title}</h2>)
                : null
            }

            {(pageData?.support_block?.subtitle) ?
                (<h3 className={classes.SubTitle}>{pageData.support_block.subtitle}</h3>)
                : null
            }

            {(pageData?.support_block?.images?.length > 0) ?
                (pageData.support_block.images.map((img, index) => (<img src={img} key={`support-block-${index}`} className={classes.Image} alt="support" />)))
                : null
            }

            {(pageData?.support_block?.description?.length > 0) ?
                (<div className={classes.Description}>
                    {pageData.support_block.description.map((paragraph, index) => (
                        <InstituteDetailsParagraph key={`support-block-para-${index}`} paragraph={paragraph} />
                    ))}
                </div>) : null
            }

            {(pageData?.support_block?.cta_config?.url) ?
                (<div className={`${classes.BlockAction} ${ctaButtonPostion}`}>
                    <a href={pageData.support_block.cta_config.url}
                        rel="noopener noreferrer nofollow"
                        target={ctaTarget}
                        className={classes.CATLink}
                    >
                        {pageData?.support_block?.cta_config?.label || "View Details"}
                    </a>
                </div>) : null
            }

            {(pageData?.support_block?.phones?.length > 0) ?
                (<div className={classes.Contact__Phone} onClick={(evnt) => handleSupportClick(evnt, 'mobile')}>
                    <FaPhoneAlt />
                    {pageData.support_block.phones.map((phone, index) => (
                        <a href={`tel:${phone}`} key={phone} className={`${classes.PhoneLink} mobile`}>{phone}</a>
                    ))}
                </div>) : null
            }

            {(pageData?.support_block?.emails?.length > 0) ?
                (<div className={classes.Contact__Email} onClick={(evnt) => handleSupportClick(evnt, 'email')}>
                    <FaEnvelope />
                    {pageData.support_block.emails.map(email => (
                        <a href={`mailto:${email}`} key={email} className={`${classes.EmailLink} email`}>{email}</a>
                    ))}
                </div>) : null
            }
        </div>
    )
}

export default InstituteDetailsSupportBlock;
