// dependencies
import React from 'react';
import { Swiper } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

// css
import classes from './CustomSlider.module.scss';

const CustomSlider = (props) => {
    const { data, children } = props;

    if (!data || data?.length === 0) {
        console.error('no data for slider to render');
        return null;
    }

    return (
        <div className={classes.CustomSliderContainer}>
            <Swiper
                {...props}
                modules={[Navigation, Pagination]}
                className={`${classes.Slider} ${
                    props?.className ? props?.className : ''
                }`}
            >
                {children}
            </Swiper>
        </div>
    );
};

CustomSlider.propTypes = {
    data: PropTypes.array,
    slidesType: PropTypes.string,
    children: PropTypes.array,
};

export default CustomSlider;
