import React, { useState, useEffect } from "react";
import contactHero from "../../assets/images/contact/contact-hero.svg";
import titlestar from "../../assets/images/contact/title-star.svg";
import emailIcon from "../../assets/images/contact/email-icon.svg";
import * as Yup from "yup";
import callIcon from "../../assets/images/contact/call-icon.svg";
import Header1 from "../Common/Header1/Header1";
import { useFormik } from "formik";
import Footer1 from "../Common/Footer1/Footer1";
import "react-phone-number-input/style.css";
import india from "../../assets/images/contact/india.png";
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import "./Contact.css"
import { toast } from "react-toastify";
import { gqLandingApiClient } from "../../api-landing-client/axios";
import CustomHelmet from "../CustomHelmet/CustomHelmet";
import MessageConfirmation from "../MessageConfirmation/MessageConfirmation";

function Contact() {
    const [isLoading, setIsLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false)

    const initialValues = {
        inquiry_type: "",
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        message: ""
    };

    const regex = {
        mobileNumberValidation: /^[6-9]+[0-9]{9}$/,
    };

    const postPayload = async (values) => {
        await gqLandingApiClient({
            method: "POST",
            url: `/v1/contact-us/landing-notification`,
            data: values,
        }).then((res) => {
            setMessageSent(true)
            formik.resetForm();
        }).catch((err) => {
            console.error("fetchPaymentPagesList error", err);
            toast.error(err?.response?.data?.message || "Oops! Something went wrong");
        });
    }

    const onSubmit = async (values) => {
        setIsLoading(true);
        await postPayload(values);
        setIsLoading(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            inquiry_type: Yup.string().required("Please select inquiry type"),
            first_name: Yup.string().required("Please enter first name"),
            last_name: Yup.string().required("Please enter last name"),
            message: Yup.string().required("Please enter message"),
            email: Yup.string().trim()
                .email('Please enter a valid email')
                .required("Please enter your Email ID"),
            mobile: Yup.string()
                .length(10, 'Mobile number must contain 10 digits')
                .matches(regex.mobileNumberValidation, 'Please enter a valid mobile number')
                .required("Please enter Phone Number"),
        }),
        enableReinitialize: true,
        onSubmit,
    });

    const handleReactSelectChange = (e) => {
        formik.setFieldValue("inquiry_type", e.selectedKey[0] || "");
    };
    
    const options = [
        {
            "labelKey": "parent",
            "value": "I'm a Parent"
        },
        {
            "labelKey": "institute",
            "value": "I'm an Institute"
        }
    ]
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <CustomHelmet page="contact" />
            <Header1 theme="contact" />

            {messageSent ? <MessageConfirmation /> :
                <main className="contact">
                <section className="contact__main">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h1 className="text--black text--bold section__title">
                                    Let’s connect,{" "}
                                    <span className="text--blueGradient">Shall we?</span>
                                </h1>
                                <p className="text--black text--regular section__desc">
                                    We are always here for you. Write to us regarding your
                                    concerns and well will get back.
                                </p>
                                <div className="contact__form-container">
                                    <form className="form" onSubmit={formik.handleSubmit}>
                                        <div className="form-row">
                                            <div className="col">
                                                <label className="form__label" htmlFor="inquiry_type">
                                                    What are you here for?
                                                </label>
                                                <BootstrapSelect id="inquiry_type" value={formik.values.inquiry_type} onChange={handleReactSelectChange} options={options} />

                                            </div>
                                        </div>
                                        {formik.touched.inquiry_type &&
                                            formik.errors.inquiry_type ? (
                                            <span className='form-error'>
                                                {formik.errors.inquiry_type}
                                            </span>
                                        ) : null}


                                        <div className="form-row">
                                            <div className="col-md-6 col-12 firstName">
                                                <label className="form__label" htmlFor="first_name">
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="first_name"
                                                    placeholder="First name"
                                                    value={formik.values.first_name}
                                                    onChange={formik.handleChange}

                                                />
                                                {formik.touched.first_name &&
                                                    formik.errors.first_name ? (
                                                    <span className='form-error'>
                                                        {formik.errors.first_name}
                                                    </span>
                                                ) : null}
                                            </div>


                                            <div className="col-md-6 col-12">
                                                <label className="form__label" htmlFor="last_name">
                                                    Last name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="last_name"
                                                    placeholder="Last name"
                                                    value={formik.values.last_name}
                                                    onChange={formik.handleChange}

                                                />
                                                {formik.touched.last_name &&
                                                    formik.errors.last_name ? (
                                                    <span className='form-error'>
                                                        {formik.errors.last_name}
                                                    </span>
                                                ) : null}
                                            </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label className="form__label" htmlFor="phone">
                                                    Phone
                                                </label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        className="phoneFlag"
                                                    >
                                                        <img
                                                            src={india}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                        <p>+91</p>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        id="mobile"
                                                        placeholder="Enter phone number"
                                                        value={formik.values.mobile}
                                                        onChange={formik.handleChange}

                                                    />
                                                </div>
                                                {formik.touched.mobile &&
                                                    formik.errors.mobile ? (
                                                    <span className='form-error'>
                                                        {formik.errors.mobile}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label className="form__label" htmlFor="email">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}

                                                />
                                                {formik.touched.email &&
                                                    formik.errors.email ? (
                                                    <span className='form-error'>
                                                        {formik.errors.email}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label className="form__label" htmlFor="message">
                                                    How can we help you?
                                                </label>
                                                <textarea id="message" rows="6" value={formik.values.message} onChange={formik.handleChange}></textarea>
                                                {formik.touched.message &&
                                                    formik.errors.message ? (
                                                    <span className='form-error'>
                                                        {formik.errors.message}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* <div className="form-row" onClick={onSubmit}> */}

                                        {/* Submit */}
                                        <button 
                                            className="custom__btn custom__btn--blue hover-border" 
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Loading..." : "Submit"}
                                        </button>

                                        {/* </div> */}
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img
                                    className="img-fluid contact__img"
                                    src={contactHero}
                                    alt="contact us"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact__footer bg--withPadding">
                    <div className="container">
                        <p className="text--black text--bold contact__footer-title">
                            Quick connect
                        </p>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="contact__footer-container">
                                    <div style={{
                                        margin: "0"
                                    }} className="item quickConnect">
                                        <p className="star-title text--bold">
                                            <img
                                                className="img-fluid"
                                                src={titlestar}
                                                alt="star icon"
                                            />{" "}
                                            For Parents
                                        </p>
                                        <p className="contact__footer-link">
                                            <img
                                                className="img-fluid"
                                                src={emailIcon}
                                                alt="email icon"
                                            />
                                            <a href="mailto:support@grayquest.com">
                                                support@grayquest.com
                                            </a>
                                        </p>
                                        <p className="contact__footer-link">
                                            <img
                                                className="img-fluid"
                                                src={callIcon}
                                                alt="call icon"
                                            />
                                            <a href="tel:919324507084">+91 9324507084</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact__footer-container">
                                    <div style={{
                                        margin: "0"
                                    }} className="item quickConnect">
                                        <p className="star-title text--bold">
                                            <img
                                                className="img-fluid"
                                                src={titlestar}
                                                alt="star icon"
                                            />{" "}
                                            For educational institutes
                                        </p>
                                        <p className="contact__footer-link">
                                            <img
                                                className="img-fluid"
                                                src={emailIcon}
                                                alt="email icon"
                                            />
                                            <a href="mailto:director@grayquest.com">
                                                director@grayquest.com
                                            </a>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact__footer-container border-0">
                                    <div style={{
                                        margin: "0"
                                    }} className="item quickConnect">
                                        <p className="star-title text--bold">
                                            <img
                                                className="img-fluid"
                                                src={titlestar}
                                                alt="star icon"
                                            />{" "}
                                            For careers
                                        </p>
                                        <p className="contact__footer-link">
                                            <img
                                                className="img-fluid"
                                                src={emailIcon}
                                                alt="email icon"
                                            />
                                            <a href="mailto:director@grayquest.com">
                                                director@grayquest.com
                                            </a>
                                        </p>
                                        <p id="marginUp" className="star-title text--bold">
                                            <img
                                                className="img-fluid"
                                                src={titlestar}
                                                alt="star icon"
                                            />{" "}
                                            Grievance Office
                                        </p>
                                        <p className="contact__footer-link">
                                            <img
                                                className="img-fluid"
                                                src={emailIcon}
                                                alt="email icon"
                                            />
                                            <a href="mailto:nodalofficer@grayquest.com">
                                                nodalofficer@grayquest.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>}


            <Footer1 />
        </>
    );
}

export default Contact;
