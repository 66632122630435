import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGqCareerJobsDataGet } from '../../../api-cms-client';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const getGqCareerJobsFetchAll = createAsyncThunk(
    'getGqCareerJobsFetchAll/Get',
    async (body, { rejectWithValue }) => {
        try {
            const response = await fetchGqCareerJobsDataGet(body);
            const data = response?.data;
            return data;
        } catch (err) {
            const errObj = {
                type: 'GET_GQ_CAREER_JOBS_FETCH_ALL_FAILURE',
                error: err,
                message: 'Oops! something went wrong',
            };
            return rejectWithValue(err?.response?.data || errObj);
        }
    }
);

const getGqCareerJobsFetchAllSlice = createSlice({
    name: 'getGqCareerJobsFetchAll',
    initialState,
    extraReducers: {
        [getGqCareerJobsFetchAll.pending]: (state) => {
            state.loading = true;
        },
        [getGqCareerJobsFetchAll.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = {
                data: payload?.data,
                pagination: payload?.meta?.pagination,
            };
            state.error = null;
        },
        [getGqCareerJobsFetchAll.rejected]: (state, { payload }) => {
            state.loading = false;
            state.data = null;
            state.error = payload;
        },
    },
});

export const getGqCareerJobsFetchAllSelector = (state) =>
    state.getGqCareerJobsFetchAll;
export const getGqCareerJobsFetchAllReducer =
    getGqCareerJobsFetchAllSlice.reducer;
