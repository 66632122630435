// dependencies
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdSearch, MdClose } from 'react-icons/md';
import { Formik, Field, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

// components
import {
    CustomButton,
    CustomReactMultiSelect,
} from '../../../Components/atoms';
import { Header1, Footer1 } from '../../../Components/Common';
import CustomHelmet from '../../../Components/CustomHelmet/CustomHelmet';

import {
    getGqJobDepartmentFetch,
    getGqJobDepartmentFetchSelector,
} from '../../../reduxtoolkit/slices/gq-career';

// styles
import classes from './AllJobs.module.scss';
import { JobListingsGrid } from '../../../Components/molecules';

const initialValues = {
    search: '',
};

const AllJobs = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');

    const dispatch = useDispatch();

    const {
        data: getGqJobDepartmentFetchData,
        loading: getGqJobDepartmentFetchLoading,
    } = useSelector(getGqJobDepartmentFetchSelector);

    const handleChange = (e, formik) => {
        formik.setFieldValue('search', e.target.value);
    };

    const handleOnChange = (selectedOption) => {
        if (selectedOption === null) {
            setSelectedDepartment('');
        } else {
            setSelectedDepartment(selectedOption.value);
        }
        return selectedDepartment;
    };

    const onSubmit = (values) => {
        setSearchQuery(values.search);
        return searchQuery;
    };

    const handleClear = (formik) => {
        formik.setFieldValue('search', '');
        setSearchQuery('');
    };

    const fetchJobDepartment = async (query) => {
        try {
            const payload = {
                label: query,
                value: query,
            };
            const getGqJobDepartmentRes = await dispatch(
                getGqJobDepartmentFetch(payload)
            ).unwrap();
            return getGqJobDepartmentRes?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getInitialJobDepartment = async (query) => {
        try {
            const getGqJobDepartmentRes = await fetchJobDepartment(query);
            return getGqJobDepartmentRes;
        } catch (error) {
            console.error(error);
        }
    };

    const jobDepartmentArray = getGqJobDepartmentFetchData?.map(
        (item) => item?.attributes
    );

    useEffect(() => {
        getInitialJobDepartment();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CustomHelmet page='careers' />
            <Header1 theme={'careers'} />
            <main className={classes.PageContainer}>
                <section>
                    <section>
                        <div
                            className={`${classes.JobSectionHeading} container`}
                        >
                            <h1
                                className={classes.Heading}
                                style={{ textAlign: 'center' }}
                            >
                                All{' '}
                                <span className={classes.GradientText}>
                                    Open Positions
                                </span>
                            </h1>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                            >
                                {(formik) => {
                                    const { values } = formik;
                                    return (
                                        <Form
                                            className={`${classes.JobSectionsWidgets} form-row`}
                                        >
                                            <div className={classes.SearchBar}>
                                                <Field
                                                    type='text'
                                                    name='search'
                                                    id='search'
                                                    placeholder='Search Jobs'
                                                    onChange={(e) =>
                                                        handleChange(e, formik)
                                                    }
                                                    className={
                                                        classes.SearchInputField
                                                    }
                                                />
                                                <CustomButton
                                                    type='submit'
                                                    icon={
                                                        <MdSearch fontSize='1.125rem' />
                                                    }
                                                    className={
                                                        classes.SearchButton
                                                    }
                                                />
                                                {values?.search && (
                                                    <MdClose
                                                        fontSize='1.125rem'
                                                        className={
                                                            classes.ClearSearchBtn
                                                        }
                                                        onClick={() =>
                                                            handleClear(formik)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <CustomReactMultiSelect
                                                className={classes.Select}
                                                isSearchable={false}
                                                hideSelectedOptions={false}
                                                options={jobDepartmentArray}
                                                isLoading={
                                                    getGqJobDepartmentFetchLoading
                                                }
                                                onChange={handleOnChange}
                                                isClearable
                                            />
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </section>
                    <div className={classes.JobCardDetails}>
                        <JobListingsGrid
                            searchQuery={searchQuery}
                            selectedDepartment={selectedDepartment}
                            enableInfiniteScroll={false}
                        />
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
};

export default AllJobs;
