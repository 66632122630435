import {
    getGqCareerJobsFetchAllReducer,
    getGqCareerJobDataFetchReducer,
    getGqJobDepartmentFetchReducer
} from '../../slices/gq-career';

export const gqCareerJobsFetchReducers = {
    getGqCareerJobsFetchAll: getGqCareerJobsFetchAllReducer,
    getGqCareerJobDataFetch: getGqCareerJobDataFetchReducer,
    getGqJobDepartmentFetch: getGqJobDepartmentFetchReducer,
};
