// dependancies
import { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import classes from './JobListingsGrid.module.scss';

// api
import { getGqCareerJobsFetchAll } from '../../../reduxtoolkit/slices/gq-career';

// components
import { SpinnerLoader } from '../../atoms';
import { JobCard } from '../../atoms';

// constants
const ACTIONS = {
    OPEN_JOB: 'open_job',
    ALL_JOBS: 'all_jobs',
};

const SCROLLEVENT = {
    NEXT: 'next',
};

const JobListingsGrid = (props) => {
    const {
        searchQuery,
        selectedDepartment,
        enableInfiniteScroll,
        jobsPageSize,
    } = props;

    const dispatch = useDispatch();

    const [activePageNumber, setActivePageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [jobsList, setJobsList] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [hasMore, setHasMore] = useState(false);
    const [showJobCount, setShowJobCount] = useState(false);

    const navigate = useNavigate();

    const handleJobClick = (type, query) => {
        switch (type) {
            case ACTIONS.OPEN_JOB:
                navigate(`/careers/${query?.attributes?.slug}`);
                break;
            case ACTIONS.ALL_JOBS:
                navigate(`/careers/all`);
                break;
            default:
                break;
        }
    };

    const handleScroll = async (scrollEvent) => {
        switch (scrollEvent) {
            case SCROLLEVENT.NEXT:
                const nextPageNumber = activePageNumber + 1;
                const fetchJobsDataRes = await fetchJobsData(
                    activePageNumber + 1
                );
                if (fetchJobsDataRes) {
                    setJobsList([...jobsList, ...fetchJobsDataRes?.data]);
                    setPaginationData(fetchJobsDataRes?.meta?.pagination);
                    setActivePageNumber(nextPageNumber);
                    setHasMore(
                        nextPageNumber <
                        fetchJobsDataRes?.meta?.pagination?.pageCount
                    );
                } else {
                    setHasMore(false);
                }
                break;
            default:
                break;
        }
    };

    const handleSubmit = useCallback(
        async (values) => {
            try {
                setIsLoading(true);
                const { searchQuery, selectedDepartment } = values;
                const fetchJobsDataRes = await fetchJobsData(
                    1,
                    searchQuery,
                    selectedDepartment
                );
                if (fetchJobsDataRes) {
                    setJobsList(fetchJobsDataRes?.data);
                    setPaginationData(fetchJobsDataRes?.meta?.pagination);
                    setHasMore(
                        activePageNumber <
                        fetchJobsDataRes?.meta?.pagination?.pageCount
                    );
                }
            } catch (error) {
                console.error(error?.message);
            } finally {
                setIsLoading(false);
                setShowJobCount(true);
            }
        },
        [activePageNumber]
    );

    const fetchJobsData = async (page, query, selectedDepartment) => {
        try {
            const payload = {
                page: page,
                sort: 'publishedAt:desc',
                job_role: query,
                department: selectedDepartment,
            };
            const getGqJobsFetchAllRes = await dispatch(
                getGqCareerJobsFetchAll(payload)
            ).unwrap();
            return getGqJobsFetchAllRes;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getInitialData = async (page, query = '') => {
        try {
            setIsLoading(true);
            const fetchJobsDataRes = await fetchJobsData(page, query);
            if (fetchJobsDataRes) {
                setJobsList(fetchJobsDataRes?.data);
                setPaginationData(fetchJobsDataRes?.meta?.pagination);
                setHasMore(
                    activePageNumber <
                    fetchJobsDataRes?.meta?.pagination?.pageCount
                );
            }
            setShowJobCount(false);
            return;
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (searchQuery || selectedDepartment) {
            handleSubmit({ searchQuery, selectedDepartment });
        } else {
            setActivePageNumber(1);
            getInitialData(1);
            setShowJobCount(false);
        }
    }, [searchQuery, selectedDepartment]);

    useEffect(() => {
        getInitialData(1);
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <div className={classes.OpenPositionsContainer}>
                {isLoading && (
                    <div className={classes.Loader}>
                        <SpinnerLoader
                            size='large'
                            trackColor='#3e54ce2c'
                            spinnerColor='#3e54ce'
                        />
                    </div>
                )}

                {jobsList?.length > 0 ? (
                    <>
                        <InfiniteScroll
                            dataLength={jobsList?.length}
                            next={() => handleScroll(SCROLLEVENT.NEXT)}
                            hasMore={hasMore}
                            scrollThreshold={0.5}
                            className={classes.OpenPositionsDiv}
                            loader={
                                isLoading && (
                                    <div className={classes.Loader}>
                                        <SpinnerLoader
                                            size='large'
                                            trackColor='#3e54ce2c'
                                            spinnerColor='#3e54ce'
                                        />
                                    </div>
                                )
                            }
                        >
                            {paginationData &&
                                showJobCount &&
                                (searchQuery || selectedDepartment) && (
                                    <div className={classes.JobsResultCount}>
                                        {`${paginationData?.total} results found`}
                                    </div>
                                )}

                            {enableInfiniteScroll
                                ? jobsList
                                    .slice(0, jobsPageSize)
                                    .map((job) => (
                                        <JobCard
                                            job={job}
                                            onClick={() =>
                                                handleJobClick(
                                                    ACTIONS.OPEN_JOB,
                                                    job
                                                )
                                            }
                                        />
                                    ))
                                : !isLoading &&
                                jobsList.map((job) => (
                                    <JobCard
                                            job={job}
                                            onClick={() =>
                                                handleJobClick(
                                                    ACTIONS.OPEN_JOB,
                                                    job
                                                )
                                            }
                                        />
                                ))}

                            {/* {jobsList?.length % 2 !== 0 && (
                                <div className={classes.EmptyDiv}></div>
                            )} */}
                        </InfiniteScroll>
                    </>
                ) : (
                    !isLoading && (
                        <div className={classes.NoJobsAvailable}>
                            <h5>No Jobs Found!</h5>
                        </div>
                    )
                )}
            </div>
            <div className={classes.OpenPositionsButtonContainer}>
                {enableInfiniteScroll && (
                    <div className={classes.OpenPositionsButtonContainer}>
                        <div
                            className={classes.OpenPositionsButton}
                            onClick={() => {
                                handleJobClick(ACTIONS.ALL_JOBS);
                            }}
                        >
                            View all jobs
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

JobListingsGrid.PropTypes = {
    searchQuery: PropTypes.string,
    selectedDepartment: PropTypes.string,
    enableInfiniteScroll: PropTypes.bool,
    jobsPageSize: PropTypes.number,
};

export default JobListingsGrid;
