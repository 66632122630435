import { React, useState } from 'react';
import Header1 from '../../Components/Common/Header1/Header1';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import classes from './TermsAndConditions.module.scss';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';
import { MdOutlineOpenInNew } from 'react-icons/md';

function TermsAndConditions() {
    const [showText, setShowText] = useState(false);

    return (
        <>
            <CustomHelmet page='termsandconditions' />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.tacHeading}>
                            <span>Terms &amp; Conditions</span>
                        </h1>
                    </div>
                </section>
                <section>
                    <div className={classes.body_section}>
                        <div>
                            <h2 className={classes.section_title}>
                                Introduction
                            </h2>
                            <p>
                                GrayQuest Education Finance Pvt Ltd("GrayQuest")
                                is a technology and operating partner to its
                                financing partners. As a service provider,
                                GrayQuest is fully responsible for
                                communicating, transacting, and processing on
                                behalf of its financing partners. At no point,
                                GrayQuest is represented as a lending company
                                that lends money of its own accord. GrayQuest
                                provides services to various banking &amp;
                                financial services companies and Non-banking
                                Financial Companies (NBFC) (collectively
                                referred to as 'Lending Partners') as a
                                'Servicer'. GrayQuest has developed technology
                                for evaluating creditworthiness using customer
                                data collected through loan applications and
                                other credit verification documents of the
                                customer. Please refer to your Loan Agreement
                                for details on your contract with the Financing
                                Partner.
                            </p>
                            <p>
                                Grayquest has partnered with the following
                                lenders including Banks and NBFC
                            </p>

                            <br />
                            <h2 className={classes.section_title}>Services</h2>
                            <ul className={classes.innerListDecimal}>
                                <li>
                                    You hereby confirm to register with us to
                                    avail of a loan/credit from one or more
                                    lending partners using the services provided
                                    by GrayQuest.
                                </li>
                                <li>
                                    You hereby understand and acknowledge that
                                    the information requested from you is used
                                    for the various purposes as set out in our
                                    Privacy Policy{' '}
                                    <a href='https://grayquest.com/privacy-policy'>
                                        ("https://grayquest.com/privacy-policy")
                                    </a>
                                    , including for the purposes of us / our
                                    partners to comply with various laws,
                                    regulations, and guidelines (including Know
                                    Your Customer (KYC) norms / Anti-Money
                                    Laundering (AML) standards / Combating of
                                    Financing of Terrorism (CFT) / Obligation of
                                    banks under the Prevention of Money
                                    Laundering Act, 2002) promulgated by the
                                    Government of India from time to time. You
                                    further acknowledge that if you do not
                                    provide the requested information to
                                    GrayQuest, GrayQuest or its partners will be
                                    unable to provide or continue its / their
                                    services to you.
                                </li>
                                <li>
                                    You confirm that all information is given to
                                    GrayQuest (whether in GrayQuest’s online
                                    form or otherwise) is true, correct, and not
                                    misleading. You agree to provide any
                                    additional documents to GrayQuest or any of
                                    its partners as may be required from time to
                                    time.
                                </li>
                                {/* <li>
                                    You hereby understand that every time you
                                    wish to make a payment using GrayQuest EMI,
                                    you will be provided with online loan terms
                                    and conditions. Upon clicking ‘I Accept’ you
                                    and your successors/ heirs/ executors will
                                    be bound by the terms of the loan in
                                    relation to the credit facility. You
                                    understand and agree that GrayQuest may
                                    charge you a non-refundable administrative
                                    fee or a technology usage fee for
                                    facilitating the loan to be availed by you
                                    and you agree to pay the same. You must
                                    understand that this fee may not be
                                    refundable to you even if the loan is
                                    canceled for any reason whatsoever.
                                </li> */}
                                <li>
                                    You hereby understand that when you apply
                                    for a loan, you authorize GrayQuest and its
                                    lending partners (Banks and NBFCs) to fetch
                                    your credit information records from credit
                                    bureaus, and any information available to
                                    GrayQuest through any of its sources,
                                    including those at credit information
                                    companies (CICs); those at fraud prevention
                                    agencies (FPAs) and its own records, for
                                    assessing your creditworthiness, verifying
                                    identity to prevent and detect crime and
                                    money laundering
                                </li>
                                <li>
                                    You also agree that if you avail of any
                                    loans through GrayQuest, you will not (i)
                                    use the proceeds thereof for any purpose
                                    other than as declared to GrayQuest or our
                                    lending partners, (ii) accept or attempt to
                                    receive the credit facility in the form of
                                    cash or any other means, (iii) use the
                                    Services in violation of applicable law or
                                    (iv) circumvent or attempt to circumvent the
                                    provisions of any terms that apply to you or
                                    act in a manner that is fraudulent,
                                    malicious or otherwise prejudicial to
                                    GrayQuest’s reputation direct or indirect
                                    interests in relation to the Services
                                </li>
                                <li>
                                    Your lender also has the right, and
                                    obligation, to report your details to CICs
                                    in the event that you have delayed repaying
                                    your loan installment.
                                </li>
                                <li>
                                    You acknowledge that GrayQuest does not lend
                                    any money directly to you, but arranges the
                                    loan for you from one or more lending
                                    partners.
                                </li>
                                <li>
                                    The GrayQuest Credit Limit provided to you
                                    is our assessment of your eligibility based
                                    on various factors including the policies of
                                    our lending partners, the market influences,
                                    and our previous experiences.
                                </li>
                                <li>
                                    You acknowledge that interest rates may vary
                                    across different lenders, borrowers, and
                                    loans.
                                </li>
                            </ul>
                            <br />
                            <h2 className={classes.section_title}>
                                Eligibility
                            </h2>
                            <p>
                                This Site is intended solely for Users who, are
                                eighteen (18) years of age or older, and any
                                registration by, use of, or access to the Site
                                by any person under 18 is unauthorized,
                                unlicensed, and in violation of these Terms of
                                Use. By using the Service or the Site, you
                                represent and warrant that you are 18 years or
                                older and that you agree to and will abide by
                                all of the terms and conditions of these Terms
                                of Use. If you violate any of these Terms of
                                Use, or otherwise violate an agreement between
                                you and us, the Company may terminate your
                                account, delete your profile and data, and/or
                                prohibit you from using or accessing the Service
                                or the Site (or any portion, aspect or feature
                                of the Service or the Site), at any time in its
                                sole discretion, with or without notice,
                                including without limitation if it believes that
                                you are under 18. GrayQuest Education Finance
                                Pvt. Ltd. is a technology service provider and
                                we facilitate the
                                application/processing/disbursal and closure of
                                loans. The terms and conditions of the lender
                                apply independently and supersede any
                                information present here.
                            </p>
                            <br />
                            
                            <h2 className={classes.section_title}>
                                Prohibited Content Categories
                            </h2>
                            <p>
                                In compliance with the Information Technology (Intermediary Guidelines and Digital Media
                                Ethics Code) Rules, 2021 ("IT Rules"), GrayQuest prohibits the hosting, display, uploading,
                                modification, publication, transmission, storage, updating, or sharing of the following categories
                                of content on its platform:
                            </p>
                            <ul className={classes.innerListDisc}>
                                <li>
                                    <span className={classes.TextBold}>Content belonging to another person without proper authorization: </span> 
                                    Content that belongs to another person and to which the user does not have any right is strictly prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Defamatory, Obscene, or Offensive Content: </span>
                                    Content that is defamatory, obscene, pornographic, paedophilic, invasive of another's privacy, including bodily privacy,
                                    insulting, or harassing on the basis of gender, racially or ethnically objectionable, or
                                    otherwise inconsistent with or contrary to the laws in force is prohibited. This includes
                                    content encouraging money laundering, gambling, or any activity that violates laws or
                                    ethical standards.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Harmful Content to Children: </span>
                                    Content that is harmful to children, including any material that may be considered inappropriate or harmful to minors, is strictly prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Infringement of Intellectual Property Rights: </span>
                                    Content that infringes any patent,
                                    trademark, copyright, or other proprietary rights is prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Violation of Laws: </span>
                                    Content that violates any law for the time being in force, including but
                                    not limited to laws related to defamation, incitement to violence, hate speech, or
                                    discrimination, is prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>False or Misleading Information: </span>
                                    Content that deceives or misleads the addressee
                                    about the origin of the message or knowingly and intentionally communicates any
                                    information that is patently false or misleading in nature but may reasonably be
                                    perceived as a fact is prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Impersonation and Threats: </span>
                                    Content that impersonates another person or threatens the
                                    unity, integrity, defence, security, or sovereignty of India, friendly relations with foreign
                                    States, public order, or insults other nations is prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Malicious Content: </span>
                                    Content that contains software viruses or any other computer code,
                                    file, or program designed to interrupt, destroy, or limit the functionality of any computer
                                    resource is prohibited.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>False Information for Financial Gain or Harm: </span>
                                    Content that is patently false and
                                    untrue, and is written or published with the intent to mislead or harass a person, entity, or
                                    agency for financial gain or to cause injury to any person, is strictly prohibited.
                                </li>
                            </ul>

                            <br />
                            <h2 className={classes.section_title}>
                                Idemnification
                            </h2>
                            <p>
                                GrayQuest shall not be responsible or liable for
                                any loss or damage, howsoever caused or suffered
                                by the User arising out of the use Products
                                offered by Merchant or due to the failure of
                                Merchant to provide Product to the Users for any
                                reason whatsoever including but not limited to
                                the User's non­compliance with the Service’s
                                terms, malfunction, partial or total failure of
                                any network terminal, data processing system,
                                computer telecom transmission or
                                telecommunications system or other circumstances
                                whether or not beyond the control of GrayQuest
                                or any person or any organization involved in
                                the above-mentioned systems.
                            </p>
                            <p>
                                GrayQuest will not be liable for any damages of
                                any kind arising from the use of the Products
                                offered by the Merchant including, but not
                                limited to direct, indirect, incidental,
                                punitive, and consequential damages.
                            </p>
                            <p>
                                GrayQuest shall not be liable for any Content
                                that you may find indecent, offensive or
                                objectionable. You shall use the Services at
                                your own risk.
                            </p>
                            <p>
                                GrayQuest will take all reasonable measures to
                                ensure that the Merchant should fulfill his duty
                                properly but there may be exceptions and
                                therefore GrayQuest will not be held liable for
                                any such compensation on account of the Merchant
                                not meeting the expectations of Users.
                            </p>
                            <p>
                                GrayQuest shall not be liable for any conduct of
                                the Merchant or the Financial Partner. GrayQuest
                                encourages Users to notify any complaints that
                                the User may have against any Merchant that the
                                User may have.
                            </p>
                            <p>
                                No advice or information, whether oral or
                                written, obtained by you from GrayQuest or
                                through or from the services shall create any
                                warranty not expressly stated in the terms.
                            </p>
                            <p>
                                GrayQuest disclaims any and all liability in
                                respect of the Merchant and their Products
                                provided thereof.
                            </p>
                            <p>
                                User agrees to indemnify and keep GrayQuest, its
                                affiliates and licensors indemnified against all
                                costs, expenses, damages, losses, and
                                liabilities incurred or suffered by GrayQuest or
                                its affiliated companies related to any User
                                Content posted or transmitted by User during the
                                use of the Website or Service or any
                                unauthorized use of the Website or Service in
                                contravention of this Agreement.
                            </p>
                            <br />
                            <h2 className={classes.section_title}>
                                Limitation of Liability
                            </h2>
                            <p>
                                Except in jurisdictions where such provisions
                                are restricted and except as provided above in
                                the paragraph titled, “investor members:
                                securities matters”, in no event will the
                                company or its directors, employees or agents be
                                liable to you or any third party for any
                                indirect, consequential, exemplary, incidental,
                                special or punitive damages, including for any
                                lost profits or lost data arising from your use
                                of the site or the service or any of the site
                                content or other materials on or accessed
                                through the site, even if the company is aware
                                or has been advised of the possibility of such
                                damages.
                            </p>
                            <p>
                                Notwithstanding anything to the contrary
                                contained herein except as provided above in the
                                paragraph titled, “investor members: securities
                                matters”, to the extent permitted by applicable
                                law the company’s liability to you for any cause
                                whatsoever, and regardless of the form of the
                                action, will at all times be limited to the
                                amount paid, if any, by you to the company for
                                the service during the term of membership. in no
                                case, except as provided above in the paragraph
                                titled, “investor members: securities matters”,
                                will the company’s liability to you exceed
                                $1000.
                            </p>
                            <p>
                                You acknowledge that if no fees are paid to the
                                company for the service, you shall be limited to
                                injunctive relief only, unless otherwise
                                permitted by law, and shall not be entitled to
                                damages of any kind from the company, regardless
                                of the cause of action.
                            </p>
                            <p>
                                In addition to the specific securities laws
                                provisions described above in the paragraph
                                titled, “investor members: securities matters”,
                                certain federal and state laws do not allow the
                                exclusion or limitation of certain damages or
                                limitations on implied warranties. If these laws
                                apply to you, some or all of the above
                                disclaimers, exclusions, or limitations may not
                                apply to you, and you may have additional
                                rights.
                            </p>
                            <br />
                            <h2 className={classes.section_title}>
                                Termination
                            </h2>
                            <p>
                                GrayQuest may at any time suspend and/or
                                terminate, in whole or in part, the services and
                                activities performed by GrayQuest in relation to
                                the BNPL Option and/ or Credit Line Option
                                (including as set out hereunder) for any reason
                                whatsoever, including for reasons of breach by
                                the User of the T&amp;Cs, terms, and conditions
                                agreed with the Partner Financial Institution
                                and/or any of the policies made available on the
                                GrayQuest Platform.
                            </p>
                            <p>
                                10.2 The User may terminate its arrangement with
                                GrayQuest in relation to the User Account by
                                writing to the GrayQuest customer care team and
                                after repayment of all amounts due and payable
                                to GrayQuest and/ or Partner Financial
                                Institution.
                            </p>
                            <p>
                                10.3 In the event of any termination or
                                suspension of services or the User Account, the
                                User shall be liable to pay in full all amounts
                                due and payable to GrayQuest and/ or Partner
                                Financial Institution as set out in the relevant
                                BNPL Statement or credit amount due to be
                                payable to Partner Financial Institution or
                                otherwise as provided under the T&amp;Cs.
                            </p>
                            <br />
                            <h2 className={classes.section_title}>
                                Governing Law and Dispute Resolution
                            </h2>
                            <p>
                                These User Terms shall be governed by, and
                                construed in accordance with, the laws of India,
                                without reference to principles of conflicts of
                                law. The parties agree that the courts of Mumbai
                                shall have exclusive jurisdiction over any
                                dispute arising from or relating to the User
                                Terms and/or the Website.
                            </p>
                            <p>
                                You expressly consent to the personal
                                jurisdiction of said courts and waive any
                                objection to such personal jurisdiction based on
                                forums non-convenience or any other basis.
                            </p>
                            <br />
                            <h2 className={classes.section_title}>
                                Copyright and Trademark
                            </h2>
                            <p className='mb-1'>
                                All related icons and logos are registered
                                trademarks or service marks of GrayQuest in
                                various jurisdictions and are protected under
                                applicable copyright, trademark, and other
                                proprietary rights Applicable Laws. The
                                unauthorized copying, modification, use, or
                                publication of these marks is strictly
                                prohibited.
                            </p>
                            <p>
                                All content on the Website and Service is the
                                copyright of GrayQuest except the third party
                                content and any link to third-party website on
                                our Website.
                            </p>
                            <p>
                                Any systematic retrieval of GrayQuest content to
                                create or compile, directly or indirectly, a
                                collection, compilation, database, or directory
                                (whether through robots, spiders, automatic
                                devices, or manual processes) without written
                                permission from GrayQuest is prohibited.
                            </p>
                            <p>
                                In addition, the use of the content for any
                                purpose not expressly permitted in this
                                agreement is prohibited and may invite legal
                                action. As a condition of Your access to and use
                                of GrayQuest's Services, You agree that You will
                                not use the Website and/or Service to infringe
                                the intellectual property rights of others in
                                any way. GrayQuest reserves the right to
                                terminate the account of a User(s) upon any
                                infringement of the rights of others in
                                conjunction with the use of GrayQuest’s Service,
                                or if GrayQuest believes that the User’s conduct
                                is harmful to the interests of GrayQuest, its
                                affiliates, or other Users, or for any other
                                reason in GrayQuest's sole discretion, with or
                                without cause.
                            </p>
                            <br />

                            <h2 className={classes.section_title}>
                                Consequences of Non-Compliance with Terms and Conditions
                            </h2>
                            <p>
                                GrayQuest, as a fintech intermediary, acknowledges the importance of compliance with the
                                Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021
                                ("IT Rules"). To ensure adherence to these regulations, GrayQuest establishes the following
                                consequences for non-compliance with its Terms and Conditions and other policies regarding
                                the usage and access of its computer resources:
                            </p>
                            <ul className={classes.innerListDisc}>
                                <li>
                                    <span className={classes.TextBold}>Immediate Termination of Access: </span>
                                    Non-compliance with the Terms and Conditions
                                    and other policies may result in the immediate termination of a user's access to
                                    GrayQuest's computer resources. This includes access to the website, mobile
                                    applications, and any other digital platforms operated by GrayQuest.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Removal of Non-Compliant Content: </span>
                                    In addition to access termination, GrayQuest
                                    reserves the right to remove any content or information deemed non-compliant with its
                                    policies. This may include content that violates the IT Rules, infringes upon the rights of
                                    others, or poses a risk to the security and integrity of GrayQuest's computer resources.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Legal Action and Remedies: </span>
                                    GrayQuest may pursue legal action and remedies against
                                    users found to be in non-compliance with its Terms and Conditions and other policies.
                                    This may include seeking damages, injunctive relief, or other appropriate legal remedies
                                    available under applicable laws.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Notification of Authorities: </span>
                                    In cases of serious non-compliance that involve illegal
                                    activities or violations of regulatory requirements, GrayQuest may notify relevant
                                    authorities or regulatory bodies as required by law. This may lead to further investigation
                                    and potential enforcement actions by the authorities.
                                </li>
                                <li>
                                    <span className={classes.TextBold}>Account Suspension or Termination: </span>
                                    Persistent or severe non-compliance with
                                    GrayQuest's policies may result in the suspension or termination of the user's account.
                                    This decision will be made at GrayQuest's discretion and may be irreversible depending
                                    on the nature and severity of the non-compliance.
                                </li>
                            </ul>
                            <br />

                            <h2 className={classes.section_title}>Consent</h2>
                            <p>
                                By clicking on the tab/button/ checkbox of
                                “GrayQuest” or any other button/checkbox of
                                similar nature, (i) you confirm that eligible
                                under Applicable Law to contract with GrayQuest,
                                (ii) you consent to conduct transactions, accept
                                the terms and conditions of this Agreement and
                                the Privacy Policy as displayed on the Website
                                and (iii) you consent to receive communications,
                                notices and information from us through post or
                                other physical medium or electronically (whether
                                through SMS, emails, phone calls, and automated
                                phone calls). Electronic communications shall be
                                deemed to have been received by you when we send
                                the electronic communication to the email
                                address/mobile number/details provided by You
                                during the sign-up process as per our records,
                                or when we post the electronic communication on
                                the Website.
                            </p>
                            <br />
                            <h2 className={classes.section_title}>
                                Force Majeure
                            </h2>
                            <p className={classes.bottom}>
                                GrayQuest and/ or Partner Financial Institution
                                shall not be liable for its failure to perform
                                under the T&amp;Cs as a result of the occurrence
                                of any force majeure events including an act of
                                God, fire, wars, sabotage, civil unrest, labor
                                unrest, the action of statutory authorities or
                                local or central governments and/or change in
                                Applicable Law, that may affect the performance
                                of GrayQuest, Partner Financial Institution,
                                payment gateways and/or the Partner Merchant.
                            </p>

                            <h2 className={classes.section_title}>
                                Grievance Mechanism System and Process
                            </h2>
                            <ul className={classes.innerListDecimal}>
                                <li>
                                    <span className={classes.ParaTitle}>
                                        Complaint Registration Process:
                                    </span>
                                    <p>Customers may register complaints or grievances through the following channels:</p>
                                    <ul className={classes.innerListDisc}>
                                        <li>
                                            By calling the Company’s dedicated helpline number: +919324507084, available from
                                            10:00 a.m. to 7:00 p.m. on Mondays to Saturdays, excluding public holidays.
                                        </li>
                                        <li>
                                            By emailing support@grayquest.com. Our team aims to respond within 24-48 hours
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span className={classes.ParaTitle}>
                                        Escalation Procedure:
                                    </span>
                                    <p>
                                        If a satisfactory response is not received within 14 days, customers are encouraged to escalate
                                        their grievance by contacting our Nodal Grievance Redressal Official at
                                        nodalofficer@grayquest.com or by calling 8356843634. We assure our customers that our team
                                        will make every effort to address their concerns.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.ParaTitle}>
                                        Resolution of Grievances:
                                    </span>
                                    <p>
                                        Upon receipt of a complaint via email or call, grayQuest will promptly acknowledge it and assign
                                        a unique complaint reference number. Complaints raised through our Nodal Grievance
                                        Redressal Official will be resolved within 14 days from the date of receipt.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.ParaTitle}>
                                        Escalation Matrix - grayQuest:
                                    </span>
                                    <p>
                                        In the event that a grievance is not satisfactorily resolved, customers may escalate the matter
                                        using the following escalation matrix:
                                    </p>
                                    <ul className={classes.innerListDisc}>
                                        <li>Level 1: Support Manager</li>
                                        <li>Level 2: Head of Support</li>
                                        <li>Level 3: Head of Department</li>
                                    </ul>
                                </li>

                                {/* <li>
                                    <span className={classes.ParaTitle}>
                                        Nodal Grievance Redressal Officials - Lender Side:
                                    </span>
                                    <p>
                                        Customers may also escalate their grievances directly to the respective Nodal Grievance
                                        Redressal Officials of our partner institutions:
                                    </p>
                                </li> */}
                            </ul>

                            {/* <div className={classes.GrievnceTableWrap}>
                                <table className={classes.GrievnceTable}>
                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Institution Name</span>
                                        </td>
                                        <td>
                                            <span className={classes.TextBold}>Contact Person</span>
                                        </td>
                                        <td>
                                            <span className={classes.TextBold}>Designation</span>
                                        </td>
                                        <td>
                                            <span className={classes.TextBold}>Email Address</span>
                                        </td>
                                        <td>
                                            <span className={classes.TextBold}>Phone Number</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Aditya Birla Finance Ltd.</span>
                                        </td>
                                        <td>Ms. Rachana Padval</td>
                                        <td>Grievance Redressal Officer</td>
                                        <td>grievance.finance@adityabirlacapital.com</td>
                                        <td>080 45860196</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Axis Finance Limited</span>
                                        </td>
                                        <td>Ms. Mangal Sarang</td>
                                        <td>Grievance Redressal Officer</td>
                                        <td>grievance@axisfinance.in</td>
                                        <td>91-22-62260000</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Arka FIncap</span>
                                        </td>
                                        <td>Ms. Priyal Shah</td>
                                        <td>Nodal Officer-Customer Grievance</td>
                                        <td>grievanceredressal@arkafincap.com</td>
                                        <td>91-22-48901424</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Ratnaafin Capital Private Ltd</span>
                                        </td>
                                        <td>Bhavesh Patel</td>
                                        <td>VP Operations</td>
                                        <td>pna.ops@ratnaafin.com</td>
                                        <td>91-9512011220</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Western Capital Advisors Pvt Ltd</span>
                                        </td>
                                        <td>Mrs. Sonia Joshi</td>
                                        <td>Grievance Redressal Officer</td>
                                        <td>contact@westerncap.in</td>
                                        <td>022-282567722</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span className={classes.TextBold}>Avanse Financial Services</span>
                                        </td>
                                        <td>Mithun Pillai</td>
                                        <td>Vice President</td>
                                        <td>gro@avanse.com</td>
                                        <td>022-6859999</td>
                                    </tr>

                                </table>
                            </div> */}

                            <br />
                            <h2 className={classes.section_title}>
                                Sachet Portal
                            </h2>
                            <p className={classes.bottom}>
                                Visit Sachet Portal {` `}
                                <a
                                    href='https://sachet.rbi.org.in'
                                    className=''
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <MdOutlineOpenInNew />
                                </a>
                            </p>

                            <h2 className={classes.section_title}>
                                Portfolio Information{' '}
                                {
                                    <span
                                        className={classes.portfolio_toggle}
                                        onClick={() => setShowText(!showText)}
                                    >
                                        {!showText
                                            ? `View Details`
                                            : `Hide Details`}
                                    </span>
                                }
                            </h2>
                            {showText && (
                                <div className={classes.PortfolioTableWrap}>
                                    <table className={classes.PortfolioTable}>
                                        <tr>
                                            <td>
                                                <span className={classes.TextBold}>Lender Name</span>
                                            </td>
                                            <td>
                                                <span className={classes.TextBold}>AUM (September'24)</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Lender 1 </td>
                                            <td>₹6,56,70,997</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 2 </td>
                                            <td>₹1,55,04,67,151</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 3 </td>
                                            <td>₹2,50,46,043</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 4 </td>
                                            <td>₹3,58,164</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 5 </td>
                                            <td>₹1,72,24,798</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 6 </td>
                                            <td>₹38,91,22,134</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 7 </td>
                                            <td>₹1,74,90,34,604</td>
                                        </tr>
                                        <tr>
                                            <td>Lender 8 </td>
                                            <td>₹1,64,75,97,458</td>
                                        </tr>
                                        <tr>
                                            <td>Total </td>
                                            <td>₹5,44,45,21,349</td>
                                        </tr>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default TermsAndConditions;
