export function generateRandomMobileNumber() {
    const DIGITS = 9;
    const random_number = Math.floor(100000000 + Math.random() * (DIGITS * 100000000));
    return `$$9${random_number}$$`;
}

export function generateRandomStudentId(LENGTH=10) {
    const p = "ABCDEFGHIJKLMNOPQRSTUVWYZabcdefghijklmnopqrstuvwyz0123456789";
    const str = [...Array(LENGTH)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
    return `$$${str}$$`;
}

export function hasDollarInString (text) {
    if (!text) return false;
    return text.indexOf("$$") === 0 && text.lastIndexOf("$$") === (text.length - 2);
}

export const snakeCaseToNormalCaseConverter = (str) => {
    if(!str) return '-';
    const convertedWords = str.split("_").map( word => word.trim().charAt(0).toUpperCase() + word.trim().slice(1));
    const convertedString = convertedWords.join(' ')
    return convertedString;
};

export const convertTitleToSlug = (title) => {
    if(!title) {
        return ''
    }
    const slug = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    return slug.replace(/^-+|-+$/g, '') || '';
};

export const dateFormatter = (data) => {
    if (!data) {
        return null;
    }
    // Month DD
    const formatter1 = new Intl.DateTimeFormat('en-IN', { month: 'short'});
    const formatter2 = new Intl.DateTimeFormat('en-IN', { year: 'numeric' });
    const month = formatter1.format(new Date(data));
    const year = formatter2.format(new Date(data));
    const date = new Date(data).getDate();

    return `${month} ${date < 10 ? '0' + date : date}, ${year} `;
};