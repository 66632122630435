import React from 'react'
import Footer1 from '../Common/Footer1/Footer1'
import Header1 from '../Common/Header1/Header1'
import "./PageNotFound.css"
import pageNotFoundBackgroundImage from "../../assets/images/pageNotFound/pageNotFoundBackgroundImage.png"
import curvedLinesforpagenotFound from "../../assets/images/pageNotFound/curvedLinesforpagenotFound.png"
import backToHomeCurvedArrow from "../../assets/images/pageNotFound/backToHomeCurvedArrow.png"
import mobileViewPageNotFoundBackgroundImage from "../../assets/images/pageNotFound/mobileViewPageNotFoundBackgroundImage.png"
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
      <div className='pageNotFoundpage'>
          <Header1 theme="pageNotFound" />
          <div id="pageNotFoundComponent" className='parents career'>
              <img id="pageNotFoundBackgroundImage" width="90%" src={pageNotFoundBackgroundImage} />
              <img id="mobileViewPageNotFoundBackgroundImage" width="90%" src={mobileViewPageNotFoundBackgroundImage} />
              <div id="page_not_found_error">
                  <div>
                      <span id="error_code" className='text--blueGradient error_message'>
                          404
                          <img className='curvedLinesforpagenotFound' width="30%" src={curvedLinesforpagenotFound} />
                      </span> 
                  <span id="whoops" className='error_message'> Whoops…</span> 
                </div>
                      
                  <p className='error_description'>
                  We couldn’t find the page you’re looking for but you can always go
                          <img className='backToHomeCurvedArrow' width="16%" src={backToHomeCurvedArrow} />
                  </p>
                  <div>
                      <Link to="/"
                      >

                          <button className='goBackButton'>Back to Home</button>
                          </Link>
                  </div>
              </div>
          </div>
          <Footer1 />
      </div>
  )
}

export default PageNotFound