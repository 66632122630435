import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { MdOutlineExpandMore, MdDoDisturb } from "react-icons/md";
// import { SpinnerLoader } from '..';
import classes from './CustomReactSelect.module.scss';


const CustomReactSelect = (props) => {
    const { icon, noOptionsText, showDisabledIcon, isDisabled } = props;
    const noOptionsMessageString = noOptionsText || "No options";

    const customStyles = {
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0px',
        }),
        control: (styles, ...props) => ({
            ...styles,
            borderColor: isDisabled ? 'transparent' : '#adadad',
            borderRadius: "12px",
            pointerEvents: isDisabled ? 'auto' : '',
            cursor: isDisabled ? 'not-allowed' : 'Default',
            paddingLeft: icon ? '24px' : '10px',
            paddingRight: '10px',
            height: '48px',
            boxShadow: 'none',
            "&:hover": {
                borderColor: "#3e52ce"
            }
        }),
        singleValue: (styles) => ({
            ...styles,
            color: isDisabled ? '#666666' : '#4e5760',
        }),
        placeholder: (styles) => ({
            ...styles,
            fontSize: '14px',
        }),
        menu: (styles, state) => ({
            ...styles,
            border: '1px solid #f6f6f6',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            padding: '10px 2px 10px 8px',
            zIndex: 99,
        }),
        // menuList: (styles, state) => ({
        //     ...styles,
        // }),
        option: (styles, state) => ({
            ...styles,
            textAlign: 'left',
            marginBottom: '4px',
            cursor: 'pointer',
            borderRadius: '12px',
            color: state.isSelected ? '#181c32' : '#4e5760',
            backgroundColor: state.isFocused || state.isSelected ? '#e3ecfd' : 'none',
            transition: '0.1s ease-in-out',
            '&:hover': {
                backgroundColor: '#e3ecfd',
            },
        }),
    };

    return (
        <div className={classes.CustomReactSelectWrap}>
            {icon ? 
                (<span className={`${classes.SelectIcon} ${classes.LeftIcon}`}>{icon}</span>)
                : null
            }
            <Select
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: ({isDisabled, ...rest}) => (
                        <components.DropdownIndicator {...rest}>
                            {!isDisabled ? 
                                (<span className={classes.SelectIcon}>
                                    <MdOutlineExpandMore />
                                </span>) 
                                : (<span className={`${classes.SelectIcon} ${!showDisabledIcon ? classes.Hidden : ""}`}>
                                    <MdDoDisturb />
                                </span>)
                            }
                        </components.DropdownIndicator>
                    ),
                    // Control: ({ children, ...rest }) => (
                    //     <components.Control {...rest}>
                    //         {icon && <span className={classes.SelectIcon}>{icon}</span>} {children}
                    //     </components.Control>
                    // ),
                }}
                styles={customStyles}
                className="react-select-container"
                classNamePrefix="react-select"
                noOptionsMessage={() => noOptionsMessageString}
                {...props}
            />
        </div>
    );
};

CustomReactSelect.propTypes = {
    icon: PropTypes.element,
    noOptionsText: PropTypes.string,
    showDisabledIcon: PropTypes.bool,
};

export default CustomReactSelect;