import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGqCareerIndividualJobDataGet } from '../../../api-cms-client';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const getGqCareerJobDataFetch = createAsyncThunk(
    'getGqCareerJobDataFetch/Get',
    async (body, { rejectWithValue }) => {
        try {
            const response = await fetchGqCareerIndividualJobDataGet(body);
            const data = response?.data;
            return data;
        } catch (err) {
            const errObj = {
                type: 'GET_GQ_CAREER_JOB_DATA_FETCH_FAILURE',
                error: err,
                message: 'Oops! something went wrong',
            };
            return rejectWithValue(err?.response?.data || errObj);
        }
    }
);

const getGqCareerJobDataFetchSlice = createSlice({
    name: 'getGqCareerJobDataFetch',
    initialState,
    extraReducers: {
        [getGqCareerJobDataFetch.pending]: (state) => {
            state.loading = true;
        },
        [getGqCareerJobDataFetch.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload?.data;
            state.error = null;
        },
        [getGqCareerJobDataFetch.rejected]: (state, { payload }) => {
            state.loading = false;
            state.data = null;
            state.error = payload;
        },
    },
});

export const getGqCareerJobDataFetchSelector = (state) =>
    state.getGqCareerJobDataFetch;
export const getGqCareerJobDataFetchReducer =
    getGqCareerJobDataFetchSlice.reducer;
