import { apiClient } from './axios';
import { routes } from './routes';

export const fetchAllGqBlogsGet = (params) => {
    let url = routes.GQ_BLOGS_DATA_FETCH.URL;
    let urlParams = new URLSearchParams(url.search);

    if (params?.pageSize) {
        urlParams.append(
            JSON.stringify('pagination[pageSize]'),
            params?.pageSize
        );
    } else {
        urlParams.append('pagination[pageSize]', 6);
    }
    if (params?.page) {
        urlParams.append('pagination[page]', params?.page);
    } else {
        urlParams.append('pagination[page]', 1);
    }
    if (params?.title) {
        urlParams.append('filters[$or][0][title][$containsi]', params?.title);
    }
    if (params?.categories?.length > 0) {
        params?.categories?.map((category) => {
            urlParams.append(
                'filters[categories][label][$containsi]',
                category?.value
            );
        });
    }
    if (params?.sort) {
        urlParams.append('sort[0]', params?.sort);
    } else {
        urlParams.append('sort[0]', 'publishedAt:desc');
    }

    return apiClient({
        method: routes.GQ_BLOGS_DATA_FETCH.METHOD,
        url,
        params: urlParams,
    });
};

export const fetchGqBlogDataGet = (searchObj = null) => {
    let queryString = '';
    if (searchObj) {
        queryString = searchObj;
    }
    return apiClient({
        method: routes.GQ_BLOG_FETCH.METHOD,
        url: `${routes.GQ_BLOG_FETCH.URL}${
            queryString ? `${queryString}?` : ''
        }${routes.GQ_BLOG_FETCH.FILTERS}`,
    });
};

export const fetchGqBlogCategoryDataGet = () => {
    return apiClient({
        method: routes.GQ_BLOGS_CATEGORY_DATA_FETCH.METHOD,
        url: routes.GQ_BLOGS_CATEGORY_DATA_FETCH.URL,
    });
};

export const fetchGqFeaturedBlogDataGet = () => {
    return apiClient({
        method: routes.GQ_FEATURED_BLOG_DATA_FETCH.METHOD,
        url: routes.GQ_FEATURED_BLOG_DATA_FETCH.URL,
    });
};

export const fetchGqCareerJobsDataGet = (params) => {
    let url = routes.GQ_CAREER_JOBS_DATA_FETCH.URL;
    let urlParams = new URLSearchParams();

    if (params?.page) {
        urlParams.append('pagination[page]', params?.page);
    } else {
        urlParams.append('pagination[page]', 1);
    }
    if (params?.pageSize) {
        urlParams.append('pagination[pageSize]', params?.pageSize);
    } else {
        urlParams.append('pagination[pageSize]', 8);
    }
    if (params?.job_role) {
        urlParams.append('filters[job_role][$containsi]', params?.job_role);
    }
    if (params?.department) {
        urlParams.append(
            'filters[department][value][$containsi]',
            params?.department
        );
    }

    return apiClient({
        method: routes.GQ_CAREER_JOBS_DATA_FETCH.METHOD,
        url: url,
        params: urlParams,
    });
};

export const fetchGqCareerIndividualJobDataGet = (searchObj = null) => {
    let queryString = '';
    if (searchObj) {
        queryString = searchObj;
    }
    return apiClient({
        method: routes.GQ_CAREER_JOB_FETCH.METHOD,
        url: `${routes.GQ_CAREER_JOB_FETCH.URL}${
            queryString ? `${queryString}?` : ''
        }${routes.GQ_CAREER_JOB_FETCH.FILTERS}`,
    });
};

export const fetchGqJobDepartmentDataGet = () => {
    return apiClient({
        method: routes.GQ_CAREER_JOB_DEPARTMENT_DATA_FETCH.METHOD,
        url: routes.GQ_CAREER_JOB_DEPARTMENT_DATA_FETCH.URL,
    });
};
