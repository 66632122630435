import React from 'react';
import { FaLock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SecuredByLogosImage from "../../../assets/images/institute_details/security_logos.png";
import classes from './InstituteDetailsFooter.module.scss';

const InstituteDetailsFooter = () => {
    return (
        <div className={classes.InstituteDetailsFooter}>
            <span className={classes.SecuredByText}>
                <FaLock />&nbsp;Secured By
            </span>
            <img 
                src={SecuredByLogosImage} 
                className={classes.SecuredByLogosImage} 
                alt="Safe & Secured" 
            />

            <div className={classes.PageFooter}>
                <Link to={`/pg-terms-and-conditions`} >
                    Terms & Conditions
                </Link>
                <Link to={`/pg-privacy-policy`} >
                    Privacy Policy
                </Link>
            </div>
        </div>
    )
}

export default InstituteDetailsFooter;