import React from 'react';
import PropTypes from 'prop-types';
import { GileSearchForm } from '../../molecules';
import classes from './SearchBlock.module.scss';
import { InstituteDetailsParagraph } from '../../atoms';

const SearchBlock = ({ pageData }) => {
    const { search_block } = pageData;
    const { gile_search } = search_block;

    return (
        <div className={classes.SearchBlock}>
            {(gile_search?.visible) ? 
                (<section className={classes.GileSearch}>
                    <div className={classes.Content}>
                        {(gile_search?.title) ? (<h2 className={classes.Title}> {gile_search.title}</h2>) : ""}

                        {(gile_search?.subtitle) ? (<h3 className={classes.SubTitle}>{gile_search.subtitle}</h3>) : ""}
                        
                        {(gile_search?.description?.length > 0) ? 
                            (<div className={classes.Description}>
                                {gile_search.description.map((paragraph, index) => (
                                    <InstituteDetailsParagraph key={`gile-search-para-${index}`} paragraph={paragraph} />
                                ))}
                            </div>) : null
                        }
                    </div>

                    <GileSearchForm pageData={pageData} />
                </section>)
                : null
            }
        </div>
    )
}

SearchBlock.propTypes = {}

export default SearchBlock;
