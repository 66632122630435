import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerLoader } from '..';
import classes from './CustomButton.module.scss';

const CustomButton = (props) => {
    const { type, 
        variant, 
        size, 
        isLoading, 
        isDisabled, 
        onClick, 
        icon, 
        text, 
        title, 
        className } = props;
    let buttonSizeClass = '';
    let buttonVariantClass = classes.Solid;

    if (size === 'small') {
        buttonSizeClass = classes.Small;
    } else if (size === 'large') {
        buttonSizeClass = classes.Large;
    }
    if (variant === 'outline') buttonVariantClass = classes.Outline;
    const handleClick = () => onClick && onClick();

    return (
        <button
            type={type === 'submit' ? 'submit' : 'button'}
            disabled={isDisabled || isLoading}
            className={`${classes.CustomButton} ${buttonVariantClass} ${buttonSizeClass} ${className}`}
            onClick={handleClick}
            title={title}
        >
            {icon && <span className={classes.CustomButton__Icon}>{icon}</span>}
            {text && <span className={classes.CustomButton__Text}>{text}</span>}
            {isLoading && (
                <span className={classes.CustomButton__Loader}>
                    <SpinnerLoader size="tiny" trackColor="#ccc" spinnerColor="#5667ff" />
                </span>
            )}
        </button>
    );
};

CustomButton.propTypes = {
    type: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    text: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
};

export default CustomButton;
