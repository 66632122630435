import {
    getGqBlogsFetchAllReducer,
    getGqBlogDataFetchReducer,
    getGqBlogCategoryFetchReducer,
    getGqFeaturedBlogDataFetchReducer
} from '../../slices/gq-blogs';

export const gqBlogsFetchReducers = {
    getGqBlogsFetchAll: getGqBlogsFetchAllReducer,
    getGqBlogDataFetch: getGqBlogDataFetchReducer,
    getGqBlogCategoryFetch: getGqBlogCategoryFetchReducer,
    getGqFeaturedBlogDataFetch: getGqFeaturedBlogDataFetchReducer
};
