import { ENV_MODE } from "../../constants";
import { GA_CODE } from "./GAEventConstants";

function sendGA4Event(data) {

    if (!window.gtag || ENV_MODE !== 'production') {
		return;
	}

    switch (data.type) {
        // case 'loginEvent':
        //     console.log("SEND LOGIN EVENT", data.type);
        //     try {
        //       gtag('event', 'login', {
        //         'method': 'MobileNumber'
        //       });
        //     } catch(error) {
        //       console.error("gtag error!", error);
        //     }
        //     break;
        // case 'pageViewEvent':
        //     console.log("SEND PAGEVIEW EVENT", data.type);
        //     try {
        //         const eventObj = {};
        //         data.page_title ? eventObj.page_title = data.page_title : '';
        //         data.page_location ? eventObj.page_location = data.page_location : '';
        //         data.page_path ? eventObj.page_path = data.page_path : '';
        //         eventObj.send_to = GA_CODE;

        //         gtag('event', 'page_view', eventObj);
        //     } catch(error) {
        //         console.error("gtag error!", error);
        //     }
        //     break;
        case 'event':
            console.log("SEND ACTION EVENT", data.type);
            try {
                const eventObj = {};
                data.event_category ? eventObj.event_category = data.event_category : '';
                data.event_label ? eventObj.event_label = data.event_label : '';
                data.value ? eventObj.value = data.value : '';

                gtag('event', data.event_action, eventObj);
            } catch(error) {
                console.error("gtag error!", error);
            }
            break;
        default:
            console.error('No event data sent');
            break;
    }
}

export default sendGA4Event;
