import React from 'react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import './Header1.css';
import Logo from '../../../assets/images/header/Logo.svg';
import blueLogo from '../../../assets/images/header/blueLogo.svg';

import dropdown1 from '../../../assets/images/header/dropdown1.png';
import dropdown2 from '../../../assets/images/header/dropdown2.png';
import iconDownArrow from '../../../assets/images/header/iconDownArrow.png';
import { CUSTOMER_BASE_URL, INSTITUTE_BASE_URL } from '../../../../constants';

function Header1(props) {
    const [showDropDown, setShowDropDown] = useState(false);
    const themeColors = {
        home: {
            backgroundColor: '#3e54ce',
            logo: Logo,
            textColor: 'white',
            humburgerColor: 'whiteHumberger',
        },
        institute: {
            backgroundColor: '#3e54ce',
            logo: Logo,
            textColor: 'white',
            humburgerColor: 'whiteHumberger',
        },
        parents: {
            backgroundColor: '#e7f5ff',
            logo: blueLogo,
            textColor: 'black',
            humburgerColor: 'blackHumberger',
        },
        contact: {
            backgroundColor: '#f7f9fc',
            logo: blueLogo,
            textColor: 'black',
            humburgerColor: 'blackHumberger',
        },
        aboutUs: {
            backgroundColor: '#f7eecb',
            logo: blueLogo,
            textColor: 'black',
            humburgerColor: 'blackHumberger',
            signUpBackgroundColor: 'white',
            signUpTextColor: '#11142c',
            loginBackgroundColor: '#213071',
            loginTextColor: '#ffffff',
        },
        careers: {
            backgroundColor: '#e7f5ff',
            logo: blueLogo,
            textColor: 'black',
            humburgerColor: 'blackHumberger',
            signUpBackgroundColor: 'white',
            signUpTextColor: '#11142c',
            loginBackgroundColor: '#213071',
            loginTextColor: '#fff',
        },
        // blogs: {
        //     backgroundColor: '#f7f9fc',
        //     logo: blueLogo,
        //     textColor: 'black',
        //     humburgerColor: 'blackHumberger',
        // },
        pageNotFound: {
            backgroundColor: '#e7f5ff',
            logo: blueLogo,
            textColor: 'black',
            humburgerColor: 'blackHumberger',
            signUpBackgroundColor: 'white',
            signUpTextColor: '#11142c',
            loginBackgroundColor: '#213071',
            loginTextColor: '#fff',
        },
    };
    const [isActive, setIsActive] = useState(false);
    const handleClick = (event) => {
        // 👇️ toggle isActive state on click
        setIsActive((current) => !current);
    };

    return (
        <>
            <header>
                <nav
                    className='mainNav navbar fixed-top navbar-expand-lg bg-fixed'
                    style={{
                        backgroundColor:
                            themeColors[props.theme].backgroundColor,
                    }}
                >
                    <div className='container'>
                        <div className='nav-right align-logo'>
                            <Link className='navbar-brand' to='/'>
                                <img
                                    className='img-fluid'
                                    src={themeColors[props.theme].logo}
                                    alt='GrayQuest Logo'
                                />
                                <span className='sr-only'>GrayQuest Logo</span>
                            </Link>
                        </div>

                        <div
                            className={
                                isActive
                                    ? 'hamburger hamburger--squeeze is-active'
                                    : 'hamburger hamburger--squeeze'
                            }
                            aria-controls='header-menu'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={handleClick}
                        >
                            <div
                                className='hamburger-box'
                                id={themeColors[props.theme].humburgerColor}
                            >
                                <div className='hamburger-inner'></div>
                            </div>
                        </div>

                        <div
                            className='collapse navbar-collapse'
                            id='header-menu'
                        >
                            <div className='navbar-nav' id='header-menu-list'>
                                <div
                                    className={`nav-item ${
                                        props.theme == 'institute'
                                            ? 'nav-item-active'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to='/institute'
                                        className='active'
                                        style={{
                                            color: themeColors[props.theme]
                                                .textColor,
                                        }}
                                    >
                                        For Institutes
                                    </Link>
                                </div>
                                <div
                                    className={`nav-item ${
                                        props.theme == 'parents'
                                            ? 'nav-item-active'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to='/parents'
                                        className='active'
                                        style={{
                                            color: themeColors[props.theme]
                                                .textColor,
                                        }}
                                    >
                                        For Parents
                                    </Link>
                                </div>
                                <div
                                    className={`nav-item ${
                                        props.theme == 'aboutUs'
                                            ? 'nav-item-active'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to={'/about-us'}
                                        className='active'
                                        style={{
                                            color: themeColors[props.theme]
                                                .textColor,
                                        }}
                                    >
                                        About Us
                                    </Link>
                                </div>
                                <div
                                    className={`nav-item ${
                                        props.theme == 'careers'
                                            ? 'nav-item-active'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to='/careers'
                                        className='active'
                                        style={{
                                            color: themeColors[props.theme]
                                                .textColor,
                                        }}
                                    >
                                        Careers
                                    </Link>
                                </div>
                                {/* <div
                                    className={`nav-item ${
                                        props.theme == 'blogs'
                                            ? 'nav-item-active'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to='/blogs'
                                        className='active'
                                        style={{
                                            color: themeColors[props.theme]
                                                .textColor,
                                        }}
                                    >
                                        <a>Blogs</a>
                                    </Link>
                                </div> */}
                                <div
                                    className={`nav-item ${
                                        props.theme == 'contact'
                                            ? 'nav-item-active'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to='/contact'
                                        className='active'
                                        style={{
                                            color: themeColors[props.theme]
                                                .textColor,
                                        }}
                                    >
                                        Contact
                                    </Link>
                                </div>
                            </div>
                            <div className='header--btn'>
                                <a
                                    href={CUSTOMER_BASE_URL}
                                    rel='noreferrer'
                                    className='custom__btn custom__btn--blue'
                                    target={'_blank'}
                                    style={{
                                        borderLeft: '2px solid  #213071',
                                        color: themeColors[props.theme]
                                            .signUpTextColor,
                                        backgroundColor:
                                            themeColors[props.theme]
                                                .signUpBackgroundColor,
                                    }}
                                >
                                    Sign up
                                </a>
                                <OutsideClickHandler
                                    onOutsideClick={() =>
                                        setShowDropDown(false)
                                    }
                                >
                                    <div className='login--btn'>
                                        <a
                                            href='#'
                                            rel='noreferrer'
                                            className='custom__btn custom__btn--white'
                                            onClick={() =>
                                                setShowDropDown(!showDropDown)
                                            }
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    themeColors[props.theme]
                                                        .loginBackgroundColor,
                                                color: themeColors[props.theme]
                                                    .loginTextColor,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: '0.5rem',
                                                }}
                                            >
                                                Login
                                            </div>

                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='12'
                                                height='12'
                                                fill='currentColor'
                                                className='bi bi-chevron-down'
                                                viewBox='0 0 16 16'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                                                />
                                            </svg>
                                        </a>
                                    </div>

                                    {showDropDown && (
                                        <div className='dropdown_menu'>
                                            <a
                                                href={CUSTOMER_BASE_URL}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <div className='loginIcon'>
                                                    {/* <div className="icon">
                            <img
                              src={dropdown1}
                              alt="institute"
                            />
                          </div> */}

                                                    <p
                                                        className='dropdown_menu_text'
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {' '}
                                                        Parents login
                                                    </p>
                                                </div>
                                            </a>

                                            <div
                                                style={{
                                                    width: '135px',
                                                    height: '1px',
                                                    margin: '14.5px 0 14.4px 0.5px',
                                                    backgroundColor:
                                                        'rgba(11, 13, 36, 0.15)',
                                                }}
                                            ></div>

                                            <a
                                                href={INSTITUTE_BASE_URL}
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                <div className='loginIcon'>
                                                    {/* <div className="icon">
                            <img
                              src={dropdown2}
                              alt="parents"
                            />
                          </div> */}
                                                    <p
                                                        className='dropdown_menu_text'
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Educational Institute
                                                        login
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </OutsideClickHandler>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header1;