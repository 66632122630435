import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { ENV_MODE, SENTRY_AUTH_TOKEN, SENTRY_DSN, SENTRY_ENABLED, SENTRY_TRACES_SAMPLE_RATE } from '../../constants';

const IS_PRODUCTION = ENV_MODE === 'production';

if (SENTRY_ENABLED && SENTRY_AUTH_TOKEN && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        enabled: SENTRY_ENABLED,
        environment: IS_PRODUCTION ? 'production' : 'development',
        tracePropagationTargets: IS_PRODUCTION
            ? [/^\//, /^https:\/\/.*\.grayquest\.com/, /^https:\/\/grayquest\.com/]
            : ['localhost', /^\//, /^https:\/\/.*\.graydev\.tech/, /^https:\/\/graydev\.tech/],
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            })
        ],

        tracesSampleRate: Number(SENTRY_TRACES_SAMPLE_RATE),
    });
    if ('connection' in navigator) {
        const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;
        if (connection) {
            Sentry.setContext("network", {
                effectiveType: connection?.effectiveType,
                downlink: connection?.downlink,
                rtt: connection?.rtt,
              });
        }
      }
}