import React from 'react';
import classes from './InstituteDetailsParagraph.module.scss';

const InstituteDetailsParagraph = (props) => {
    const { paragraph } = props;
    const classList = `${classes.Paragraph}
        ${paragraph?.is_bold ? classes.Bold : ''} 
        ${paragraph?.is_italic ? classes.Italic : ''} 
        ${paragraph?.is_underlined ? classes.Underlined : ''}`;
    return (
        <>{paragraph?.para ? (<p className={classList}>{paragraph.para}</p>) : null}</>
    )
}

export default InstituteDetailsParagraph
