import React, { useState } from "react";
import titlestar from "../../assets/images/institute/title-star.svg";
import paymentOne from "../../assets/images/institute/payment-one.webp";
import paymentsecond from "../../assets/images/institute/paymentsecond.png";
import paymentTwo from "../../assets/images/institute/payment-two.png";
import paymentzero from "../../assets/images/institute/paymentzero.png";
import paymentOptionThirdMobile from "../../assets/images/institute/paymentOptionThirdMobile.png";
import debit1 from "../../assets/images/institute/debit1.png";
import debit2 from "../../assets/images/institute/debit2.png";
import online1 from "../../assets/images/institute/online1.png";
import online2 from "../../assets/images/institute/online2.png";
import online3 from "../../assets/images/institute/online3.png";
import zeroCost_desktop from "../../assets/images/institute/zeroCost_desktop.png";
import Slider from "react-slick";
import "./instituteSlider.css";

function InstituteSlider() {

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="instituteSlider">
      <section className="bg--lightGray zeroCostSection">
        <div className="container zeroCostContainer">
          <div className="col-lg-6 ">
            <p className="section__label blue">
              <img className="img-fluid" src={titlestar} alt="star icon" />{" "}
              Payment Option 1{" "}
            </p>
            <h2 className="section__title text--bold text--black">
              <span className="text-spiral zeroTxt">Zero Cost EMI</span>{" "}
            </h2>
            <p className="text--black text--regular section__desc offerparentTxt">
              Offer parents the convenience of paying fees in affordable monthly
              instalments with a quick 2 min sign-up!
            </p>

            <Slider {...settings}>
              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img  src={paymentzero} />
                    <div className="sliderTxt">
                      <h5>Get the full year’s fee, upfront</h5>
                      <h6>
                        Receive upfront fees at the beginning of the academic
                        year from GrayQuest, even if parents pay in parts.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img style={{width: "110%"}} src={paymentOne} />
                    <div className="sliderTxt">
                      <h5>Boost institute’s cashflow</h5>
                      <h6>
                        Use the upfront cash flow for your growth and expansion
                        plans.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img style={{width: "110%"}}  src={paymentOptionThirdMobile} />
                    <div className="sliderTxt">
                      <h5>Digital and secure payments</h5>
                      <h6>
                        We have a simple, 2-min sign up process that’s
                        completely digital.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
          <div></div>
          <div className="small-screen">
            <div className="custom__accordion-slider">
              <div className="item"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--yellow ">
        <div className="container zeroCostContainer">
          <div className="col-lg-6 ">
            <p className="section__label blue">
              <img className="img-fluid" src={titlestar} alt="star icon" />{" "}
              Payment Option 2{" "}
            </p>
            <h2 className="section__title text--bold text--black">
              <span className="text-spiral zeroTxt">
              Auto-Debit Subscription Payments
              </span>{" "}
            </h2>
            <p className="text--black text--regular section__desc offerparentTxt">
            No more post-dates cheques! Collect fees as per due dates by automatically debiting them from the parent’s account!
            </p>

            <Slider {...settings}>
              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img src={zeroCost_desktop} />
                    <div className="sliderTxt">
                      <h5>50 sec sign up for auto-debit</h5>
                      <h6>
                      A simple process for parents to register for auto-debit and can use the same registration for upto 15 years.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img style={{width: "110%"}} src={debit1} />
                    <div className="sliderTxt">
                      <h5>Send fee-related alerts and reminders</h5>
                      <h6>
                      With one click, send custom fee reminders and notifications to parents through WhatsApp, email and SMS.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img src={debit2} />
                    <div className="sliderTxt">
                      <h5>Eliminate fee follow-ups</h5>
                      <h6>
                      With auto-debit payments, fee will be auto-debited from parent’s account on due dates.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
          <div></div>
          <div className="small-screen">
            <div className="custom__accordion-slider">
              <div className="item"></div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg--lightGray "> */}

      <section className="bg--lightGray ">
        <div className="container zeroCostContainer">
          <div className="col-lg-6 ">
            <p className="section__label blue">
              <img className="img-fluid" src={titlestar} alt="star icon" />{" "}
              Payment Option 3{" "}
            </p>
            <h2 className="section__title text--bold text--black">
              <span className="text-spiral zeroTxt">Online Payments</span>{" "}
            </h2>
            <p className="text--black text--regular section__desc offerparentTxt">
              Parents can simply choose to pay online through a variety of
              online payment methods covered under RAPID.
            </p>

            <Slider {...settings}>
              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img  src={online1} />
                    <div className="sliderTxt">
                      <h5>Flexible fee-payment options</h5>
                      <h6>
                      Collect fees from parents via UPI, debit cards, net banking, credit cards, and wallets seamlessly!
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img style={{width: "135%"}} src={online2} />
                    <div className="sliderTxt">
                      <h5>Auto-reconciliation of payments</h5>
                      <h6>
                      Use the upfront cash flow for your growth and expansion plans.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sliderimageContainer">
                  <div className="sliderImage">
                    <img src={online3} />
                    <div className="sliderTxt">
                      <h5>Digital and secure payments</h5>
                      <h6>
                      We have a simple, 2-min sign up process that’s completely digital.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
          <div></div>
          <div className="small-screen">
            <div className="custom__accordion-slider">
              <div className="item"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default InstituteSlider;
