import React from 'react';
import PropTypes from 'prop-types';
import classes from './JobCard.module.scss';

const JobCard = (props) => {
    const { job, onClick } = props;

    const handleClick = () => onClick && onClick();

    return (
        <div
            onClick={handleClick}
            key={job?.id}
            className={classes.OpenPositionsCard}
            >
            <p className={classes.OpenPositionsRole}>
                {job?.attributes?.job_role}
            </p>
            <p className={classes.OpenPositionsTeam}>
                {'Department - ' +
                    job?.attributes?.department?.data?.attributes?.label}
            </p>
        </div>
    );
};

JobCard.propTypes = {
    job: PropTypes.object,
    onClick: PropTypes.func,
};

export default JobCard;
