import "bootstrap/dist/css/bootstrap.min.css";
import * as Sentry from "@sentry/react";
import "./App.css";
import 'reset-css';
import Home from "./Components/Home/Home";
import Parents from "./Components/Parents/Parents";
import Contact from "./Components/Contact/Contact";
import { BrowserRouter, Routes, Route, useLocation, Link, Navigate } from "react-router-dom";
import Institute from "./Components/Institutes/Institutes";
import InstitutePrivacyPolicy from "./pages/InstitutePrivacyPolicy/InstitutePrivacyPolicy";
import UpgradEligibilityCheck from "./pages/UpgradEligibilityCheck/UpgradEligibilityCheck";
import InstituteTermsAndConditions from "./pages/InstitueTermsAndConditions/InstituteTermsAndConditions";
import AboutUs from "./Components/AboutUs/AboutUs";
import Career from "./Components/Career/Career";
import { useEffect } from "react";
import GrievanceOfficer from "./pages/GrievanceOfficer/GrievanceOfficer";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RedirectToLandingPage from "./Components/RedirectToLandingPage/RedirectToLandingPage";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import MessageConfirmation from "./Components/MessageConfirmation/MessageConfirmation";
import DynamicInstituteDetails from "./pages/DynamicInstituteDetails/DynamicInstituteDetails";
import LendingPartners from "./pages/LendingPartners/LendingPartners";
import { Blog, Blogs, AllBlogs } from './pages/BlogsPage/';
import {JobDescription, AllJobs } from './pages/JobsPage'

function App() {
  const location = useLocation()

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

    useEffect(()=>{
      window.scrollTo(0,0)
    },[location.pathname])

  return (
    <div className="App">
        <SentryRoutes>
          <Route index element={<Home />} />
          <Route path="parents" element={<Parents />} />
          <Route path="contact" element={<Contact />} />
          <Route path="institute" element={<Institute />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="careers" >
            <Route path=""  element={<Career />} />
            <Route path="all" element={<AllJobs />} />
            <Route path=":job_role" element={<JobDescription />} />
          </Route>          <Route path="grievance-officer" element={<GrievanceOfficer />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="lending-partners" element={<LendingPartners />} />
          <Route path="pg-terms-and-conditions" element={<InstituteTermsAndConditions />} />
          <Route path="pg-privacy-policy" element={<InstitutePrivacyPolicy />} />
          <Route path="upgrad-eligibility-check" element={<UpgradEligibilityCheck />} />
          <Route path="institute/:institute_slug" element={<DynamicInstituteDetails />} />
          <Route path="partner-with-us" element={<Navigate to="/institute" replace />} />
          {/* <Route path="blogs" >
            <Route path=""  element={<Blogs />} />
            <Route path="all-blogs" element={<AllBlogs />} />
            <Route path=":id/:title" element={<Blog />} />
          </Route> */}
          <Route path="*" element={<PageNotFound />} />
        </SentryRoutes>

        <ToastContainer position="top-center" theme="colored" />
    </div>
  );
}

export default App;