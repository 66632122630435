import React from 'react';
import Header1 from '../../Components/Common/Header1/Header1';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';
import axisFinance from '../../assets/images/terms/axisFinance.png';
import arkalogo from '../../assets/images/terms/arkalogo.png';
import adityabirlalogo from '../../assets/images/terms/abfl.png';
import avanselogo from '../../assets/images/terms/avanselogo.png';
import westerncaplogo from '../../assets/images/terms/westerncaplogo.png';
import fintreelogo from '../../assets/images/terms/fintreelogo.png';
import arthmatelogo from '../../assets/images/terms/arthmatelogo.png';
import ratnaafin from '../../assets/images/terms/ratnaafin_logo.jpg';
import idfclogo from '../../assets/images/terms/idfcFirst.png';
import smfglogo from '../../assets/images/terms/smfg.png';
import miraelogo from '../../assets/images/terms/mirae.png';
import yeslogo from '../../assets/images/terms/yesBank.png';
import dcblogo from '../../assets/images/terms/dcbBank.png';
import classes from './LendingPartners.module.scss';

function LendingPartners() {
    return (
        <>
            <CustomHelmet page='lendingpartners' />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.tacHeading}>
                            <span>Our Lending Partners</span>
                        </h1>
                    </div>
                </section>
                
                <section className={classes.body_section}>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div
                                    className={classes.lender_logo}
                                >
                                    <a
                                        href='https://axisfinance.in/Customer-Services/customer-care'
                                        target='_blank'
                                    >
                                        <img
                                            className={classes.lender_logo_img}
                                            src={axisFinance}
                                            alt='Axis Bank'
                                        />
                                    </a>
                                    <a
                                        href='https://axisfinance.in/Customer-Services/customer-care'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Axis Finance &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://www.arkaholdings.com/arka-fincap/embedded-finance'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={arkalogo}
                                            alt='Arka Finance'
                                        />
                                    </a>
                                    <a
                                        href='https://www.arkaholdings.com/arka-fincap/embedded-finance'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Arka Fincap &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://www.adityabirlacapital.com/Privacy-Policy'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={adityabirlalogo}
                                            alt='Aditya Birla Capital'
                                        />
                                    </a>
                                    <a
                                        href='https://www.adityabirlacapital.com/Privacy-Policy'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Aditya Birla Capital
                                        &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://www.avanse.com/privacy-policy'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={avanselogo}
                                            alt='Avanse Financial Services'
                                        />
                                    </a>
                                    <a
                                        href='https://www.avanse.com/privacy-policy'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Avanse Finance &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://westerncap.in/terms-and-condition/#'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={westerncaplogo}
                                            alt='Western Capital'
                                        />
                                    </a>
                                    <a
                                        href='https://westerncap.in/terms-and-condition/#'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Western Capital &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://fintreefinance.com/fintree-privacy-policy/'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={fintreelogo}
                                            alt='Fintree Finance'
                                        />
                                    </a>
                                    <a
                                        href='https://fintreefinance.com/fintree-privacy-policy/'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Fintree Finance &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://www.arthmate.com/helpTopic'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={arthmatelogo}
                                            alt='Arthmate Finance'
                                        />
                                    </a>
                                    <a
                                        href='https://www.arthmate.com/helpTopic'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Arthmate &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a
                                        href='https://ratnaafin.com/privacy-policy/'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={ratnaafin}
                                            alt='Ratnaafin'
                                        />
                                    </a>
                                    <a
                                        href='https://ratnaafin.com/privacy-policy/'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Ratnaafin &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a href='https://www.idfcfirstbank.com/privacy-policy'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={idfclogo}
                                            alt='Idfc First Bank'
                                        />
                                    </a>
                                    <a
                                        href='https://www.idfcfirstbank.com/privacy-policy'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Idfc First Bank &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a href='https://www.yesbank.in/privacy-policy'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={yeslogo}
                                            alt='Yes Bank'
                                        />
                                    </a>
                                    <a
                                        href='https://www.yesbank.in/privacy-policy'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Yes Bank &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a href='https://www.dcbbank.com/PRIVACY-POLICY'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={dcblogo}
                                            alt='DCB Bank'
                                        />
                                    </a>
                                    <a
                                        href='https://www.dcbbank.com/PRIVACY-POLICY'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact DCB Bank &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a href='https://www.smfgindiacredit.com/'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={smfglogo}
                                            alt='SMFG'
                                        />
                                    </a>
                                    <a
                                        href='https://www.smfgindiacredit.com/'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact SMFG &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4'>
                            <div className={classes.lender_logo_wrap}>
                                <div className={classes.lender_logo}>
                                    <a href='https://www.miraeassetfin.com/privacy-policy.html'
                                        target='_blank'
                                    >
                                        <img
                                            className='lender_logo_img'
                                            src={miraelogo}
                                            alt='Mirae'
                                        />
                                    </a>
                                    <a
                                        href='https://www.miraeassetfin.com/privacy-policy.html'
                                        className={classes.lender_link_btn}
                                        target='_blank'
                                    >
                                        Contact Mirae &nbsp;
                                        <svg
                                            stroke='currentColor'
                                            fill='currentColor'
                                            strokeWidth='0'
                                            viewBox='0 0 512 512'
                                            height='1em'
                                            width='1em'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default LendingPartners;
