import React from 'react';
import Header1 from '../../Components/Common/Header1/Header1';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import classes from './InstituteTermsAndConditions.module.scss';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';

function InstituteTermsAndConditions() {
    return (
        <>
             <CustomHelmet page="instituteTermsofservice" />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.instacHeading}>
                            <span>Terms and Conditions</span>
                        </h1>
                    </div>
                </section>
                <section className="container">
                    <div className={classes.body_section}>
                        <div className={classes.section_title}>
                            INTRODUCTION
                        </div>
                        <p>
                            At GrayQuest, we aim to become the default method of
                            paying education fees for the 200Mn+ students
                            studying in private sector education institutions by
                            providing a convenient, flexible and rewarding
                            experience to our customers.
                        </p>
                        <p>
                            GrayQuest offers a host of financing , payments and
                            auto debit products that are helping make payments
                            and collections easier for education institutes. It
                            offers both APIs and dashboards that makes
                            collection easier to track and eliminate the need to
                            do reconciliation. It's more all this as a
                            integrated check out easier for the parents to make
                            informed choices for making any fee payments.
                        </p>
                        <div className={classes.section_title_header}>
                            Terms and Conditions
                        </div>
                        <p>
                            The Terms and Conditions/Terms of Use contained
                            hereinafter &#40;“Terms”&#41; shall apply to the users of
                            the website , GrayQuest applications and any other
                            linked pages, products, software’s, API&#40;s&#41;,
                            features, content or application services
                            &#40;“Services”&#41; in connection therewith offered from
                            time to time &#40;collectively referred to as
                            “Platform”/ “Website”&#41; by GrayQuest Education
                            Finance Pvt &#40;“GrayQuest”, or “we” or “our” or “us”&#41;{' '}
                        </p>
                        <p>
                            Any individual or business &#40;“you” or “Merchant”&#41;,
                            logging in to or using the Website &#40;even when such
                            person does not avail of any services provided in
                            the Website&#41; shall be presumed to have read and
                            unconditionally and irrevocably accepted the Terms
                            set herein for usage of Services of GrayQuest. By
                            continuing to use the Website, you are legally bound
                            by these Terms and if you do not agree to any of the
                            conditions listed below then please do not use the
                            platform or Services of GrayQuest any further. The
                            purpose of these Terms, the following words and
                            phrases shall have the meaning assigned under this
                            article.
                        </p>
                        <div className={classes.section_title}>Definitions</div>
                        <ul className={classes.innerListDisc}>
                            <li>
                                “Acquiring Bank” shall mean a bank or financial
                                institution that processes credit or debit card
                                payments on behalf of any Merchant.
                            </li>
                            <li>
                                “Authentication” shall mean the process by which
                                Customer identification is authenticated in the
                                Processing Mechanism.
                            </li>
                            <li>
                                “Application Form” shall mean an application
                                submitted by the Merchant to GrayQuest for
                                availing the Services which shall include the
                                data shared by the You while logging in the
                                Platform of GrayQuest and filling in the
                                requisite data/details for the purpose of
                                availing the Services all such data or details
                                whether given in parts either in pull or push
                                mode shall be considered as integral part of the
                                Application Form.
                            </li>
                            <li>
                                “Authorization” shall mean the process by which
                                the availability of requisite balances / limit
                                &#40;to conduct the transaction requested&#41; and
                                approval of the Direct Debit Service Providers
                                on the Customer’s Debit Card or Bank Account is
                                established in the Processing Mechanism.
                            </li>
                            <li>
                                “Chargeback” shall mean an approved and settled
                                Transaction that an Issuer, upon receiving a
                                chargeback request from a Customer, reverses to
                                a Payment Service Provider for the Transaction
                                value to be ultimately reversed to the Customer,
                                subject to the Merchant being unable to provide
                                an explanation along with documentary evidence
                                as to why the chargeback request should be
                                rejected in accordance with applicable laws.
                            </li>
                            <li>
                                “Customer Payment Amount” shall mean the total
                                amount paid by the Customer towards the Merchant
                                Services which shall include, inter alia,
                                charges and other taxes, duties, costs, charges,
                                GrayQuest Fees and expenses in respect of the
                                Merchant Services.
                            </li>
                            <li>
                                “Customer” shall mean any person or entity who
                                is buying a product or service from a
                                third-party website &#40;Merchant’s website&#41;, mobile
                                application and is paying using GrayQuest
                                payment instruments.
                            </li>
                            <li>
                                “Data Protection Law” means all applicable
                                statutes and regulations pertaining to the
                                processing of personal data, including but not
                                limited to the privacy and security of personal
                                data.
                            </li>
                            <li>
                                “Fees or Charges” shall mean such fee that has
                                been agreed between the Parties payable by the
                                Merchant to GrayQuest or directly chargeable by
                                GrayQuest to the Customer of the Merchant for
                                GrayQuest Services, and as detailed in LOI
                                &#40;Letter of Intent&#41; hereto.
                            </li>
                            <li>
                                “Issuing bank” shall mean a bank that issues and
                                authorizes the card or net banking privilege to
                                its customers.
                            </li>
                            <li>
                                “Merchant” or “User” shall mean any person or
                                business entity using the services or products
                                of GrayQuest private limited and uses
                                application, products, APIs and collects/
                                disburses payments to an end customer.
                            </li>
                            <li>
                                “Merchant Website” shall mean the online
                                platform &#40;with the domain name as stated in the
                                Application Form/ Legal Agreement &#40;if any&#41; which
                                is established by the Merchant for the purposes
                                of enabling the Customers to carry out
                                Transactions for availing Services.
                            </li>
                            <li>
                                “Merchant Services” shall mean the services or
                                products provided by the Merchant to its
                                Customer/s, the remittance/payment for which is
                                to be made through the Customer’s valid accounts
                                / card/s or bank account, using GrayQuest’s
                                Platform.
                            </li>
                            <li>
                                “Nodal Account” shall mean an intermediary
                                account governed and operated by the acquiring
                                bank and that is directed for merchant
                                settlements by GrayQuest Private Limited. All
                                guidelines of this account are established under
                                the “Payments and settlements Act, 2007”, stated
                                by Reserve Bank of India &#40;RBI&#41;.
                            </li>
                            <li>
                                “Payment Gateway Network” shall mean the
                                hardware, software, platform of GrayQuest and
                                telecommunications tools necessary to perform
                                protocol conversion between different networks
                                or applications and all associated software
                                required for the Merchant to submit
                                Authorization and data capture transactions to
                                the payment service providers and to transmit
                                Authorization and settlement transactions
                                between the Merchant and the payment service
                                providers.
                            </li>
                            <li>
                                “Payment Gateway” shall mean the protocol that
                                may be stipulated by GrayQuest to enable the
                                Authentication of Customers and the
                                Authorization of payments on valid cards in
                                accordance with the Processing Mechanism.
                            </li>
                            <li>
                                “Personal Data” shall mean personal data as may
                                be defined under Data Protection Law.
                            </li>
                            <li>
                                “Products and Services” shall mean the services
                                listed on GrayQuest including the services
                                mentioned over the website or related pages of
                                https://grayquest.com/
                            </li>
                            <li>
                                “Terms and Conditions” shall mean these terms
                                and conditions under this Agreement, and any and
                                all schedules, appendices, annexure and exhibits
                                attached to it and includes any addition,
                                modification, amendment, addendum or deletion
                                thereof agreed to in writing by the Parties.
                            </li>
                            <li>
                                “Token” shall mean the string of randomly
                                generated numbers which shall be unique for each
                                combination of card generated through
                                Tokenization
                            </li>
                            <li>
                                “User Account” shall mean the account created by
                                any individual or business or entity by
                                successfully signing up on the Website to avail
                                the Services provided by GrayQuest.
                            </li>
                        </ul>
                        <div className={classes.section_title}>Eligibility</div>
                        <ol className={classes.innerListDecimal}>
                            <li>
                                You affirm that you are at least Eighteen years
                                of age and are fully able and competent to
                                understand the terms, conditions, obligations,
                                affirmations, representations, and warranties
                                set forth in these terms, and to abide by and
                                comply with these terms.
                            </li>
                            <li>
                                You affirm that you are eligible to contract
                                under applicable laws and you are further
                                authorized under law to avail our Services, the
                                details of which you have provided in our
                                Website.
                            </li>
                            <li>
                                If you are using or accessing the Website as a
                                representative of any person/entity, you
                                acknowledge that you are legally authorized to
                                represent such person/entity.
                            </li>
                            <li>
                                You represent and warrant that You have full
                                power and authority for using the Services and
                                is in strict compliance of all laws.
                            </li>
                            <li>
                                These Terms as accepted by you shall be read
                                along with and in addition to the laws in force
                                from time to time.
                            </li>
                            <li>
                                You agree to use the Services and this Website
                                only if you accept and abide by all of the
                                following Terms.
                            </li>
                        </ol>
                        <div className={classes.section_title}>
                            Sign up and Registration
                        </div>
                        <ul className={classes.innerListDisc}>
                            <li>
                                In order to avail the Services, You must be
                                registered with GrayQuest.
                            </li>
                            <li>
                                You shall provide GrayQuest with all the
                                necessary documents as required by GrayQuest for
                                successful identification, registration and
                                verification.
                            </li>
                            <li>
                                By registering with GrayQuest, you agree to
                                provide true, accurate, current and complete
                                information, failing which, GrayQuest reserves
                                the right to, at any point of time, reject the
                                your registration and terminate his right to use
                                or access the Website and/or avail the Services.
                            </li>
                            <li>
                                We may collect the following information:
                                <ul className={classes.innerListDisc}>
                                    <li>
                                        Name, Trade Name, Brand Name, Legal
                                        Name, etc.
                                    </li>
                                    <li>
                                        Contact information including but not
                                        limited to email address, identity
                                        proof, address proof required for the
                                        verification of your identity and/or
                                        ownership of a product/item.
                                    </li>
                                    <li>
                                        Contract information including address,
                                        shipping and billing details and any
                                        other information provided by you while
                                        making any purchase on our website.
                                    </li>
                                    <li>
                                        User Information, user activity,
                                        Sensitive personal data such as
                                        financial information including but not
                                        limited to details of credit cards,
                                        debit cards, bank account and know your
                                        customer &#40;KYC&#41; documents as per RBI
                                        regulations and other information as may
                                        be applicable.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                When you register with GrayQuest, a User Account
                                shall be created, that is associated with Your
                                username, password, and other such identifying
                                information as provided by You.
                            </li>
                        </ul>
                        <div className={classes.section_title}>
                            Use of Services
                        </div>
                        <ul className={classes.innerListDisc}>
                            <li>
                                The Services may be used by You for making and
                                receiving payments from/to third parties.
                            </li>
                            <li>
                                GrayQuest reserves the right but shall have no
                                obligation to verify the authenticity of the
                                Transaction other than by means of verification
                                of your basic KYC information.
                            </li>
                            <li>
                                You shall at all times take all appropriate
                                steps, including those as mentioned herein, to
                                maintain the security and confidentiality of the
                                information.
                            </li>
                            <li>
                                GrayQuest shall not be liable for any mistake or
                                misuse of the services by either You or by any
                                person authorized by You, or by any person
                                gaining access to the services through You.
                            </li>
                            <li>
                                You agree that GrayQuest accepts all
                                instructions originating from his account in
                                good faith and in a manner consistent with
                                commercially reasonable security standards.
                            </li>
                            <li>
                                You hereby agree to indemnify and hold GrayQuest
                                harmless for direct or indirect losses sustained
                                as a result of the disclosure of sensitive
                                information by or through You.
                            </li>
                            <li>
                                You can receive payments from the customers or
                                other payers or suppliers by
                                <ul className={classes.innerListDisc}>
                                    <li>
                                        IMPS/ NEFT/ RTGS or
                                    </li>
                                    <li>
                                        Credit/ Debit cards or
                                    </li>
                                    <li>
                                        Netbanking and or
                                    </li>
                                    <li>
                                        UPI both for collections and payables
                                    </li>
                                    <li>
                                        Any other modes of payment through banks
                                        that may be accepted by GrayQuest from
                                        time to time under these Terms
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Confirmation of the transaction performed using
                                valid login credentials shall be conclusive
                                evidence of a transaction being affected.
                            </li>
                            <li>
                                You shall be responsible to provide GrayQuest
                                with the appropriate and current customer
                                information.
                            </li>
                            <li>
                                In the event, wherein a Customer wishes to raise
                                a complaint about any transaction, the same
                                should be communicated to GrayQuest within 10
                                (Ten) days of such transaction.
                            </li>
                        </ul>
                        <div className={classes.section_title}>Chargebacks</div>
                        <ul className={classes.innerListDisc}>
                            <li>
                                A Chargeback shall mean a Transaction that is
                                uncollectible and returned to the acquiring
                                bank/ GrayQuest by customer or issuing bank for
                                a refund to the concerned Customer due to any of
                                the following reasons:
                                <ul className={classes.innerListDisc}>
                                    <li>
                                        Any charge/debit arising out of any
                                        alleged hacking breach of security or
                                        encryption that may be utilized by
                                        GrayQuest/ Customer from time to time.
                                    </li>
                                    <li>
                                        Any Transaction that is unsuccessful.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                If the Customer wishes to attempt to chargeback/
                                retract a payment, he must notify the respective
                                bank and GrayQuest immediately and provide all
                                required information. GrayQuest does however
                                guarantee that the payment will be retracted
                            </li>
                            <li>
                                As a result of the rapid nature of movements of
                                funds, once a payment has been initiated through
                                the Services, it may not be possible to retract
                                the same using the Services.
                            </li>
                            <li>
                                GrayQuest shall not be responsible to make
                                payments in respect of any Chargeback unless it
                                has received the requisite amount from the
                                concerned acquiring bank/ Service Provider/
                                Payee/User.
                            </li>
                            <li>
                                In the event that GrayQuest receives a
                                Chargeback amount from the acquiring bank/
                                Service Provider/ You, GrayQuest is entitled to
                                pass on the amount received to the User/Payer
                                within 7 days of receipt.
                            </li>
                        </ul>
                        <div className={classes.section_title}>
                            Refunds & Cancellation:
                        </div>
                        <ul className={classes.innerListDisc}>
                            <li>
                                We follow a Clear No refund policy for all the
                                payment made on the GrayQuest Platform
                            </li>
                            <li>
                                If in case on Our Discretion any refund is given
                                then, transaction charges will be not be
                                reversed and refunds initiated will be credited
                                to the account/card only from where the
                                transaction had initiated via Payment Gateway.
                            </li>
                        </ul>
                        <div className={classes.section_title}>Settlement</div>
                        <ol className={classes.innerListDecimal}>
                            <li>
                                In consideration of the Services rendered by
                                GrayQuest Private limited, a User/You agree to
                                pay a non-refundable fee &#40;apart from Chargebacks
                                under Clause 6&#41; which shall be in consideration
                                of all products, platform, APIs and other
                                related financial services that have been listed
                                on GrayQuest Website and mobile application.
                            </li>
                            <li>
                                You shall pay to GrayQuest, a non-refundable,
                                one time set up fee and/or the Fee as detailed
                                in the Application Form or Platform while
                                signing up for GrayQuest Services.
                            </li>
                            <li>
                                The Fee may be revised periodically and at such
                                intervals as are intimated by GrayQuest to
                                Merchant.
                            </li>
                            <li>
                                You/ User shall receive an invoice from
                                GrayQuest private limited with GST details to
                                showcase the fees being levied and charged as
                                per agreement and pricing agreed between the
                                user and GrayQuest.
                            </li>
                        </ol>
                        <div className={classes.section_title}>Termination</div>
                        <ul className={classes.innerListDisc}>
                            <li>
                                In the event that GrayQuest suspects, on
                                reasonable grounds, that You or User has
                                committed a breach of these Terms or any other
                                agreement with GrayQuest or any act of
                                dishonesty or fraud against GrayQuest/ any
                                Service Provider, GrayQuest shall be entitled to
                                <ul className={classes.innerListDisc}>
                                    <li>
                                        Suspend all payment under these Terms;
                                    </li>
                                    <li>
                                        Deactivate/ suspend your User Account
                                        and disable your password; and
                                    </li>
                                    <li>
                                        Terminate User’s access to the Website
                                        or the Services; pending enquiries by
                                        GrayQuest.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                GrayQuest reserves the complete rights to hold
                                any pay-out to You/ User and also refund amounts
                                based on complaints received from acquiring or
                                issuing bank or any related person to the
                                government of India which includes Cyber cell,
                                Police, Reserve bank of India, SEBI, etc
                            </li>
                        </ul>
                        <div className={classes.section_title}>
                            Confidentiality and Non-disclosure Agreement
                        </div>
                        <ol className={classes.innerListDecimal}>
                            <li>
                                In connection with these Terms, GrayQuest may
                                disclose to you and or you may otherwise receive
                                some sensitive information of GrayQuest
                                &#40;collectively, confidential information&#41;.
                            </li>
                            <li>
                                You shall not use any confidential information
                                for the purpose of soliciting, or to permit to
                                solicit, users to subscribe to any other
                                services or promote the sale of any products
                                that compete directly or indirectly, with
                                GrayQuest or the functionality or services
                                offered by the platform.
                            </li>
                            <li>
                                You hereby agree that GrayQuest may be required
                                to provide to governmental agencies/Law or other
                                third parties, information in its possession
                                regarding you or the business you conduct with
                                GrayQuest or via the Platform.
                            </li>
                        </ol>
                        <div className={classes.section_title}>
                            Indemnification and Remedy
                        </div>
                        <ol className={classes.innerListDecimal}>
                            <li>
                                You shall keep GrayQuest indemnified from and
                                against any and all liability &#40;including but not
                                limited to liabilities, judgments, damages,
                                losses, claims, costs and expenses,&#41; or any
                                other loss that may occur, arising from or
                                relating to any claim, suit or proceeding
                                brought against GrayQuest by another User/
                                Service Provider/ third party for reasons
                                including, but not limited to &#40;i&#41; delivery,
                                non-delivery or delay, deficiency or mistake in
                                respect of the Products sold; &#40;ii&#41; a breach,
                                non-performance, non-compliance or inadequate
                                performance by the User of any of the terms,
                                conditions, representations, obligations or
                                warranties made by him; &#40;iii&#41; any acts, errors,
                                misrepresentations, wilful misconduct or
                                negligence of the User, or his employees,
                                subcontractors and agents in performance of
                                their obligations under these Terms; &#40;iv&#41; breach
                                and/or any violation by You which is penalized
                                by any banks or regulators.
                            </li>
                            <li>
                                The User shall comply with all such terms and
                                conditions as GrayQuest or any Service Provider
                                may prescribe from time to time with regard to
                                the Services.
                            </li>
                            <li>
                                All Transactions effected by or through this
                                Website, shall constitute legally binding and
                                valid transactions when done in adherence to and
                                in compliance with the terms and conditions
                                prescribed by GrayQuest or Seller/ Service
                                Provider.
                            </li>
                            <li>
                                This indemnification clause will survive
                                termination, modification or expiration.
                            </li>
                        </ol>
                        <div className={classes.section_title}>
                            Governing Laws
                        </div>
                        <ol className={classes.innerListDecimal}>
                            <li>
                                These terms shall be governed and construed in
                                accordance with the rules and regulations of the
                                Republic of India.
                            </li>
                            <li>
                                Any dispute arising out of the formation,
                                performance, interpretation, nullification,
                                termination or invalidation of this policy or
                                arising therefrom or related thereto in any
                                manner whatsoever, shall be settled by
                                arbitration in accordance with the provisions
                                set forth under the Arbitration &amp;
                                Conciliation Act, 1996 &#40;the “Rules”&#41;, by one
                                arbitrator appointed in compliance with the
                                Rules.
                            </li>
                            <li>
                                The seat of Arbitration shall be in Mumbai,
                                Maharashtra and the decision of the sole
                                arbitrator shall be final and binding upon the
                                parties to the dispute.
                            </li>
                        </ol>
                        <div className={classes.section_title}>Grievances</div>
                        <p>
                            Any complaints or concerns with regards to content
                            of this Website or comment or breach of these Terms
                            of Use or any intellectual property of any User,
                            instances of customer grievances, regulatory queries
                            and clarifications shall be informed/ communicated
                            to the Grievance cum Nodal Officer at the
                            co-ordinates mentioned below in writing:
                        </p>
                        <p className={classes.instacBottom}>nodalofficer@GrayQuest.com </p>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default InstituteTermsAndConditions;
