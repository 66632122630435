import React from 'react';
import mask1 from '../../../assets/images/home/mask1.png';
import mask2 from '../../../assets/images/home/mask2.png';
import mask3 from '../../../assets/images/home/mask3.png';
import mask4 from '../../../assets/images/home/mask4.png';
import mask5 from '../../../assets/images/home/mask5.png';
import mask6 from '../../../assets/images/home/mask6.png';
import mask7 from '../../../assets/images/home/mask7.png';
import mask8 from '../../../assets/images/home/mask8.png';
import mask9 from '../../../assets/images/home/mask9.png';
import mask10 from '../../../assets/images/home/mask10.png';
import mask11 from '../../../assets/images/home/mask11.jpg';
import mask12 from '../../../assets/images/home/mask12.png';
import mask13 from '../../../assets/images/home/mask13.png';
import mask14 from '../../../assets/images/home/mask14.png';
import Slider2 from '../Slider2/Slider2';
import './Slider.css';

function SliderComp(props) {
    const data1 = [mask1, mask2, mask3, mask4, mask5, mask6, mask7];
    const data2 = [mask8, mask9, mask10, mask11, mask12, mask13, mask14];

    return (
        <>
            <section id='SliderComp' className='bg--withPadding'>
                <div className='container'>
                    <div className='partner-slider'>
                        <div className='content-box'>
                            <div className='title-box'>
                                <h2 className='title'>
                                    {props.theme === 'partner' ? (
                                        <>
                                            {' '}
                                            <span className='text--blueGradient text-bl'>
                                                6500+ of India’s most respected
                                                educational institutions use GQ{' '}
                                            </span>
                                            <span className='text-bl'>
                                                To power fee payments for 5M+
                                                students
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            <span className='text--blueGradient'>
                                                Proudly featured{' '}
                                            </span>
                                            In media
                                        </>
                                    )}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='displayInstituteLogos1'>
                    {[...data1, ...data2].map((img, index) => {
                        return (
                            <div key={index}>
                                <img
                                    src={img}
                                    alt='institute-logo'
                                    width='165px'
                                    height='75px'
                                />
                            </div>
                        );
                    })}
                </div>
            </section>

            <div id='MobileSliderComp'>
                {/* <div className="partner-static"> */}
                <div className='content-box-static'>
                    <div className='title-box-static'>
                        <h2 className='static-title'>
                            {props.theme === 'partner' ? (
                                <>
                                    {' '}
                                    <span
                                        id='sm-title-gradient'
                                        className='text--blueGradient text-bl'
                                    >
                                        In partnership{' '}
                                    </span>
                                    <span id='sm-title' className='text-bl'>
                                        with India’s top educational
                                        institutions
                                    </span>
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <span className='text--blueGradient'>
                                        Proudly featured{' '}
                                    </span>
                                    In media
                                </>
                            )}
                        </h2>
                    </div>
                    <div id='displayInstituteLogos' className='static-row'>
                        {[...data1, ...data2].map((img, index) => {
                            return (
                                <div key={index}>
                                    <div className='static-logo'>
                                        <img
                                            src={img}
                                            alt='institute-logo'
                                            width='100px'
                                            height='50px'
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SliderComp;
