import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { GiDiamonds } from 'react-icons/gi';
import bannerImg from '../../assets/images/about/bannerImg.webp';
import tio from '../../assets/images/about/yourstory.png';
import entracker from '../../assets/images/about/entracker.png';
import BWDisrupt from '../../assets/images/about/BWDisrupt.png';
// team
import RishabMehta from '../../assets/images/about/team/rishab_mehta.png';
import RishiKapoorImage from '../../assets/images/about/team/rishi_kapoor.png';
import NikunjDoshiImage from '../../assets/images/about/team/nikunj_doshi.png';
import DeepenSaha from '../../assets/images/about/team/deepen_saha.png';
import RanjeetSingh from '../../assets/images/about/team/ranjeet_singh.png';
import GarvitMathur from '../../assets/images/about/team/garvit_mathur.png';
import SagarTiwarekar from '../../assets/images/about/team/sagar_tiwarekar.png';
import LeisannePintoImage from '../../assets/images/about/team/leisanne_pinto.png';
import JugashrastaDashImage from '../../assets/images/about/team/jugashastra_das.png';
import ShrijitMenonImage from '../../assets/images/about/team/shrijit_menon.png';
import AnishDantuImage from '../../assets/images/about/team/anish_dantu.png';

import smallArrowDown from '../../assets/images/about/smallArrowDown.png';
import yellowStar2 from '../../assets/images/about/yellowStar2.png';
import credcrop from '../../assets/images/about/credcrop.png';
import careing from '../../assets/images/about/careing.png';
import humble from '../../assets/images/about/humble.png';
import empowering from '../../assets/images/about/empowering.png';
import trustworthy from '../../assets/images/about/trustworthy.png';
import aim from '../../assets/images/about/aim.png';
import farsighted from '../../assets/images/about/farsighted.png';

// Investors
// import pravega from '../../assets/images/about/pravega.png';
import PravegaVenturesLogo from '../../assets/images/about/investors/pravega_ventures_logo.jpeg';
import IIFLLogo from '../../assets/images/about/investors/iifl_logo.jpeg';
import ClayPondCapitalLogo from '../../assets/images/about/investors/claypond_capital_logo.jpeg';

import dabur from '../../assets/images/about/dabur.png';
import pidilite from '../../assets/images/about/pidilite.png';
import talema from '../../assets/images/about/talema.png';
import alliance from '../../assets/images/about/alliance.png';
import WeizmannImage from '../../assets/images/about/weizmann.png';
import MahansariaImage from '../../assets/images/about/mahanagar.png';
import ambit from '../../assets/images/about/ambit.png';
import KunalShah from '../../assets/images/about/KunalShah.png';
import shaadicrop from '../../assets/images/about/shaadicrop.png';
import oyo from '../../assets/images/about/oyo.png';
import transformImg from '../../assets/images/about/transformImg.png';
import business1 from '../../assets/images/about/business1.png';
import business2 from '../../assets/images/about/business2.png';
import business3 from '../../assets/images/about/business3.png';
import business4 from '../../assets/images/about/business4.png';
import vccircle from '../../assets/images/about/vccircle.png';
import yourstory from '../../assets/images/about/tio.png';
import MitenSampat from '../../assets/images/about/MitenSampat.png';
import { Link, Navigate } from 'react-router-dom';
import PravinJadhav from '../../assets/images/about/PravinJadhav.png';
import AkritVaishImage from '../../assets/images/about/AkritJadhav.jpeg';
import AnupamMittal from '../../assets/images/about/AnupamMittal.png';
import AnujTejpal from '../../assets/images/about/AnujTejpal.png';
import SujeetKumar from '../../assets/images/about/SujeetKumar.png';
import RevantBhate from '../../assets/images/about/RevantBhate.png';
import DhyaneshShah from '../../assets/images/about/DhyaneshShah.png';
import DhanLogoImage from '../../assets/images/about/dhan.png';
import JioHaptikLogo from '../../assets/images/about/jio_haptik_logo.png';
import udaan from '../../assets/images/about/udaan.png';
import mosaic from '../../assets/images/about/mosaic.png';
import AbhinavSinha from '../../assets/images/about/AbhinavSinha.png';
import Footer1 from '../Common/Footer1/Footer1';
import { useNavigate } from 'react-router-dom';

import cnbcLogo from '../../assets/images/about/cnbcLogo.png';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { AiOutlineFullscreenExit } from 'react-icons/ai';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../AboutUs/AboutUs.css';
import NewSlider from '../NewSlider/NewSlider';
import Header1 from '../Common/Header1/Header1';
import { useState } from 'react';
import CustomHelmet from '../CustomHelmet/CustomHelmet';

function AboutUs() {
    const [toShowMore, setToShowMore] = useState(false);

    const navigate = useNavigate();
    let toggleFun = (e) => {
        let lap = e.target
            .closest('.otherFounder_inner')
            .classList.contains('activedata');
        if (lap) {
            e.target
                .closest('.otherFounder_inner')
                .classList.remove('activedata');
        } else {
            e.target.closest('.otherFounder_inner').classList.add('activedata');
        }
    };
    const data = [
        business1,
        business2,
        business3,
        business4,
        business1,
        business2,
    ];
    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };
    var settings = {
        dots: true,
        infinite: 4,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    initialSlide: 0,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                    infinite: true,
                    initialSlide: 0,
                    dots: true,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                    infinite: true,
                    initialSlide: 1,
                    dots: true,
                },
            },
        ],
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <CustomHelmet page="about" />
            <div className='about-us-comp'>
                <Box className='backgroundContainer'>
                    <Box className='bannerSection'>
                        <Box className='bannerContainer'>
                            <Header1 theme={'aboutUs'} />
                            <Box className='bannerTxt'>
                                <Box className='diamond'>
                                    <GiDiamonds />
                                    <h6>Introducing GQ</h6>
                                </Box>
                                <h1>
                                    We aim to <span>empower education</span> for
                                    every child with easy fee payments.
                                </h1>

                                <Box className='journeyData_outer'>
                                    <Box className='journeyData_inner'>
                                        <h5>6500+</h5>
                                        <span>Partnered institutes</span>
                                    </Box>

                                    <Box className='journeyData_inner teamMember'>
                                        <h5>300+</h5>
                                        <span>Team members</span>
                                    </Box>

                                    <Box className='journeyData_inner'>
                                        <h5>40+</h5>
                                        <span>Investor family</span>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className='bannerContainer_outer'>
                                <Box className='bannerImage'>
                                    {' '}
                                    <img src={bannerImg} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* /****Our Founder*****/}

                    <Box className='ourFounderSection'>
                        {/* /********** */}
                        <Box id='team' className='otherFounderContainer'>
                            <Box className='otherFounder_outer'>
                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={RishabMehta} />

                                        <a
                                            href='https://www.linkedin.com/in/rishab-mehta-b2963357/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Rishab Mehta</h5>
                                    <h6>CEO & Founder</h6>
                                </Box>
                                
                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={RishiKapoorImage} />
                                        <a
                                            href='https://www.linkedin.com/in/rishi-kapoor-17687076/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Rishi Kapoor</h5>
                                    <h6>Chief of Staff</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={NikunjDoshiImage} />
                                        <a
                                            href='https://www.linkedin.com/in/nikunj-doshi-7477a084'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Nikunj Doshi</h5>
                                    <h6>Chief Business Officer</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={DeepenSaha} />
                                        <a
                                            href='https://www.linkedin.com/in/deepensaha/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Deepen Saha</h5>
                                    <h6>Engineering</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={RanjeetSingh} />
                                        <a
                                            href='https://www.linkedin.com/in/ranjeetrawat/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Ranjeet Singh</h5>
                                    <h6>Risk</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={GarvitMathur} />
                                        <a
                                            href='https://www.linkedin.com/in/garvit-mathur-73a896155/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Garvit Mathur</h5>
                                    <h6>Operations</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={SagarTiwarekar} />
                                        <a
                                            href='https://www.linkedin.com/in/sagartiwarekar/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Sagar Tiwarekar</h5>
                                    <h6>Alliance and Integration</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={LeisannePintoImage} />
                                        <a
                                            href='https://www.linkedin.com/in/leisanne-pinto-1b8a76111/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Leisanne Pinto</h5>
                                    <h6>People & Culture</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={JugashrastaDashImage} />
                                        <a
                                            href='https://www.linkedin.com/in/jugashrasta-dash-011626b6/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Jugashrasta Dash</h5>
                                    <h6>Business</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={ShrijitMenonImage} />
                                        <a
                                            href='https://www.linkedin.com/in/shrijitmenon/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Shrijit Menon</h5>
                                    <h6>Business</h6>
                                </Box>

                                <Box className='otherFounder_inner'>
                                    <Box className='subourFounderImg'>
                                        <img src={AnishDantuImage} />
                                        <a
                                            href='https://www.linkedin.com/in/anish-dantu-39b003102/'
                                            target='_blank'
                                            className='linkedInLogo'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='30'
                                                height='30'
                                                fill='currentColor'
                                                className='bi bi-linkedin'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                                            </svg>
                                        </a>
                                    </Box>
                                    <h5>Anish Dantu</h5>
                                    <h6>Business</h6>
                                </Box>

                                <Box className='otherFounder_inner'></Box>
                            </Box>
                        </Box>
                    </Box>

                    {/**Our Mission /*/}

                    <div id='ourMissoinWrapper'>
                        <div className='ourMissionSection'>
                            <div
                                id='ourMisssion'
                                className='ourMissionContainer'
                            >
                                <div id='flexImage' className='ourMissionTxt'>
                                    <div
                                        id='flexColoumn'
                                        className='ourMissionHead'
                                    >
                                        <div id='manageWidth'>
                                            <div
                                                className='adjustPosition'
                                                style={{ maxWidth: '23px' }}
                                            >
                                                <img src={yellowStar2} />
                                            </div>
                                            <h4 className='ourMission'>
                                                OUR MISSION
                                            </h4>
                                        </div>
                                        <p className='ourMission_para'>
                                            It is our mission to become the
                                            default method of paying education
                                            fees for the 200M+ students studying
                                            in educational institutions
                                        </p>
                                    </div>
                                </div>

                                <div id='backgroundGradiant'>
                                    <img src='https://gq-public-assets.s3.ap-south-1.amazonaws.com/images/gqlanding_website/about/parentChildImage.png' />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** our Services */}
                    <Box className='ourServicesContaier'>
                        <Box className='ourServices_outer'>
                            <Box className='ourServices_innerTxt'>
                                <h4>
                                    <span>Our values</span> drive us to do
                                    better!
                                </h4>
                                <p>
                                    At GrayQuest, we don’t just offer a fee
                                    management and payment platform. We empower
                                    the future of our children.
                                </p>
                                <Link to='/careers'>
                                    <button className='joinBtn'>
                                        {' '}
                                        Join us
                                    </button>
                                </Link>
                            </Box>

                            <Box className='ourServices_imgContainer'>
                                <Box className='careingContainer'>
                                    <Box
                                        sx={{
                                            width: '70px',
                                            '@media screen and (max-width: 460px)':
                                                {
                                                    maxWidth: '60px',
                                                },
                                        }}
                                    >
                                        {' '}
                                        <img src={careing} />
                                    </Box>
                                    <h6>Caring</h6>
                                </Box>

                                <Box className='humbleContainer'>
                                    <Box
                                        sx={{
                                            width: '70px',
                                            '@media screen and (max-width: 460px)':
                                                {
                                                    maxWidth: '60px',
                                                },
                                        }}
                                    >
                                        {' '}
                                        <img src={humble} />
                                    </Box>
                                    <h6>Humble</h6>
                                </Box>

                                <Box className='empoweringContainer'>
                                    <Box
                                        sx={{
                                            width: '70px',
                                            '@media screen and (max-width: 460px)':
                                                {
                                                    maxWidth: '54px',
                                                },
                                        }}
                                    >
                                        {' '}
                                        <img src={empowering} />
                                    </Box>
                                    <h6>Empowering</h6>
                                </Box>

                                <Box className='trustworthyContainer'>
                                    <Box
                                        sx={{
                                            width: '70px',
                                            '@media screen and (max-width: 460px)':
                                                {
                                                    maxWidth: '61.8px',
                                                },
                                        }}
                                    >
                                        {' '}
                                        <img src={trustworthy} />
                                    </Box>
                                    <h6>Trustworthy</h6>
                                </Box>
                                <Box className='aspirationalContainer'>
                                    <Box
                                        sx={{
                                            width: '70px',
                                            '@media screen and (max-width: 460px)':
                                                {
                                                    maxWidth: '55px',
                                                },
                                        }}
                                    >
                                        {' '}
                                        <img src={aim} />
                                    </Box>
                                    <h6>Aspirational</h6>
                                </Box>

                                <Box className='farsightedContainer'>
                                    <Box
                                        sx={{
                                            width: '70px',
                                            '@media screen and (max-width: 460px)':
                                                {
                                                    maxWidth: '64px',
                                                },
                                        }}
                                    >
                                        {' '}
                                        <img src={farsighted} alt='' />
                                    </Box>
                                    <h6>Far-sighted</h6>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/********* Marquee Investor ************* */}
                    <Box className='marquorSectioneeInvert'>
                        <Box className='marqueeInversorContainer'>
                            <h2>
                                Our marquee <span>investors</span>
                            </h2>

                            <Box className='institutionalInvestor_outer'>
                                <h4>
                                    Institutional investors & family offices
                                </h4>
                                <Box className='institutionalOfficesContainer'>
                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '152px',
                                                width: '100%',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '90px',
                                                    },
                                            }}
                                        >
                                            <img src={IIFLLogo} alt='' />
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '80%',
                                                width: '100%',
                                                textAlign: 'center',
                                                fontSize: '1.375rem',
                                                fontWeight: '600',
                                                userSelect: 'none',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '90%',
                                                    },
                                            }}
                                        >
                                            {/* <img src={ClayPondCapitalLogo} alt='' /> */}
                                            <p>Claypond Capital</p>
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '120px',
                                                width: '100%',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '72px',
                                                    },
                                            }}
                                        >
                                            <img src={PravegaVenturesLogo} alt='' />
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '76px',
                                                width: '100%',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '48px',
                                                    },
                                            }}
                                        >
                                            <img src={dabur} />
                                        </Box>
                                    </Box>
                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '122px',
                                                width: '100%',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '76px',
                                                    },
                                            }}
                                        >
                                            <img src={pidilite} />
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '65px',
                                                width: '100%',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '43px',
                                                    },
                                            }}
                                        >
                                            <img src={talema} />
                                        </Box>
                                    </Box>

                                    {/* /***************** */}

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '200px',
                                                width: '100%',
                                            }}
                                        >
                                            <img src={ambit} />
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '140px',
                                                width: '100%',
                                                '@media screen and (max-width: 768px)':
                                                    {
                                                        maxWidth: '88px',
                                                    },
                                            }}
                                        >
                                            <img src={alliance} />
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                        // sx={{
                                        //     maxWidth: '140px',
                                        //     width: '100%',
                                        //     '@media screen and (max-width: 768px)':
                                        //         {
                                        //             maxWidth: '88px',
                                        //         },
                                        // }}
                                        >
                                            <img src={WeizmannImage} />
                                        </Box>
                                    </Box>

                                    <Box className='institutionalOffices_outer'>
                                        <Box
                                            sx={{
                                                maxWidth: '140px',
                                                width: '100%',
                                                // '@media screen and (max-width: 768px)':
                                                //     {
                                                //         maxWidth: '88px',
                                                //     },
                                            }}
                                        >
                                            <img src={MahansariaImage} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className='angelInvestorContainer '>
                                <h3>Angel investors</h3>
                                <Box className ='angelInvestor_outerDesktop'>
                                    <Box className='angelInvestor_inner'>
                                        <Box
                                            sx={{
                                                maxWidth: '245px',
                                                width: '100%',
                                                height: '245px',
                                            }}
                                        >
                                            {' '}
                                            <img src={KunalShah} />
                                        </Box>

                                        <Box>
                                            <Box className='angelInvestor_innerTxt_outer'>
                                                <Box className='angelInvestor_innerTxt_inner'>
                                                    <h5>Kunal Shah</h5>
                                                    <h6>Founder of CRED</h6>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        width: '64px',
                                                        paddingTop: '11px',
                                                    }}
                                                >
                                                    <img src={credcrop} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <Box
                                            sx={{
                                                maxWidth: '245px',
                                                width: '100%',
                                                height: '245px',
                                            }}
                                        >
                                            {' '}
                                            <img src={MitenSampat} />
                                        </Box>
                                        <Box>
                                            <Box className='angelInvestor_innerTxt_outer'>
                                                <Box className='angelInvestor_innerTxt_inner'>
                                                    <h5>Miten Sampat</h5>
                                                    <h6>President at Cred</h6>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '64px',
                                                        paddingTop: '11px',
                                                    }}
                                                >
                                                    <img src={credcrop} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* <Box className='angelInvestor_inner'>
                                        <Box
                                            sx={{
                                                maxWidth: '245px',
                                                width: '100%',
                                                height: '245px',
                                            }}
                                        >
                                            {' '}
                                            <img src={PravinJadhav} />
                                        </Box>
                                        <Box>
                                            <Box className='angelInvestor_innerTxt_outer'>
                                                <Box className='angelInvestor_innerTxt_inner'>
                                                    <h5>Pravin Jadhav</h5>
                                                    <h6>Founder of Dhan</h6>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '68px',
                                                        paddingTop: '11px',
                                                    }}
                                                >
                                                    <img src={DhanLogoImage} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box> */}

                                    <Box className='angelInvestor_inner'>
                                        <Box
                                            sx={{
                                                maxWidth: '245px',
                                                width: '100%',
                                                height: '245px',
                                            }}
                                        >
                                            {' '}
                                            <img src={AkritVaishImage} />
                                        </Box>
                                        <Box>
                                            <Box className='angelInvestor_innerTxt_outer'>
                                                <Box className='angelInvestor_innerTxt_inner'>
                                                    <h5>Akrit Vaish</h5>
                                                    <h6>Co-founder & CEO of Haptik</h6>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '68px',
                                                        paddingTop: '11px',
                                                    }}
                                                >
                                                    <img src={JioHaptikLogo} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <Box
                                            sx={{
                                                maxWidth: '245px',
                                                width: '100%',
                                                height: '245px',
                                            }}
                                        >
                                            {' '}
                                            <img src={AnupamMittal} />
                                        </Box>
                                        <Box className='angelInvestor_innerTxt_outer'>
                                            <Box className='angelInvestor_innerTxt_inner'>
                                                <h5>Anupam Mittal</h5>
                                                <h6>Founder of Shaadi.com</h6>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '62px',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                <img src={shaadicrop} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <Box
                                            sx={{
                                                maxWidth: '245px',
                                                width: '100%',
                                                height: '245px',
                                            }}
                                        >
                                            {' '}
                                            <img src={AnujTejpal} />
                                        </Box>

                                        <Box className='angelInvestor_innerTxt_outer'>
                                            <Box className='angelInvestor_innerTxt_inner'>
                                                <h5>Anuj Tejpal</h5>
                                                <h6>CBDO at OYO</h6>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '61px',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                <img src={oyo} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <img src={AbhinavSinha} />
                                        <Box className='angelInvestor_innerTxt_outer'>
                                            <Box className='angelInvestor_innerTxt_inner'>
                                                <h5>Abhinav Sinha</h5>
                                                <h6>COO & CPO at OYO</h6>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '61px',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                <img src={oyo} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <img src={SujeetKumar} />
                                        <Box className='angelInvestor_innerTxt_outer'>
                                            <Box className='angelInvestor_innerTxt_inner'>
                                                <h5>Sujeet Kumar </h5>
                                                <h6>Co-Founder of Udaan</h6>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '90px',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                <img src={udaan} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <img src={RevantBhate} />
                                        <Box className='angelInvestor_innerTxt_outer'>
                                            <Box className='angelInvestor_innerTxt_inner'>
                                                <h5>Revant Bhate</h5>
                                                <h6>
                                                    Co-Founder & CEO at Mosaic
                                                </h6>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '94px',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                <img
                                                    src={mosaic}
                                                    width='40px'
                                                    height='30px'
                                                    alt=''
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='angelInvestor_inner'>
                                        <img src={DhyaneshShah} />
                                        <Box className='angelInvestor_innerTxt_outer'>
                                            <Box className='angelInvestor_innerTxt_inner'>
                                                <h5>Dhyanesh Shah</h5>
                                                <h6>Co-Founder of Mosaic</h6>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '94px',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                <img src={mosaic} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            {/* /********** Resp ************* */}
                            <Box className='angelInvestorContainerMobile '>
                                <h2 className='SectionTitle'>
                                    Angel investors
                                </h2>
                                <Box className='sliderBox'>
                                    <NewSlider />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/****** Proudy ********/}

                    {/* <Box className='proudySection'>
                        <h2>
                            <span>Proudly featured</span> in the media
                        </h2>
                        <div className='media-container'>
                        <span className='mediaLogos'>
                                <a
                                    className='mediaLogosLink'
                                    href='https://www.cnbctv18.com/startup/grayquest-raises-rs-56-crore-in-series-a-equity-funding-round-16127821.htm'
                                    target='_blank'
                                >
                                    <img
                                        width='80%'
                                        src={cnbcLogo}
                                        alt='cnbcLogo'
                                    />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a
                                    href='https://entrackr.com/2023/03/grayquest-raises-7-mn-in-series-a-round/
'
                                    target='_blank'
                                    className='mediaLogosLink'
                                >
                                    <img
                                        width='80%'
                                        src={entracker}
                                        alt='entracker'
                                    />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a
                                    href='https://yourstory.com/hindi/grayquest-announces-its-series-a-equity-funding-round-of-rs-56crore-7mn-education-fees-fintech
 '
                                    target='_blank'
                                >
                                    <img src={tio} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href=' https://www.vccircle.com/fintechcompany-grayquest-iot-based-probus-others-raise-early-funds
'
                                    target='_blank'
                                >
                                    <img src={vccircle} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://www.financialexpress.com/money/grayquest-this-firm-helps-convert-heavy-school-fees-into-emis-offers-insurance-of-rs-20-lakh-interview/2068499/
'
                                    target='_blank'
                                >
                                    <img src={business1} alt='medialogo' />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a
                                    href='https://mumbaimirror.indiatimes.com/coronavirus/news/pvt-school-fee-takes-the-microloan-route/articleshow/76884336.cms'
                                    target='_blank'
                                >
                                    <img src={business2} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://bfsi.economictimes.indiatimes.com/news/fintech/foundation-holdings-invest-1-2-million-in-fintech-grayquest/77592506'
                                    target='_blank'
                                >
                                    <img src={business3} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://www.moneycontrol.com/news/business/startup/grayquest-raises-1-2-mn-in-pre-series-a-funding-5718151.html'
                                    target='_blank'
                                >
                                    <img src={business4} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://bwdisrupt.businessworld.in/article/GrayQuest-Raises-1-2-Million-In-Pre-Series-A-Funding-Led-By-Foundation-Holdings/18-08-2020-310082/'
                                    target='_blank'
                                >
                                    <img
                                        width='80%'
                                        src={BWDisrupt}
                                        alt='medialogo'
                                    />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://timesofindia.indiatimes.com/increasing-number-of-women-paying-for-kids-education-report/articleshow/81396500.cms
'
                                    target='_blank'
                                >
                                    <img src={yourstory} alt='medialogo' />
                                </a>
                            </span>
                        </div>
                    </Box> */}

                    {/* NEW */}
                    {/* <section>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>TEST </h2>
                            </div>
                        </div>
                    </section> */}

                    {/* <section className='partnersLogContainer'> */}
                    <section className='proudySection'>
                        <div className='title-box'>
                            <h2
                                style={{ textAlign: 'center' }}
                                className='title'
                            >
                                <span className='text--blueGradient'>
                                    Proudly featured{' '}
                                </span>
                                In media
                            </h2>
                        </div>
                        <div className='mediaContainer'>
                            <span className='mediaLogos'>
                                <a
                                    className='mediaLogosLink'
                                    href='https://www.cnbctv18.com/startup/grayquest-raises-rs-56-crore-in-series-a-equity-funding-round-16127821.htm'
                                    target='_blank'
                                >
                                    <img
                                        width='80%'
                                        src={cnbcLogo}
                                        alt='cnbcLogo'
                                    />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a
                                    href='https://entrackr.com/2023/03/grayquest-raises-7-mn-in-series-a-round/
'
                                    target='_blank'
                                    className='mediaLogosLink'
                                >
                                    <img
                                        width='80%'
                                        src={entracker}
                                        alt='entracker'
                                    />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a 
                                    href='https://yourstory.com/hindi/grayquest-announces-its-series-a-equity-funding-round-of-rs-56crore-7mn-education-fees-fintech
 '
                                    target='_blank'
                                >
                                    <img src={tio} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href=' https://www.vccircle.com/fintechcompany-grayquest-iot-based-probus-others-raise-early-funds
'
                                    target='_blank'
                                >
                                    <img src={vccircle} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://www.financialexpress.com/money/grayquest-this-firm-helps-convert-heavy-school-fees-into-emis-offers-insurance-of-rs-20-lakh-interview/2068499/
'
                                    target='_blank'
                                >
                                    <img src={business1} alt='medialogo' />
                                </a>
                            </span>

                            <span className='mediaLogos'>
                                <a
                                    href='https://mumbaimirror.indiatimes.com/coronavirus/news/pvt-school-fee-takes-the-microloan-route/articleshow/76884336.cms'
                                    target='_blank'
                                >
                                    <img src={business2} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://bfsi.economictimes.indiatimes.com/news/fintech/foundation-holdings-invest-1-2-million-in-fintech-grayquest/77592506'
                                    target='_blank'
                                >
                                    <img src={business3} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://www.moneycontrol.com/news/business/startup/grayquest-raises-1-2-mn-in-pre-series-a-funding-5718151.html'
                                    target='_blank'
                                >
                                    <img src={business4} alt='medialogo' />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://bwdisrupt.businessworld.in/article/GrayQuest-Raises-1-2-Million-In-Pre-Series-A-Funding-Led-By-Foundation-Holdings/18-08-2020-310082/'
                                    target='_blank'
                                >
                                    <img
                                        width='80%'
                                        src={BWDisrupt}
                                        alt='medialogo'
                                    />
                                </a>
                            </span>
                            <span className='mediaLogos'>
                                <a
                                    href='https://timesofindia.indiatimes.com/increasing-number-of-women-paying-for-kids-education-report/articleshow/81396500.cms
'
                                    target='_blank'
                                >
                                    <img src={yourstory} alt='medialogo' />
                                </a>
                            </span>
                        </div>
                    </section>

                    <Box className='transformSection'>
                        <Box className='transformContainer'>
                            <Box className='transformTxt'>
                                <h4>
                                    Let’s transform your institute’s
                                    fee-collection process today!
                                </h4>
                                <h5>
                                    Get a step closer to improving your
                                    institute holistically with our payment
                                    platform.
                                </h5>
                                <Box className='transformTxtBtn'>
                                    <Link to='/contact'>
                                        <button className='demoBtn'>
                                            {' '}
                                            Schedule a demo
                                        </button>
                                    </Link>
                                    <div className='ourPorduct'>
                                        {' '}
                                        <a
                                            onClick={() => {
                                                navigate('/institute');
                                            }}
                                        >
                                            Our Products
                                            <div className='productsArrow'>
                                                <img src={smallArrowDown} />
                                            </div>
                                        </a>
                                    </div>
                                </Box>
                            </Box>
                            <Box className='transformImg'>
                                <img src={transformImg} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
            <Footer1 />
        </div>
    );
}

export default AboutUs;
