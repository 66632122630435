import React from 'react';
import Header1 from '../../Components/Common/Header1/Header1';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import classes from './TermsOfService.module.scss';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';

function TermsOfService() {
    return (
        <>
             <CustomHelmet page="termsofservice" />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.touHeading}>
                            <span>Terms Of Use</span>
                        </h1>
                    </div>
                </section>
                <section>
                    <div className={classes.body_section}>
                        <div className='container'>
                            <div className={classes.section_title}>
                                INFORMATION ABOUT USE
                            </div>
                            <p>
                                GrayQuest Education Finance ("GrayQuest") is
                                technology and operating partner to its
                                financing partners. As a service provider,
                                GrayQuest is fully responsible communicating,
                                transacting and processing on behalf of its
                                financing partners. At no point, GrayQuest is
                                representing as a lending company that lends
                                money on it’s own accord. GrayQuest provides
                                services to various banking &amp; financial
                                services companies and Non-banking Financial
                                Companies (NBFC) (collectively referred to as
                                'Lending Partners') as a 'Servicer'. GrayQuest
                                has developed technology for evaluating credit
                                worthiness using customer data collected through
                                loan application and other credit verification
                                documents of the customer. Please refer to your
                                Loan Agreement for details on your contract with
                                the Financing Partner.
                            </p>
                            <div className={classes.section_title}>
                                1. TERMS OF WEBSITE USE
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        This page (together with the documents
                                        referred on it) set out the terms of use
                                        by which you may make use of the{' '}
                                        <a href='https://www.grayquest.com'>
                                            www.grayquest.com
                                        </a>{' '}
                                        website and the Grayquest mobile
                                        application (together and separately,
                                        referred to as "our site"), whether as a
                                        guest or a registered user. Please read
                                        these terms of use carefully before you
                                        start to use the site. By using our site
                                        or by assessing or using our website
                                        through our Facebook application, you
                                        indicate that you have read and accepted
                                        these terms of use, which include the
                                        privacy policy that applies to our site
                                        (the "Privacy Policy") and that you
                                        agree to abide by them. If you do not
                                        agree to these terms of use, please
                                        refrain from using our site. We at our
                                        sole discretion, reserve the right not
                                        to accept a user from registering on our
                                        site or on our application without
                                        assigning any reason thereof.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Terms of Use are in respect of for
                                        our site and our application only and do
                                        not apply, replace or supersede to the
                                        terms and condition of use and the
                                        privacy policy of our financial
                                        partners.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                2. OWNERSHIP OF CONTENT
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        Our Site, Application and all of its
                                        contents including, but not limited to,
                                        all text, logos, videos, illustrations,
                                        artwork, information, images and other
                                        material ("Content") are owned and
                                        copyrighted by us unless otherwise
                                        mentioned therein. Our Site features
                                        logos, brand identities, domain names
                                        and other trademarks and service marks
                                        (collectively referred to as
                                        "Intellectual Property") that are the
                                        proprietary rights and/ or are licensed
                                        to us. Nothing contained on this Site
                                        should be construed as granting, by
                                        implication, estoppel, or otherwise, any
                                        license or right to use any of the marks
                                        displayed on our Site without prior
                                        written authorization/ permission from
                                        us. Your use of any content, except as
                                        provided in these Terms of Use herein,
                                        without prior written authorization/
                                        permission from us is strictly
                                        prohibited. You are also advised that we
                                        will enforce our Intellectual Property
                                        rights to the fullest extent of the law,
                                        including taking civil and/ or criminal
                                        action.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                3. INTELLECTUAL PROPERTY RIGHTS
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        We are the owner and/ or the licensee of
                                        all the Intellectual Property rights in
                                        our site, except where specifically
                                        mentioned. Said Intellectual Property
                                        are protected under applicable laws in
                                        India and through international
                                        conventions. All such rights are
                                        reserved to us and our licensors. As a
                                        visitor to our site, you may download a
                                        single copy of the material for your own
                                        non-commercial, private viewing purposes
                                        only. No copying or distribution for any
                                        commercial or business use is permitted
                                        without our prior written consent.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You may not include a link to our site
                                        or display the contents of our site
                                        surrounded or framed or otherwise
                                        surrounded by material not originating
                                        from us without our consent.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You may not distribute, modify, publish,
                                        re-use, reproduce, copy (except as set
                                        forth above), transmit, display,
                                        license, create derivative works from,
                                        transfer, sell or otherwise use any of
                                        the information, illustrations,
                                        photographs, video or audio sequences or
                                        any graphics in any manner whatsoever.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        If you print off, copy or download any
                                        part of our site in breach of these
                                        terms of use, your right to use our site
                                        will cease immediately and you must, at
                                        our option, return or destroy any copies
                                        of the materials you have made.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                4. REGISTERED USERS
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        In order to access certain features of
                                        our site and our application, you may be
                                        asked to register by providing certain
                                        personal information such as, for
                                        example, your name and email address.
                                        You will also be asked to select a user
                                        name ("User Name") for identification
                                        purposes.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        2 You must ensure that the details and
                                        information provided by you is correct,
                                        accurate and up to date. We will not be
                                        responsible for any loss or harm arising
                                        out of your failure to provide the same.
                                        We may ask you to verify the accuracy of
                                        the details and information provided by
                                        you at any point of timee.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You must take all the reasonable and
                                        necessary care to ensure that your
                                        account both on our site and our
                                        Application respectively is operated and
                                        accessed by solely by you and no one
                                        else.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You are responsible for maintaining the
                                        confidentiality of your password and
                                        your account information, and you are
                                        solely and fully responsible for all
                                        activities that occur under your
                                        password or account and for any access
                                        to or use of the our site by you or any
                                        person or entity using your password,
                                        whether or not such access or use has
                                        been authorised by you, and whether or
                                        not such person or entity is your
                                        employee or agent.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You must immediately notify us of any
                                        unauthorized use of your password or
                                        account or any other breach of security.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We will not be liable for any loss or
                                        damage whatsoever resulting from the
                                        disclosure your password contrary to
                                        these Terms of Use. You may not use
                                        another person's account at any time
                                        without the express permission of the
                                        account holder.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        If we become aware that you have used
                                        another person's account or password or
                                        any information or material contrary to
                                        any of the terms and conditions
                                        mentioned herein, then we reserve our
                                        right to take all necessary actions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You retain ownership of any video,
                                        image, text, software, information or
                                        any content posted, uploaded or
                                        published by you on our site. You
                                        represent and warrant that you have the
                                        necessary right, license, authorization
                                        or permission to post, upload or publish
                                        the same on our site and by posting,
                                        uploading or publishing the same on our
                                        site, you have authorized the company to
                                        use the same without any restrictions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We will not be responsible for any of
                                        comment, video, text, software,
                                        information discussion, information,
                                        content or view posted by you on our
                                        site or through our Application.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You retain and warrant that the
                                        information, material, comment, video,
                                        text, software, information discussion,
                                        information, content posted uploaded or
                                        published by you on our site or our
                                        application does not infringe upon the
                                        rights if third parties including but
                                        not limited to intellectual property
                                        rights, privacy or personal rights.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We will be in no way responsible or
                                        liable with respect to any discussions,
                                        chats, information or any material
                                        posted on our site by you or anyone else
                                        and we assume no liability arising from
                                        any false, misleading, defamatory,
                                        obscene, vulgar, scandalous, infringing
                                        or any material that would violate the
                                        laws of India or any other country and
                                        give rise to civil or criminal
                                        liability.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You must not post, upload, publish,
                                        transmit, or share any Content on our
                                        Site or our Application that you did not
                                        create or that you do not have
                                        permission to post.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We have the right to store any content
                                        you post on our site, and at our
                                        discretion to make available any such
                                        content on our site, in each case
                                        indefinitely. However, we are not
                                        obliged to make available or otherwise
                                        exploit any such content. We are not
                                        responsible for any loss, theft, rights
                                        infringement or damage of any kind to or
                                        associated with any such content and you
                                        take sole responsibility for any such
                                        content that you provide to us.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You are prohibited from using our Site
                                        to advertise or perform any commercial
                                        solicitation in any manner.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You authorize Us for contacting you
                                        (including by post, email, fax, short
                                        text message (SMS), pager or telephone)
                                        about Our products and services
                                        (collectively "Promotional
                                        Communications") which We think may be
                                        of your interest. You agree to hold
                                        harmless and indemnify Us and Our
                                        affiliates and our respective directors,
                                        officers, employees and agents, as well
                                        as their licensors and suppliers, from
                                        and against any and all claims, actions,
                                        suits and or proceedings, as well as any
                                        and all losses, liabilities, damages,
                                        costs and expenses arising out of or
                                        relate to such Promotional Communication
                                        with you or any person on your behalf.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                5. INFORMATION AND AVAILABILITY
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        While we take every care to ensure that
                                        the information on our site is accurate
                                        and complete, some of it is supplied to
                                        us by third parties and we are unable to
                                        check its accuracy or completeness. You
                                        are advised to verify the accuracy of
                                        any information before relying on it. We
                                        make no warranty or represent the
                                        accuracy of the information supplied to
                                        us by third parties. Further, due to the
                                        inherent nature of the internet, errors,
                                        interruptions and delays may occur in
                                        the service at any time. Accordingly,
                                        our site is provided "as is" without any
                                        warranties of any kind, express or
                                        implied, and we do not accept any
                                        responsibility and liability arising
                                        from any inaccuracy or omission in the
                                        information or interruption in
                                        availability.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We would be happy to correct any error
                                        or correction with respect to the
                                        information published on our website
                                        that is brought to our notice by you in
                                        writing and we will within a reasonable
                                        time carry out the necessary changes as
                                        deemed appropriate by us.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                6. MOBILE TERMS OF USE
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        When accessing our website or
                                        application via mobile, standard network
                                        charges apply. Your handset must be WAP
                                        enabled. You may incur additional WAP,
                                        GPRS, 3G &amp; 4G data charges from your
                                        network operator according to your
                                        contract. Our site via mobile (off
                                        portal) is not a subscription service.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                7. VARIATION
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        We may revise these terms of use at any
                                        time by amending this page. You are
                                        expected to check this page from time to
                                        time to take notice of any changes we
                                        make, as they are binding on you. Some
                                        of the provisions contained in these
                                        terms of use may also be superseded by
                                        provisions or notices published
                                        elsewhere on our site. Information on
                                        our site is subject to change without
                                        any prior notice or obligation to notify
                                        you. Additionally, we reserve the right
                                        to change any and all content, software
                                        and other items used or contained on our
                                        Site or our application at any point of
                                        time without notice.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                8. INDEMNITY
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        You agree to indemnify us and our
                                        affiliates and our respective directors,
                                        officers, employees and agents, as well
                                        as their representatives, from and
                                        against any and all claims, actions,
                                        suits or proceedings, as well as any and
                                        all losses, liabilities, damages, costs
                                        and expenses (including reasonable legal
                                        fees) arising out of:
                                    </p>
                                    <ul className={classes.innerListDecimal}>
                                        <li>
                                            Any misrepresentation, act or
                                            omission made by you in connection
                                            with your use of our site; or
                                        </li>
                                        <li>
                                            Any non-compliance by you with these
                                            Terms
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                9. LIABILITY
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        In respect of all aspects of our site
                                        and our supply of products and any other
                                        party (whether or not involved in
                                        creating, producing, maintaining or
                                        delivering our site), and any of our
                                        group companies and the officers,
                                        directors, employees, shareholders or
                                        agents of any of them, exclude all
                                        liability and responsibility for any
                                        amount or kind of loss or damage that
                                        may result to you or a third party
                                        (including without limitation, any
                                        direct, indirect, punitive or
                                        consequential loss or damages, or any
                                        loss of income, profits, goodwill, data,
                                        contracts, use of money, or loss or
                                        damages arising from or connected in any
                                        way to business interruption, and
                                        whether in tort (including without
                                        limitation negligence), contract or
                                        otherwise) in connection with our site
                                        in any way or in connection with the
                                        use, inability to use or the results of
                                        use of our site, any websites linked to
                                        our site or the material on such
                                        websites, including but not limited to
                                        loss or damage due to viruses that may
                                        infect your computer equipment,
                                        software, data or other property on
                                        account of your access to, use of, or
                                        browsing our site or your downloading of
                                        any material from our site or any
                                        websites linked to our site.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        If you choose to post your location,
                                        your intended location or any other such
                                        personal information on our site you
                                        accept that this is entirely at your own
                                        risk and we are not responsible for any
                                        loss or harm which occurs as a result of
                                        your sharing any information about your
                                        location or intended location.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        This Section does not in any way limit
                                        our liability:
                                    </p>
                                    <ul className={classes.innerListDecimal}>
                                        <li>
                                            for death or personal injury caused
                                            by our negligence;
                                        </li>
                                        <li>
                                            under section 2(3) of the Consumer
                                            Protection Act 1987;
                                        </li>
                                        <li>
                                            For fraud or fraudulent
                                            misrepresentation; or
                                        </li>
                                        <li>
                                            For any matter for which it would be
                                            illegal for us to exclude, or
                                            attempt to exclude, our liability.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>
                                        Where you buy any product or service
                                        from a third party seller through our
                                        site, the seller's individual liability
                                        will be set out in the seller's terms
                                        and conditions.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                10. REWARD
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        We may introduce rewards, discounts or
                                        promotion scheme in conjunction with
                                        other companies from time to time in
                                        respect of a certain number of our
                                        products. The terms and condition for
                                        the rewards, discounts and promotion
                                        will be disclosed at the time the
                                        reward, discounts or promotion is
                                        launched. The rewards, discounts and
                                        promotion will also be subject to the
                                        terms and conditions of the other
                                        associates companies as well.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                11. YOUR RESPOSIBILITY: DAMAGE LIMITS
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        You have sole responsibility for use of
                                        the products/services you purchase or
                                        avail through this website. In no event
                                        shall GrayQuest be liable to you in
                                        relation to the products/services, or
                                        your use, misuse or inability to use the
                                        products or services, for either any -
                                        (i) indirect, punitive, special,
                                        exemplary, incidental, or consequential
                                        damage or direct damages in excess of
                                        the amount you paid GrayQuest or its
                                        financial partners for the Services.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                12. DISCLAIMER
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        Our site or Application may be
                                        temporarily unavailable at any point of
                                        time due to maintenance, up-gradation or
                                        any other reason. We do not claim any
                                        responsibility, liability for any
                                        omission, error deletion, defect or
                                        destruction in user communication. We
                                        are not responsible for any malfunction
                                        technical or other problem of any
                                        telephone network or service, computer
                                        systems, servers or providers, computer
                                        or mobile phone equipment, software,
                                        failure of email on account of technical
                                        problems or traffic congestion in our
                                        site or application, including injury or
                                        damage to your or to any other person's
                                        computer, hardware, software or mobile
                                        phone as a result from using or
                                        downloading materials in connection with
                                        our Site and/or application. We are not
                                        responsible or liable for any loss or
                                        damage resulting from the use of our
                                        Site or Application or any Content or
                                        Third-Party Applications, Software or
                                        Content posted on or through our Site or
                                        transmitted to users, or any
                                        interactions between users of our site,
                                        whether online or offline.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        User gives us the right to contact
                                        him/her or his/her parents as and when
                                        required for the purpose of selling the
                                        products or services.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        User takes full responsibility for
                                        providing personal or academic
                                        information and indemnifies the company
                                        or any of its financial partners and
                                        associates for any wrong information
                                        provided by him/her under the India law.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                13. THIRD-PARTY WEBSITES AND CONTENT
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        Our Site or application may contain
                                        links to other websites ("Third-Party
                                        Sites") as well as applications,
                                        software, text, marks, graphics,
                                        pictures, designs, music, sound, video,
                                        articles, photographs, information, and
                                        other content or material belonging to
                                        or originating from third parties (the
                                        "Third-Party Content"). We are not
                                        responsible for such Third Party
                                        Contents and Third Party Sites. We have
                                        not checked or monitored the accuracy or
                                        completeness of the said Third Party
                                        Contents and Third Party Sites
                                        respectively. If you access or install a
                                        Third Party Contents you are doing the
                                        same at your own risk and you should
                                        read and review the respective terms and
                                        conditions thereof and our terms and
                                        conditions and privacy policies will no
                                        longer we applicable in any manner.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                14. APPLICABLE LAW AND JURISDICTION
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        These terms and conditions are governed
                                        by and to be interpreted in accordance
                                        with laws of India, without regard to
                                        the choice or conflicts of law
                                        provisions of any jurisdiction. You
                                        agree, in the event of any dispute
                                        arising in relation to these terms and
                                        conditions or any dispute arising in
                                        relation to the website whether in
                                        contract or tort or otherwise, to submit
                                        to the jurisdiction of the courts
                                        located at Mumbai, India for the
                                        resolution of all such disputes.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                15. LIMITATION OF LIABILITY
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        Your use of our site is at your own
                                        risk. In no event and under no
                                        circumstances and under no legal theory,
                                        tort, contract, or otherwise shall
                                        GrayQuest Education Finance Pvt. Ltd. be
                                        liable, without limitation, for any
                                        damages whatsoever, including direct,
                                        indirect, incidental, consequential or
                                        punitive damages, arising out of any
                                        access to or any use of or any inability
                                        to access or use this website including
                                        any material, information, links, and
                                        content accessed through this website or
                                        through any linked external website.
                                        Note: If you are under the age of 18, it
                                        is mandatory that your parent or
                                        guardian has read and accepted the terms
                                        and conditions mentioned hereinabove on
                                        your behalf and by your use of the site
                                        it is deemed that you have obtained
                                        parental consent for the same. If your
                                        parent or guardian has not read and
                                        agreed to the terms and conditions, you
                                        will not have permission to use our
                                        site.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                16. CANCELLATION / REFUND
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    <p>
                                        GrayQuest strongly recommends that all
                                        their Borrowers – read, understand and
                                        familiarize themselves with our business
                                        model and cancellation &amp; refund
                                        policy prior to using any services of
                                        GrayQuest. No overseas payment can be
                                        made through wire transfer or any other
                                        means to our bank account.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        In the event of cancellation of services
                                        due to any avoidable/unavoidable reasons
                                        we must be notified of the same in
                                        writing at{' '}
                                        <a href='mailto:work@grayquest.com'>
                                            work@grayquest.com
                                        </a>
                                        . Cancellation request should be placed
                                        within 24 hours of agreement.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Any refunds, if applicable within the
                                        purview of our refund policy, will be
                                        processed over a period of 2-4 weeks due
                                        to Indian banking procedures. The refund
                                        policy is subject to change by
                                        GrayQuest. The changes will apply after
                                        due legal diligence. Suitable
                                        announcements will be made on the
                                        website and legally-compliant notice
                                        period will be given before any changes
                                        come into force. If lenders or borrowers
                                        do not accept the revised policy, they
                                        should not continue to use the services
                                        and inform us about the same on{' '}
                                        <a href='mailto:work@grayquest.com'>
                                            work@grayquest.com
                                        </a>
                                        . If lenders or borrowers continue to
                                        use the services after the date on which
                                        the change comes into effect, their use
                                        of the services indicates the agreement
                                        to be bound by the new refund policy.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Please note that there may be certain
                                        instruction to remit payments or seek
                                        rendition of services that GrayQuest is
                                        unable to accept shall be cancel. We
                                        reserve the right, at our sole
                                        discretion, to refuse or cancel any
                                        order for any reason. We may also
                                        require additional verifications or
                                        information before accepting any
                                        instruction or registering you on are
                                        portal as a lender or a borrower. The
                                        loan processing may get rejected at any
                                        stage due to lack of information or
                                        verifiable information.
                                    </p>
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                17. User agrees not to host, display, upload
                                ,modify, transmit and share any information of
                                the below nature
                            </div>
                            <ul type='roman' className={classes.roman}>
                                <li className={classes.roman_list}>
                                    <span>&#40;i&#41;</span> belongs to another
                                    person and to which the user does not have
                                    any right;
                                </li>
                                <li className={classes.roman_list}>
                                    <span>&#40;ii&#41;</span> is harmful to
                                    children;
                                </li>
                                <li className={classes.roman_list}>
                                    <span>&#40;iii&#41;</span> impersonates
                                    another person;
                                </li>
                                <li className={classes.roman_list}>
                                    <span>&#40;iv&#41;</span> threatens the
                                    unity, integrity, defence, security or
                                    sovereignty of India, friendly relations
                                    with foreign States, or public order, or
                                    causes incitement to the commission of any
                                    cognisable offence or prevents investigation
                                    of any offence or is insulting other nation;
                                </li>
                                <li className={classes.roman_list}>
                                    <span>&#40;v&#41;</span> contains software
                                    virus or any other computer code, file or
                                    program designed to interrupt, destroy or
                                    limit the functionality of any computer
                                    resource; and
                                </li>
                                <li className={classes.roman_list}>
                                    <span>&#40;vi&#41;</span> is patently false
                                    and untrue, and is written or published in
                                    any form, with the intent to mislead or
                                    harass a person, entity or agency for
                                    financial gain or to cause any injury to any
                                    person;
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default TermsOfService;
