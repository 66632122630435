import React from 'react';
import PropTypes from 'prop-types';
import classes from './SpinnerLoader.module.scss';

function SpinnerLoader(props) {
    let size = '';
    if (props.size === 'medium') {
        size = classes.medium;
    } else if (props.size === 'large') {
        size = classes.large;
    } else if (props.size === 'tiny') {
        size = classes.tiny;
    }
    return (
        <>
            <span
                className={`${classes.loading} ${size}`}
                style={{ borderColor: props.trackColor, borderTopColor: props.spinnerColor }}
            />
        </>
    );
}

SpinnerLoader.propTypes = {
    size: PropTypes.string,
    spinnerColor: PropTypes.string,
    trackColor: PropTypes.string,
};

export default SpinnerLoader;
