import React from 'react'
import { Helmet } from 'react-helmet';
import { LANDING_BASE_URL } from '../../../constants';
import blueLogo from '../../assets/images/header/gqBlueLogo.png';
import { OrganizationSchema } from '../../seo/Schema/OrganizationSchema';
import { 
    HomeBreadcrumbSchema, 
    AboutUsBreadcrumbSchema, 
    ParentsBreadcrumbSchema,
    CareersBreadcrumbSchema,
    BlogsBreadcrumbSchema,
    InstituteBreadcrumbSchema,
    GQPlusBreadcrumbSchema, 
    ContactBreadcrumbSchema, 
    FAQBreadcrumbSchema, 
    TermsAndConditionsBreadcrumbSchema,
    LendingPartnersBreadcrumbSchema,
    TermsOfServiceBreadcrumbSchema,
    grievanceOfficerBreadcrumbSchema,
    PrivacyPolicyBreadcrumbSchema,
} from '../../seo/Schema/Breadcrumbs';
import PropTypes from 'prop-types';

function CustomHelmet(props) {
    let titleTag = ''; 
    let metaTitle = '';
    let metaDescription = '';
    let instituteName = props.instituteName ? props.instituteName : 'GrayQuest Institute Partner';
    let PageBreadcrumbSchema;

    switch (props.page) {
        case "home":
            titleTag = 'GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = "Pay your School, College, University, & EdTech fees in interest free easy monthly installments with GrayQuest. India's Largest Education Fee Financing Company.";
            PageBreadcrumbSchema = HomeBreadcrumbSchema;
            break;
        case "about":
            titleTag = 'About Us | Zero Cost Education Fee Financing in India';
            metaTitle = 'About Us - Zero Cost Education Fee Financing in India';
            metaDescription = 'The best way to pay education fees in India. Enjoy the convenience of interest free monthly payment plans for paying school, college & university fees';
            PageBreadcrumbSchema = AboutUsBreadcrumbSchema;
            break;
        case "institute":
            titleTag = 'Partner With Us | Zero Cost Fee Financing Products for Institutions in India';
            metaTitle = 'Partner With Us | Zero Cost Fee Financing Products for Institutions in India';
            metaDescription = 'Boost your school, college, university & edtech cashflows with upfront fees from GrayQuest while students & parents get interest free monthly installments. Full institute fee financing available with online fee collection.';
            PageBreadcrumbSchema = InstituteBreadcrumbSchema;
            break;
        case "parents":
                titleTag = 'Partner With Us | Zero Cost Fee Financing Products for Institutions in India';
                metaTitle = "Pay your School, College, University, & EdTech fees in interest free easy monthly installments with GrayQuest. India's Largest Education Fee Financing Company.";
                PageBreadcrumbSchema = ParentsBreadcrumbSchema;
                break;
        case "careers":
                titleTag = 'Partner With Us | Zero Cost Fee Financing Products for Institutions in India';
                metaTitle = 'Partner With Us | Zero Cost Fee Financing Products for Institutions in India';
                metaDescription = "Pay your School, College, University, & EdTech fees in interest free easy monthly installments with GrayQuest. India's Largest Education Fee Financing Company.";
                PageBreadcrumbSchema = CareersBreadcrumbSchema;
                break;
        case "blogs":
                titleTag = 'Blogs | GrayQuest : Zero Interest School & College Fee EMI';
                metaTitle = 'Blogs | GrayQuest : Zero Interest School & College Fee EMI';
                metaDescription = "Pay your School, College, University, & EdTech fees in interest free easy monthly installments with GrayQuest. India's Largest Education Fee Financing Company.";
                PageBreadcrumbSchema = BlogsBreadcrumbSchema;
                break;
        // case "gqplus":
        //     titleTag = "Membership Program | India's First Student Rewards Program  - GQ+";
        //     metaTitle = "Membership Program | India's First Student Rewards Program  - GQ+";
        //     metaDescription = "Join India's First student Rewards program that rewards you for paying your education fees. Get access to 50+ exciting rewards and insurance.";
        //     PageBreadcrumbSchema = GQPlusBreadcrumbSchema;
        //     break;
        case "contact":
            titleTag = 'Contact Us | GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'Contact Us - GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = 'Just focus on your education. We provide Parents and Students with the most convenient and easiest option to pay their education fees.';
            PageBreadcrumbSchema = ContactBreadcrumbSchema;
            break;
        case "termsandconditions":
            titleTag = 'Terms & Conditions | GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'Terms & Conditions - GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = 'Please find below the latest GrayQuest terms and conditions.';
            PageBreadcrumbSchema = TermsAndConditionsBreadcrumbSchema;
            break;
        case "lendingpartners":
            titleTag = 'Lending Partners | GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'Lending Partners - GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = 'Our Lending Partners';
            PageBreadcrumbSchema = TermsAndConditionsBreadcrumbSchema;
            break;
        case "termsofservice":
            titleTag = 'Terms Of Service | GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'Terms Of Service - GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = 'Please find below the latest GrayQuest Education Terms of Service.';
            PageBreadcrumbSchema = TermsOfServiceBreadcrumbSchema;
            break;
        case "privacypolicy":
            titleTag = 'Privacy Policy | GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'Privacy Policy - GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = '';
            PageBreadcrumbSchema = PrivacyPolicyBreadcrumbSchema;
            break;
        case "faqpage":
            titleTag = 'Frequently asked questions | GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'Frequently asked questions - GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = '';
            PageBreadcrumbSchema = FAQBreadcrumbSchema;
            break;
        case "grievanceofficer":
                titleTag = `Grievance Officer | GrayQuest : Zero Interest School & College Fee EMI`;
                metaTitle = `Grievance Officer | GrayQuest : Zero Interest School & College Fee EMI`;
                metaDescription = `Grievance Officer - GrayQuest Education Finance : Fastest Way to Get School Fee EMI | Full Institute Fee Funding`;
                PageBreadcrumbSchema = grievanceOfficerBreadcrumbSchema;
            break;
        case "instituteDetails":
            titleTag = `${instituteName} | GrayQuest : Zero Interest School & College Fee EMI`;
            metaTitle = `${instituteName} | GrayQuest : Zero Interest School & College Fee EMI`;
            metaDescription = `${instituteName} - GrayQuest Education Finance : Fastest Way to Get School Fee EMI | Full Institute Fee Funding`;
            break;
        default:
            titleTag = 'GrayQuest : Zero Interest School & College Fee EMI';
            metaTitle = 'GrayQuest : Zero Interest School & College Fee EMI';
            metaDescription = "Pay your School, College, University, & EdTech fees in interest free easy monthly installments with GrayQuest. India's Largest Education Fee Financing Company.";
            break;
    }

    return (
        <Helmet>
            <title>{titleTag ? titleTag : "GrayQuest : Zero Interest School & College Fee EMI"}</title>

            <meta name="title" content={metaTitle} />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content="school fee loan, school fees emi, school fees in emi, student loans, grayquest, grayquest education loans, grayquest education finance, grayquest finance, student loans in india, education loans in india, easy emi for college fees, school financing, 0% interest education loan, zero interest monthly installments, low interest financing, low interest education loan"></meta>

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:image" content={`${LANDING_BASE_URL+blueLogo}`} />
            <meta name="twitter:image:alt" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />

            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="GrayQuest Education Finance" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={`${LANDING_BASE_URL+blueLogo}`} />
            <meta property="og:url" content={`${LANDING_BASE_URL}`} />

            <script type="application/ld+json">{OrganizationSchema ? JSON.stringify(OrganizationSchema) : ''}</script>
            {
                PageBreadcrumbSchema ?
                    <script type="application/ld+json">{JSON.stringify(PageBreadcrumbSchema)}</script> : ''
            }

            {props.children ? props.children : ''}
        </Helmet>
    )
}

CustomHelmet.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	instituteName: PropTypes.string,
	page: PropTypes.string,
};

export default CustomHelmet;
