import React, { useEffect, useRef, useState } from 'react';
import yellowstaricon from '../../assets/images/parents/yellow-star.svg';
import titlestar from '../../assets/images/parents/title-star.svg';
import testimonial from '../../assets/images/parents/testimonial-icon.svg';
import amityInsititueLogo from '../../assets/images/parents/amity.png';
import ryanInsititueLogo from '../../assets/images/parents/ryan.png';
import vibgyorInsititueLogo from '../../assets/images/parents/vibgyor.png';
import euroSchoolLogo from '../../assets/images/parents/euro.png';
import sliderArrow from '../../assets/images/parents/slider-arrow.svg';
import peopleimg from '../../assets/images/parents/people-chips.png';
import support from '../../assets/images/parents/support-icon.svg';
import payanywhere from '../../assets/images/parents/pay-anywhere-icon.svg';
import paymentmethod from '../../assets/images/parents/payment-method-icon.svg';
import alertBanner from '../../assets/images/parents/bellIcon.png';
import bellIcon from '../../assets/images/parents/bell-icon.svg';
import discountIcon from '../../assets/images/parents/discount-icon.svg';
import discount from '../../assets/images/parents/discount.png';
import add from '../../assets/images/parents/add.png';
import subtract from '../../assets/images/parents/subtract.png';
import mobileParentChildImage from '../../assets/images/parents/mobileParentChildImage.png';
import emiicon from '../../assets/images/parents/emi-icon.svg';
import debit from '../../assets/images/parents/debit.png';
import quote from '../../assets/images/parents/quote.png';
import debitIcon from '../../assets/images/parents/auto-debit-icon.svg';
import Accordion from 'react-bootstrap/Accordion';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer1 from '../Common/Footer1/Footer1';
import Header1 from '../Common/Header1/Header1';
import flexEmi1 from '../../assets/images/parents/flexEmi1.png';
import parentNewImage from '../../assets/images/parents/parentNewImage.png';
import './Parents.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import CustomHelmet from '../CustomHelmet/CustomHelmet';
// Insurance
import InsuranceBannerImage from '../../assets/images/parents/grayquest-insurance.png';
import InsuranceBannerImage2 from '../../assets/images/parents/grayquest-insurance2.jpg';
import InsuranceBannerImage3 from '../../assets/images/parents/grayquest-insurance3.png';

function Parents() {
    const [help1, setHelp1] = useState(false);
    const [help2, setHelp2] = useState(false);
    const [hideRightArrow, setHideRightArrow] = useState(false);

    const swiperRef = useRef();

    const sliderSettings = {
        0: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        425: {
            slidesPerView: 1,
            spaceBetween: 50,
        },
        680: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 30,
        },
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <CustomHelmet page="parents" />
            <Header1 theme={'parents'} />
            <main id="main" className='parents'>
                <section id="mainContainer" className='bg--lightBlue pb-0 hero'>
                    <div id="container" className='container'>
                        <div id="heroContainer" className='hero__container'>
                            <p className='section__label section__label-center blue'>
                                <img
                                    className='img-fluid'
                                    src={titlestar}
                                    alt='star icon'
                                />
                                Pay fee the easy way
                            </p>
                            <h1 className='text--bold section__title text-center'>
                                Flexible fee payment options for every student’s{' '}
                                <span className='text--blueGradient text-spiral'>
                                    brighter future
                                </span>
                            </h1>
                            <p className='peopleChips text--semi'>
                                <img
                                    className='img-fluid'
                                    src={peopleimg}
                                    alt='people chips'
                                />
                                <span className='text--pink text--bold'>
                                    500k+
                                </span>{' '}
                                <span>parents will vouch for us</span>
                            </p>
                            <div id="parentChildImage">
                                <img
                                    
                                    className='img-fluid'
                                    src={parentNewImage}
                                    alt='parent hero'
                                />
                            </div>
                            <div id="mobileParentChildImage" >
                                <img
                                    
                                    className='img-fluid'
                                    src={mobileParentChildImage}
                                    alt='parent hero'
                                />
                            </div>


                        </div>
                    </div>
                </section>
                <section className='bg--blue why'>
                    <div className='container'>
                        <p className='section__label section__label-center yellow'>
                            <img
                                className='img-fluid'
                                src={yellowstaricon}
                                alt='yellow star icon'
                            />
                            Why choose GRAYQUEST?
                        </p>
                        <h2 className='section__title text--bold text--white'>
                            <span className='text--pinkGradient'>Control</span>{' '}
                            the way you pay your child’s fees
                        </h2>
                        <p
                            className='section__desc text--white text--regular'
                            style={{
                                opacity: '0.6',
                            }}
                        >
                            Paying fees should never stand in between your
                            child’s growth and education.
                        </p>
                        <p className='section__btn text-center'>
                            <a
                                href='#'
                                className='custom__btn custom__btn--white'
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                Sign up with us
                            </a>
                        </p>
                        <div className='why__container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img
                                        className='img-fluid'
                                        src={flexEmi1}
                                        alt='no cost emi'
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <div className='why__container--icon withArrow'>
                                        <img
                                            className='img-fluid'
                                            src={emiicon}
                                            alt='emi icon'
                                        />
                                    </div>
                                    <p className='text--white why__container--title'>
                                        No cost EMI{' '}
                                    <img
                                    className='img-fluid'
                                    src={yellowstaricon}
                                    alt='yellow star icon'
                                    />
                                    </p>
                                    <p className='text--white why__container--desc'>
                                        Opt-in to pay fees in easy monthly
                                        instalments with{' '}
                                        <span className='text--bold'>
                                            0% interest.
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 order-lg-2'>
                                    <img
                                        className='img-fluid'
                                        src={debit}
                                        alt='auto debit'
                                    />
                                </div>
                                <div className='col-lg-6 order-lg-1'>
                                    <div className='why__container--icon'>
                                        <img
                                            className='img-fluid'
                                            src={debitIcon}
                                            alt='auto debit icon'
                                        />
                                    </div>
                                    <p className='text--white why__container--title'>
                                        Auto-debit subscriptions
                                    </p>
                                    <p className='text--white why__container--desc'>
                                        Never miss a fee payment deadline with
                                        our{' '}
                                        <span className='text--bold text-spiral'>
                                            auto-debit facility.
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img
                                        className='img-fluid'
                                        src={discount}
                                        alt='discount'
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <div className='why__container--icon'>
                                        <img
                                            className='img-fluid'
                                            src={discountIcon}
                                            alt='discount icon'
                                        />
                                    </div>
                                    <p className='text--white why__container--title'>
                                        Get discounts
                                    </p>
                                    <p className='text--white why__container--desc'>
                                        Pay full year’s fees upfront through
                                        GrayQuest and avail{' '}
                                        <span className='text--bold text-spiral'>
                                            exciting discounts
                                        </span>
                                        (conditions applied).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--yellow pb-0 alerts'>
                    <div className='container'>
                        <div className='alerts__heading'>
                            <h2 className='section__title withArrow text--bold text--black'>
                                We’ll send you real-time alerts{' '}
                                <span className='text--blueGradient'>
                                    via WhatsApp/SMS
                                </span>{' '}
                                for
                            </h2>
                            <img
                                className='img-fluid'
                                src={bellIcon}
                                alt='bell icon'
                            />
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='alerts__banner'>
                                    <img
                                        className='img-fluid'
                                        src={alertBanner}
                                        alt='alerts banner'
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <ul className='alerts__point'>
                                    <li>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        <p className='text--regular text--black'>
                                            Get notified before the{' '}
                                            <span className='text--bold'>
                                                fee deadline
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        <p className='text--regular text--black'>
                                            Custom{' '}
                                            <span className='text--bold'>
                                                payment links
                                            </span>
                                            to pay as per a mode convenient to
                                            you.
                                        </p>
                                    </li>
                                    {/* <li>
                                        <img
                                            className='img-fluid'
                                            src={titlestar}
                                            alt='star icon'
                                        />
                                        <p className='text--regular text--black'>
                                            <span className='text--bold'>
                                                Receipts
                                            </span>{' '}
                                            and payment details of completed
                                            transactions.
                                        </p>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--withPadding features'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='item flexCloumn'>
                                    <img
                                        className='img-fluid'
                                        src={paymentmethod}
                                        alt='payment method'
                                    />
                                    <p className='text--black text--bold title'>
                                        8+ methods to pay
                                    </p>
                                    <p className='text--regular desc'>
                                        Pay with cards, wallets, UPI,
                                        Netbanking, NEFTs, Cash or Zero Cost
                                        EMIs.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='item flexCloumn'>
                                    <img
                                        className='img-fluid'
                                        src={payanywhere}
                                        alt='pay anywhere'
                                    />
                                    <p className='text--black text--bold title'>
                                        Pay anytime, anywhere!
                                    </p>
                                    <p className='text--regular desc'>
                                        Pay digitally from the comfort of your
                                        home or on campus via QR code.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='item mb-0 flexCloumn'>
                                    <img
                                        className='img-fluid'
                                        src={support}
                                        alt='support icon'
                                    />
                                    <p className='text--black text--bold title'>
                                        Priority support
                                    </p>
                                    <p className='text--regular desc'>
                                        Get instant support via call, email or
                                        WhatsApp and solve any pressing fee
                                        payment issues.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg--lightGray testimonial'>
                    <div className='container'>
                        <div className='testimonial__heading'>
                            <h2 className='section__title text--bold text--black'>
                                Hear it from our{' '}
                                <span className='text--blueGradient'>
                                    fellow parents
                                </span>
                            </h2>
                            <img
                                className='img-fluid'
                                src={testimonial}
                                alt='testimonial icon'
                            />
                        </div>
                    </div>

                    <div className='HomePageSlider'>
                        <Swiper
                            id='slider_margin'
                            className='testimonial__slider'
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            // centeredSlides={true}
                            breakpoints={sliderSettings}
                            onSlideChange={(e) =>
                                e.activeIndex === 3
                                    ? setHideRightArrow(true)
                                    : setHideRightArrow(false)
                            }
                        >
                            <SwiperSlide>
                                <div className='HomePageSlider__Slide'>
                                    <div className='Slide__ContentWrap' style={{ minHeight: '370px' }}>
                                        <img
                                            className='InstituteLogoImage'
                                            src={amityInsititueLogo}
                                            alt='institute'
                                        />
                                        <img
                                            className='QuoteImage'
                                            src={quote}
                                            alt=''
                                        />
                                        <p className='ContentText'>
                                            As a salaried individual, I highly recommend Grayquest's excellent services. It has been a great relief to have a platform that simplifies the payment of my children's higher education fees, eliminating any worries associated with the process.
                                        </p>

                                        <div className='Author'>
                                            <p className='Name'>Sanjeeb Ganguly</p>
                                            <p className='Designation'>Amity University - Lucknow</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomePageSlider__Slide'>
                                    <div className='Slide__ContentWrap' style={{ minHeight: '370px' }}>
                                        <img
                                            className='InstituteLogoImage'
                                            src={ryanInsititueLogo}
                                            alt='institute'
                                        />
                                        <img
                                            className='QuoteImage'
                                            src={quote}
                                            alt=''
                                        />
                                        <p className='ContentText'>
                                            The GrayQuest team is so kind and supportive. They helped me through each and every step.The experience was amazing. They make the whole process so easy for you. Would highly recommend GrayQuest for easy payments of your college or school fees. Very satisfied and happy with the service.
                                        </p>

                                        <div className='Author'>
                                            <p className='Name'>Sarika Chawan</p>
                                            <p className='Designation'>Ryan International School - Sanpada</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomePageSlider__Slide'>
                                    <div className='Slide__ContentWrap' style={{ minHeight: '370px' }}>
                                        <img
                                            className='InstituteLogoImage'
                                            src={vibgyorInsititueLogo}
                                            alt='institute'
                                        />
                                        <img
                                            className='QuoteImage'
                                            src={quote}
                                            alt=''
                                        />
                                        <p className='ContentText'>
                                            GrayQuest Made the process of paying school fees in EMI very easy and simple. I have been using GrayQuest for the last 2 years for both of my kids and have never faced any issues.
                                        </p>

                                        <div className='Author'>
                                            <p className='Name'>Amol Vijay Gosewade</p>
                                            <p className='Designation'>Vibgyor School, Pimple Saudagar, Pune</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomePageSlider__Slide'>
                                    <div className='Slide__ContentWrap' style={{ minHeight: '370px' }}>
                                        <img
                                            className='InstituteLogoImage'
                                            src={euroSchoolLogo}
                                            alt='institute'
                                        />
                                        <img
                                            className='QuoteImage'
                                            src={quote}
                                            alt=''
                                        />
                                        <p className='ContentText'>
                                            The company offers a very easy to follow process to complete loan application form. It is a very good facility to enable parents to pay huge fee amounts in easily manageable EMI chunks. The executives here are also very supportive in personally resolving queries and issues. 
                                        </p>

                                        <div className='Author'>
                                            <p className='Name'>Aseem Chandra</p>
                                            <p className='Designation'>Euro school - Wakad</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className='container'>
                        <div className='slider-controls'>
                            <a
                                style={{ cursor: 'pointer' }}
                                className='slider-arrow btn-prev review-swiper-button-next'
                            >
                                <img
                                    onClick={() =>
                                        swiperRef.current?.slidePrev()
                                    }
                                    className='img-fluid'
                                    src={sliderArrow}
                                    alt='slider arrow'
                                />
                                <span className='sr-only'>Prev Btn</span>
                            </a>

                            {!hideRightArrow ? (
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        swiperRef.current?.slideNext()
                                    }
                                    className='slider-arrow btn-next'
                                >
                                    <img
                                        className='img-fluid'
                                        src={sliderArrow}
                                        alt='slider arrow'
                                    />
                                    <span className='sr-only'>
                                        Next Btn
                                    </span>
                                </a>
                            ) : null}
                        </div>
                    </div>
                </section>
                <section className='bg--withPadding faq'>
                    <div className='container'>
                        <h2 className='section__title text--bold text-center'>
                            We’re here to{' '}
                            <span className='text--blueGradient'>help you</span>
                        </h2>
                        <p className='section__desc text--regular text--black text-center'>
                            These questions will help clear some of your doubts
                            But if they don’t, we are also just a call away!
                        </p>
                        <div className='faq__container'>
                            <Accordion>
                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='0'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp1(!help1)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            What services does GQ provide for my
                                            educational institution?
                                        </p>
                                        {!help1 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_1'
                                    >
                                        <p className='text--regular'>
                                            GQ is India’s largest education
                                            focused payments platform. Our
                                            platform is used by over 5000+
                                            educational institutions with over 3
                                            Million Students. We provide
                                            educational institutions with a
                                            range of payment solutions to enable
                                            them to collect fees seamlessly and
                                            offer multiple convenient payment
                                            options to their parents.
                                            Essentially, GQ offers 3 payment
                                            options - EMI, Auto-Debit Payments
                                            and Online Payment Gateway Solutions
                                            to institutions.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item
                                    className='faq__card'
                                    eventKey='1'
                                >
                                    <Accordion.Header
                                        onClick={() => setHelp2(!help2)}
                                        className='faq__header collapsed'
                                    >
                                        <p className='text--bold text--black'>
                                            How does GQ’s EMI product work?
                                        </p>
                                        {!help2 ? (
                                            <img
                                                className='img-fluid'
                                                src={add}
                                                alt='dummy institute'
                                            />
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                src={subtract}
                                                alt='dummy institute'
                                            />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        className='faq__answer'
                                        id='question_2'
                                    >
                                        <p className='text--regular'>
                                            Our EMI facility allows
                                            parents/students to convert their
                                            annual or term fee payment into
                                            easy, convenient monthly
                                            installments. For most of our
                                            educational institution
                                            partnerships, this facility comes
                                            with zero extra costs (interest or
                                            any other hidden charges) for
                                            parents/students. Parents or
                                            eligible students, have to complete
                                            regular KYC and accept our EMI T&C
                                            as part of the sign up process for
                                            the EMI facility. This EMI facility
                                            is provided in partnership with
                                            various Banks & NBFCs.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <div className="faq__card">
                <div
                  className="faq__header collapsed"
                  datatoggle="collapse"
                  dataTarget="#question_2"
                >
                  <p className="text--bold text--black">
                    What if my Institute is not listed?
                  </p>
                  <span></span>
                </div>
                <div className="faq__answer collapse" id="question_2">
                  <p className="text--regular">
                    DIf you are a parent/guardian of a student studying in our
                    partner Institute or if your Institute name is available in
                    our application form, you are eligible for the plan.
                  </p>
                </div>
              </div>
              <div className="faq__card">
                <div
                  className="faq__header collapsed"
                  datatoggle="collapse"
                  dataTarget="#question_3"
                >
                  <p className="text--bold text--black">
                    What profile of applicants does GrayQuest cater to?
                  </p>
                  <span></span>
                </div>
                <div className="faq__answer collapse" id="question_3">
                  <p className="text--regular">
                    DIf you are a parent/guardian of a student studying in our
                    partner Institute or if your Institute name is available in
                    our application form, you are eligible for the plan.
                  </p>
                </div>
              </div>
              <div className="faq__card">
                <div
                  className="faq__header collapsed"
                  datatoggle="collapse"
                  dataTarget="#question_4"
                >
                  <p className="text--bold text--black">
                    Can we decide the installment tenure?
                  </p>
                  <span></span>
                </div>
                <div className="faq__answer collapse" id="question_4">
                  <p className="text--regular">
                    DIf you are a parent/guardian of a student studying in our
                    partner Institute or if your Institute name is available in
                    our application form, you are eligible for the plan.
                  </p>
                </div>
              </div> */}
                            </Accordion>
                        </div>
                    </div>
                </section>

                <section className='InsuranceSection'>
                    <div className='container'>
                        <div className='InsuranceBannerWrapper'>
                            <img 
                                src={InsuranceBannerImage3} 
                                className='InsuranceBannerImage'
                                alt='grayquest insurance'
                            />
                        </div>
                    </div>
                </section>

                <section>
                    <Footer1 />
                </section>
            </main>
        </>
    );
}

export default Parents;
