const blogRoutes = {
    GQ_BLOGS_DATA_FETCH: {
        METHOD: 'GET',
        URL: '/api/blogs?fields[0]=title&fields[1]=description&fields[2]=publishedAt&fields[3]=readTime&populate[0]=thumbnail',
    },
    GQ_BLOG_FETCH: {
        METHOD: 'GET',
        URL: '/api/blogs/',
        FILTERS:
            'fields[0]=title&fields[1]=subtitle&fields[2]=publishedAt&fields[3]=readTime&fields[4]=body&populate=media&populate=banner',
    },
    GQ_BLOGS_CATEGORY_DATA_FETCH: {
        METHOD: 'GET',
        URL: '/api/categories?fields=label&fields=value',
    },
    GQ_FEATURED_BLOG_DATA_FETCH: {
        METHOD: 'GET',
        URL: '/api/featured-blog?populate[blog][populate][0]=thumbnail&populate[blog][populate][1]=categories',
    },
};

const careerJobsRoutes = {
    GQ_CAREER_JOBS_DATA_FETCH: {
        METHOD: 'GET',
        URL: '/api/jobs?fields[0]=job_role&fields[1]=slug&populate=*',
    },
    GQ_CAREER_JOB_FETCH: {
        METHOD: 'GET',
        URL: '/api/jobs/',
        FILTERS: 'populate=department'
    },
    GQ_CAREER_JOB_DEPARTMENT_DATA_FETCH: {
        METHOD: 'GET',
        URL: '/api/departments?fields=label&fields=value',
    },
};

export const routes = {
    ...blogRoutes,
    ...careerJobsRoutes,
};
