import { LANDING_BASE_URL } from "../../../constants";

export const HomeBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        }
    ]
};


export const InstituteBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/institute", 
                "name": "Institute" 
            } 
        }
    ]
};

export const ParentsBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/parents", 
                "name": "Parents" 
            } 
        }
    ]
};

export const AboutUsBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/about-us", 
                "name": "About Us" 
            } 
        }
    ]
};

export const CareersBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/careers", 
                "name": "Careers" 
            } 
        }
    ]
};

export const BlogsBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/blogs", 
                "name": "Blogs" 
            } 
        }
    ]
};

export const ContactBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/contact", 
                "name": "Contact Us" 
            } 
        }
    ]
};

export const GQPlusBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/membership-program", 
                "name": "Membership Program" 
            } 
        }
    ]
};


export const TermsAndConditionsBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/terms-and-conditions", 
                "name": "Terms And Conditions" 
            } 
        }
    ]
};

export const LendingPartnersBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/lending-partners", 
                "name": "Our Lending Partners" 
            } 
        }
    ]
};

export const TermsOfServiceBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/terms-of-service", 
                "name": "Terms Of Use" 
            } 
        }
    ]
};

export const PrivacyPolicyBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/privacy-policy", 
                "name": "Privacy Policy" 
            } 
        }
    ]
};

export const grievanceOfficerBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/grievance-officer", 
                "name": "Grievance Officer" 
            } 
        }
    ]
};

export const FAQBreadcrumbSchema = {
    "@context": "http://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [
        { 
            "@type": "ListItem", 
            "position": "1", 
            "item": { 
                "@id": LANDING_BASE_URL, 
                "name": "Home" 
            } 
        },
        { 
            "@type": "ListItem", 
            "position": "2", 
            "item": { 
                "@id": LANDING_BASE_URL+"/frequently-asked-questions", 
                "name": "Frequently Asked Questions" 
            } 
        }
    ]
};