import React from 'react';
import PropTypes from 'prop-types';
import classes from './Pagination.module.scss';

const Pagination = (props) => {
    const { activePage, totalPages, onPaginationChange, isLoading } = props;

    const pagelist = [];
    for (let i = 1; i <= totalPages; i++) {
        pagelist.push(i);
    }

    const handOnClick = (e, page) => {
        e.stopPropagation();
        onPaginationChange(page);
    };

    if (totalPages < 2 && !isLoading) {
        return null;
    }

    return (
        !isLoading && (
            <div className={`${classes.Pagination} container`}>
                <div className={`${classes.PaginationContainer}  mx-auto`}>
                    {pagelist?.map((page) => (
                        <span
                            className={`${classes.Page} ${
                                page === activePage ? classes.ActivePage : ''
                            }`}
                            onClick={(e) => handOnClick(e, page)}
                            key={page}
                        >
                            {page}
                        </span>
                    ))}
                </div>
            </div>
        )
    );
};

Pagination.propTypes = {
    isLoading: PropTypes.bool,
    activePage: PropTypes.number,
    totalPages: PropTypes.number,
    onPaginationChange: PropTypes.func,
};

export default Pagination;
