export const UPGRAD_INDIAN_STATES = [
    { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
    { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
    { label: 'Assam', value: 'Assam' },
    { label: 'Bihar', value: 'Bihar' },
    { label: 'Chhattisgarh', value: 'Chhattisgarh' },
    { label: 'Goa', value: 'Goa' },
    { label: 'Gujarat', value: 'Gujarat' },
    { label: 'Haryana', value: 'Haryana' },
    { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
    { label: 'Jharkhand', value: 'Jharkhand' },
    { label: 'Karnataka', value: 'Karnataka' },
    { label: 'Kerala', value: 'Kerala' },
    { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
    { label: 'Maharashtra', value: 'Maharashtra' },
    { label: 'Manipur', value: 'Manipur' },
    { label: 'Meghalaya', value: 'Meghalaya' },
    { label: 'Mizoram', value: 'Mizoram' },
    { label: 'Nagaland', value: 'Nagaland' },
    { label: 'Odisha', value: 'Odisha' },
    { label: 'Punjab', value: 'Punjab' },
    { label: 'Rajasthan', value: 'Rajasthan' },
    { label: 'Sikkim', value: 'Sikkim' },
    { label: 'Tamil Nadu', value: 'Tamil Nadu' },
    { label: 'Telangana', value: 'Telangana' },
    { label: 'Tripura', value: 'Tripura' },
    { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
    { label: 'Uttarakhand', value: 'Uttarakhand' },
    { label: 'West Bengal', value: 'West Bengal' },
];

export const UPGRAD_EXCLUDED_STATES = [
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Jharkhand',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Sikkim',
    'Tripura',
    'West Bengal',
];

export const UPGRAD_EMPLOYMENT_TYPE = [
    {
        label: 'Salaried (minimum 3 months salary credit)',
        value: 'salaried',
    },
    // {
    //     label: 'Self Employed (with valid business proof)',
    //     value: 'self_employed',
    // },
    // {
    //     label: 'Pensioner',
    //     value: 'pensioner',
    // },
    {
        label: 'No Income',
        value: 'no_income',
    },
];

export const UPGRAD_INELIGIBILITY_REASONS = {
    age: "Your age doesn't fall in 23 - 60 years bracket",
    state: 'Your state is currently not catered by us',
    employment_type: "'No Income' emplyment type is not eligible",
    monthly_income:
        "Your monthly income doesn't meet the minimum income requirements",
    required_tenure: 'Tenure greater than 36 months is not eligible',
    bureau_score:
        "Your bureau score doesn't meet the minimum score requirements",
    bureau_issue: 'Ineligible due to missed EMI(s) / bureau issue',
    required_loan_amount:
        'Only loan requirements upto Rs. 4 Lakhs is eligible ',
    existing_emi_amount:
        'EMI value higher than threshold value, hence not eligible',
    bank_statement:
        'Atleast 3 months bank statement showing income proof is required',
};
