import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import classes from './CustomReactMultiSelect.module.scss';
import { GiHamburgerMenu } from 'react-icons/gi';

const CustomReactMultiSelect = (props) => {
    const {
        selectLabelText,
        inputId,
        showCheckbox,
        isMulti,
        actionComponent,
    } = props;

    const customStyles = {
        container: (styles) => ({
            ...styles,
            position: 'relative',
            cursor: 'pointer',
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: '4px',
            paddingLeft: '0px',
            paddingRight: '0px',
            display: 'block',
        }),
        control: (styles, { isDisabled }) => ({
            ...styles,
            backgroundColor: '#eceef0',
            pointerEvents: isDisabled ? 'auto' : '',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            padding: 8,
            borderRadius: 2,
        }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            border: '1px solid white',
            borderRadius: 2,
            boxShadow: 'var(--box-shadow-md)',
            zIndex: 99,
        }),
        menuList: (styles) => ({
            ...styles,
            maxHeight: 190,

            '::-webkit-scrollbar': {
                width: '4px',
                height: '0px',
            },
            '::-webkit-scrollbar-track': {
                background: 'white',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#213071',
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#0a2251',
            },
        }),
        option: (styles, {isFocused, isSelected }) => {
            return {
                ...styles,
                display: 'flex',
                alignItems: 'center',
                padding: '10px 10px',
                borderBlockEnd: '1px solid #eceef0',
                cursor: 'pointer',
                borderRadius: 'var(--boder-radius-md)',
                backgroundColor:
                    !isSelected && isFocused
                        ? 'var(--neutral-100)'
                        : 'transparent',
                color: isSelected ? 'black' : 'var(--neutral-700)',
                fontWeight: isSelected ? '700' : 'var(--neutral-700)',
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: '#f5f5f5',
                },
            };
        },
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: 'none',
                maxWidth: '78%',
            };
        },
        multiValueLabel: (styles) => ({
            ...styles,
            paddingLeft: '0px',
            fontSize: '100%',
        }),
        placeholder: (styles) => ({
            ...styles,
        }),
    };

    const ControlComponent = (propsControl) => {
        return (
            <div className={classes.ControlComponent}>
                {selectLabelText ? (
                    <label htmlFor={inputId}>{selectLabelText}</label>
                ) : null}
                <components.Control {...propsControl} />
            </div>
        );
    };

    const OptionComponent = (propsOption) => {
        return (
            <div className={classes.OptionComponent}>
                <components.Option {...propsOption}>
                    {showCheckbox ? (
                        <div className={classes.CheckboxWrap}>
                            <input
                                type='checkbox'
                                checked={propsOption.isSelected}
                                onChange={() => null}
                            />
                        </div>
                    ) : null}
                    <span>{propsOption.label}</span>
                </components.Option>
            </div>
        );
    };

    const ValueContainerComponent = ({
        children,
        hasValue,
        getValue,
        ...valueContainerProps
    }) => {
        if (!hasValue || !isMulti) {
            return (
                <components.ValueContainer {...valueContainerProps}>
                    {children}
                </components.ValueContainer>
            );
        }
        const [options, otherChildren] = children;
        const optionsCount = getValue().length;

        return (
            <components.ValueContainer {...valueContainerProps}>
                <div className={classes.SelectedCount}>
                    {`${optionsCount} Selected`}
                </div>
                {otherChildren}
            </components.ValueContainer>
        );
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <GiHamburgerMenu />
            </components.DropdownIndicator>
        );
    };

    const CustomMenu = (props) => {
        return (
            <>
                <components.Menu {...props}>
                    <div className={classes.MenuComponent}>
                        {props.children}
                        {actionComponent ? (
                            <div className={classes.ReactSelectActionWrap}>
                                {actionComponent}
                            </div>
                        ) : null}
                    </div>
                </components.Menu>
            </>
        );
    };

    return (
        <>
            <Select
                components={{
                    IndicatorSeparator: () => null,
                    MultiValueRemove: () => null,
                    Control: ControlComponent,
                    Option: OptionComponent,
                    DropdownIndicator: DropdownIndicator,
                    ValueContainer: ValueContainerComponent,
                    Menu: CustomMenu,
                }}
                styles={customStyles}
                className='react-select-container'
                classNamePrefix='react-select'
                {...props}
            />
        </>
    );
};

CustomReactMultiSelect.propTypes = {
    selectLabelText: PropTypes.string,
    inputId: PropTypes.string,
    showCheckbox: PropTypes.bool,
    isMulti: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default CustomReactMultiSelect;
