import React, { useEffect } from 'react'
import "./MessageConfirmation.css"
import pageNotFoundBackgroundImage from "../../assets/images/pageNotFound/pageNotFoundBackgroundImage.png"
import mobileViewPageNotFoundBackgroundImage from "../../assets/images/pageNotFound/mobileViewPageNotFoundBackgroundImage.png"
import messageConfirmed from "../../assets/images/pageNotFound/messageConfirmed.png"
import { Link } from 'react-router-dom'

const MessageConfirmation = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
      <div className='pageNotFoundpage'>
          <div id="MessageSentComponent" className='parents career'>
              <img id="confirmMessageBackgroundImage" width="70%" src={pageNotFoundBackgroundImage} />
              <img id="mobileConfirmMessageBackgroundImage" width="90%" src={mobileViewPageNotFoundBackgroundImage} />
              <div id="page_not_found_error">
                  <div className='messageConfirmed'>
                    <img width="30%" src={messageConfirmed} />
                      
                  </div>

                  <div className='message_description'>
                      <span className='text--blueGradient confirmation_message'>
                          Thank you
                      </span> 
                  <span id="whoops" className='confirmation_message'> for reaching out to us</span> 
                </div>
                      
                  <p className='error_description'>
                  Your form has been submitted. Our team will be contacting you in 24-48 hrs.
                  </p>
                  <div>
                      <Link to="/"
                      >

                          <button className='goBackButton'>Back to Home <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></button>
                          </Link>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default MessageConfirmation