import React from 'react';
import Header1 from '../../Components/Common/Header1/Header1';
import Footer1 from '../../Components/Common/Footer1/Footer1';
import classes from './PrivacyPolicy.module.scss';
import CustomHelmet from '../../Components/CustomHelmet/CustomHelmet';

function PrivacyPolicy() {
    return (
        <>
             <CustomHelmet page="privacypolicy" />
            <Header1 theme={'home'} />
            <main>
                <section>
                    <div className={classes.title_container}>
                        <h1 className={classes.ppHeading}>
                            <span>Privacy Policy</span>
                        </h1>
                    </div>
                </section>
                <section>
                    <div className={classes.body_section}>
                        <div className='container'>
                            <div className={classes.section_title}>
                                INTRODUCTION
                            </div>
                            <p>
                                This document (“Privacy Policy”) is published by
                                virtue of the legal requirement under the
                                provisions of the Information Technology Act,
                                2000 that require intermediaries to publish the
                                rules and regulations, privacy policy and terms
                                for access or usage of their website/mobile app
                                and is primarily governed by Rule 3 (1) of the
                                Information Technology (Intermediaries
                                Guidelines) Rules, 2011 and the Information
                                Technology (Reasonable Security Practices and
                                Procedures and Sensitive Personal Data or
                                Information) Rules, 2011.{' '}
                            </p>
                            <p>
                                The privacy policy covers how Grayquest
                                Education Finance Pvt Ltd www.grayquest.com
                                uses any information that you give when you
                                visit the Grayquest platform. The policy
                                describes what information we collect from you,
                                how we collect this information and what we do
                                with it after we collect it. By using our
                                website, you give consent to the use of your
                                Personal Information by Grayquest in accordance
                                with the terms set out in this Privacy Policy.
                                We may make changes to the Privacy policy from
                                time to time. We will notify you of any material
                                changes to the privacy policy by sending you a
                                notification{' '}
                            </p>
                            <div className={classes.section_title}>
                                Information Collected{' '}
                            </div>
                            <p>
                                Personal Information: We collect personal
                                information which includes but is not limited to
                                name, mailing address, phone number, pan number,
                                Redacted Aadhaar number, bank account number,
                                ITR, Bank Statements, purpose of loan and other
                                financial information. We may ask you to provide
                                additional information about yourself, if
                                required for the purpose of rendering our
                                services. {' '}
                            </p>
                            <p>
                                Grayquest may collect your personal data or
                                information from a variety of sources,
                                including:
                            </p>
                            <ul className={classes.innerList}>
                                <li>
                                    Online and electronic interactions via the
                                    Application, text messaging programs or
                                    applications on third-party service providers.{' '}
                                    third-party service providers
                                </li>
                                <li>
                                    Offline interactions via collecting PDCs (if
                                    required), NACH mandate.{' '}
                                </li>
                            </ul>
                            <p>
                                Non-personal information: We can also collect
                                non-personal information such as IP address,
                                browser type, operating system, and the URL of
                                the previous website you visited.{' '}
                            </p>
                            <div className={classes.section_title}>
                                Use of Information
                            </div>
                            <p>
                                We use the information to understand your credit
                                profile and provide you with a better service.
                                We also might send you promotional emails about
                                new products, special offers or any other
                                information which might be of relevance to you.
                            </p>
                            <p>
                                We will not sell or distribute your information
                                to third parties unless we have your explicit
                                permission to do so.
                            </p>
                            <p>
                                However, the information collected is shared to
                                the following parties to perform their
                                respective actions in order to cater the service
                                requested by the user.{' '}
                            </p>
                            <div className={classes.section_title}>
                                Party Name/Type Action{' '}
                            </div>
                            <table className={classes.table_bordered}>
                                <thead>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            <strong>Services</strong>
                                        </td>
                                        <td className={classes.table_p1}>
                                            <strong>Vendors Empaneled</strong>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            SMS
                                        </td>
                                        <td className={classes.table_p1}>
                                            MSG91
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            Email
                                        </td>
                                        <td className={classes.table_p1}>
                                            Sendgrid
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            WhatsApp
                                        </td>
                                        <td className={classes.table_p1}>
                                            Interakt
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            Bureau
                                        </td>
                                        <td className={classes.table_p1}>
                                            Experian, CIBIL
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            Bank Statement Analysis
                                        </td>
                                        <td className={classes.table_p1}>
                                            Digitap
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            Bank Verification
                                        </td>
                                        <td className={classes.table_p1}>
                                            Karza Technologies
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            KYC
                                        </td>
                                        <td className={classes.table_p1}>
                                            Digitap, Arya.ai ,
                                            HyperVerge
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            NACH
                                        </td>
                                        <td className={classes.table_p1}>
                                            Digio, Razorpay
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            Payment Gateway
                                        </td>
                                        <td className={classes.table_p1}>
                                            Razorpay, Cashfree, Axis Bank UniPG
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            OCR
                                        </td>
                                        <td className={classes.table_p1}>
                                            Hyperverge
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.table_p1}>
                                            PAN Verification
                                        </td>
                                        <td className={classes.table_p1}>
                                            Karza Technologies
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <p>
                                If you believe that any information you have
                                provided to us is either incomplete or
                                incorrect, please write to us at <a href="mailto:support@grayquest.in"> support@grayquest.in </a> to request any changes. We
                                never publicly disclose any personal information
                                related to financial or payment activities or
                                any government identification numbers.{' '}
                            </p>
                            <p>
                                By signifying your acceptance to the provisions
                                of the Privacy Policy and by providing your
                                personal information, you consent to the
                                collection and use of such personal information
                                in accordance with this Privacy Policy,
                                including but not limited to your consent for
                                sharing your personal information as per this
                                Privacy Policy. You specifically agree and
                                consent to Grayquest Education finance Pvt Ltd
                                and its partners for collecting, storing,
                                processing, transferring, and sharing
                                information (including personal information)
                                related to you in the manner set out in this
                                Privacy Policy.
                            </p>
                            <p>
                                All information, including personal information,
                                disclosed by you shall be deemed to be disclosed
                                willingly and without any coercion. No liability
                                pertaining to the authenticity/ genuineness/
                                misrepresentation/ fraud/ negligence, etc. of
                                the information disclosed shall lie on Grayquest
                                nor will Grayquest in any way be responsible to
                                verify any information obtained from you.
                            </p>
                            <div className={classes.section_title}>
                                Information Holding
                            </div>
                            <p>
                                We will securely store the information collected
                                from user excluding the data mentioned in the
                                Archiving / removal clause of the policy until
                                closure of the account with Grayquest, for us to
                                offer better services and products on an ongoing
                                basis.{' '}
                            </p>
                            <div className={classes.section_title}>
                                Cookies usage{' '}
                            </div>
                            <p>
                                A cookie is a small file placed on the hard
                                drive of your computer. Most websites use
                                cookies. We use cookies to track your use of the
                                website and provide you with a more personalized
                                user experience. You can choose either to accept
                                or decline cookies. Most web-browsers
                                automatically accept cookies, but you can change
                                your browser settings to decline cookies as
                                well.
                            </p>
                            <div className={classes.section_title}>
                                Data minimisation
                            </div>
                            <p>
                                The Organisation shall ensure that personal data
                                are adequate, relevant, and limited to what is
                                necessary in relation to the purposes for which
                                they are possessed.
                            </p>
                            <div className={classes.section_title}>
                                DATA RETENTION
                            </div>
                            <ul className={classes.innerListAlphabet}>
                                <li>
                                    We shall retain the information you provide
                                    to facilitate your smooth and uninterrupted
                                    use of the Platform, and (i) to provide,
                                    improve and personalize our services; (ii)
                                    to contact you about your account and give
                                    customer service; (iii) to personalize our
                                    advertising and marketing communications;
                                    and (iv) to prevent, detect, mitigate, and
                                    investigate fraudulent or illegal
                                    activities.
                                </li>
                                <li>
                                    Except as permitted by applicable law, the
                                    information / data stored, retained,
                                    processed and transmitted pursuant to this
                                    Privacy Policy shall reside at a data
                                    storage centre within the geographical
                                    territories of India.
                                </li>
                                <li>
                                    The information / data retained shall
                                    exclude the data mentioned in Data
                                    Destruction clause hereinbelow.
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                DATA DESTRUCTION
                            </div>
                            <p>
                                We do not retain your Personal Information for
                                longer than required for the purpose for which
                                the information may be lawfully used. To ensure
                                that Personal Information is kept for no longer
                                than necessary, Company shall delete the KYC
                                documents like PAN, Aadhaar and other OVD
                                Documents of the user possessed for processing
                                their loan application within 48 (Forty Eight)
                                hours from date of disbursement or rejection of
                                the application.
                            </p>
                            <p>
                                For any other information, we may entertain your
                                request for deletion, however, you may not be
                                able to use our services at all after such
                                deletion.
                            </p>
                            <div className={classes.section_title}>
                                Right to withdraw consent
                            </div>
                            <p>
                                You have the option, at any time while availing
                                our Services or otherwise, to withdraw your
                                consent given to us, for processing your data.
                                In case of withdrawal of your consent, we
                                reserve the option not to provide the Services
                                for which such information was sought. In case
                                the Services are already availed and then you
                                raise a request to withdraw consent, then we
                                have the right to retain all the necessary data
                                required to carry out our operations. The user
                                can exercise the same by mailing us at
                                <a href="mailto:support@grayquest.in"> support@grayquest.in</a>
                            </p>
                            <div className={classes.section_title}>
                                General Provisions
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    We will desist from accessing mobile phone
                                    resources like file and media, contact list,
                                    call logs, telephony functions from borrower
                                    phone resources.
                                </li>
                                <li>
                                    We will ensure that access to camera,
                                    microphone, location or any other facility
                                    necessary for the purpose of on-boarding/
                                    KYC requirements and only with the explicit
                                    consent of the borrower.
                                </li>
                                <li>
                                    We will ensure that no biometric data is
                                    stored/ collected in the systems, unless
                                    allowed under extant statutory guidelines.
                                </li>
                                <li>
                                    We will ensure that all data is stored only
                                    in servers located within India, while
                                    ensuring compliance with statutory
                                    obligations/ regulatory instructions.
                                </li>
                                <li>
                                    We will report security breach to concerned
                                    authority &amp; stakeholders on timely
                                    manner.
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                Due Diligence &amp; Security
                            </div>
                            <p>
                                Data security is our priority. To prevent
                                unauthorised access, we have the required
                                procedures and firewalls (both electronic and
                                physical) to safeguard your data.
                            </p>
                            <div className={classes.section_title}>
                                Steps for handling security breach
                            </div>
                            <ul className={classes.innerList}>
                                <li>
                                    Move quickly to secure the systems and fix
                                    vulnerabilities that may have caused the
                                    breach.
                                </li>
                                <li>
                                    Switch off the servers and change the access
                                    code to prevent additional data loss.
                                </li>
                                <li>
                                    Mobilize the breach response team right away
                                    to prevent additional data loss.
                                </li>
                                <li>
                                    Additional security required will be placed
                                    as and when required.
                                </li>
                                <li>
                                    Securely delete personally identifiable
                                    information (PII) and other sensitive data
                                    when it no longer needed for business
                                    purposes.
                                </li>
                            </ul>
                            <div className={classes.section_title}>
                                Other Sites Links
                            </div>
                            <p>
                                We provide links to other websites within our
                                site. Once you use these links to leave our
                                website, you should understand that we cannot
                                guarantee the privacy of any data that you may
                                have divulged on these websites. You should
                                exercise caution and read the privacy policy of
                                the concerned website before sharing any data.
                            </p>
                            <div className={classes.section_title}>
                                Communications
                            </div>
                            <p>
                                Whatsapp option - 'By Signing Up, the user
                                agrees to receive communication from Grayquest
                                via Whatsapp, Email, Messaging or any other
                                communication channel. Email Soft calls and SMS
                            </p>
                            <div className={classes.section_title}>
                                Changes in Privacy Policy
                            </div>
                            <p>
                                Our Privacy Policy might change from time to
                                time, and Grayquest{' '}
                                <strong className={classes.bolderOffice}>
                                    will update it on the app/website.
                                </strong>
                            </p>
                            <div className={classes.section_title}>
                                Contact Us
                            </div>
                            <p>
                                If you have questions, concerns, or grievances
                                regarding this Privacy Policy, you can email us
                                at  <a href="mailto:support@grayquest.com"> support@grayquest.com </a> or reach out to our
                                grievance officer mentioned below:
                            </p>
                            <br />
                            <p>
                                <span>
                                    <strong className={classes.bolderOffice}>Officer Name:</strong> Mr. Ranjeet
                                    Singh Rawat
                                </span>
                                <br />
                                <span>
                                    <strong className={classes.bolderOffice}>Officer Email:</strong>
                                    <a href='mailto:nodalofficer@grayquest.com'>
                                        {' '}
                                        nodalofficer@grayquest.com
                                    </a>
                                </span>
                            </p>
                            <br />
                            <p>
                                <span>
                                    <strong className={classes.bolderOffice}>Officer Name:</strong> Mr. Garvit
                                    Mathur
                                </span>
                                <br />
                                <span>
                                    <strong className={classes.bolderOffice}>Officer Email:</strong>
                                    <a href='mailto:nodalofficer@grayquest.com'>
                                        {' '}
                                        nodalofficer@grayquest.com
                                    </a>
                                </span>
                            </p>
                            <br />
                            <p>
                                The Grievance Officer can be contacted between
                                10:00 a.m. to 7:00 p.m. from Monday to Saturday
                                except on public holidays.
                            </p>
                            <br />
                            <p className={classes.ppBottom}>
                                We confirm that our privacy policy is compliant
                                with applicabale laws, associated regulations
                                and RBI guidelines
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer1 />
        </>
    );
}

export default PrivacyPolicy;
