import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGqFeaturedBlogDataGet } from '../../../api-cms-client';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const getGqFeaturedBlogDataFetch = createAsyncThunk(
    'getGqFeaturedBlogDataFetch/Get',
    async (body, { rejectWithValue }) => {
        try {
            const response = await fetchGqFeaturedBlogDataGet(body);
            const data = response?.data;
            return data;
        } catch (err) {
            const errObj = {
                type: 'GET_GQ_FEATURED_BLOG_DATA_FETCH_FAILURE',
                error: err,
                message: 'Oops! something went wrong',
            };
            return rejectWithValue(err?.response?.data || errObj);
        }
    }
);

const getGqFeaturedBlogDataFetchSlice = createSlice({
    name: 'getGqFeaturedBlogDataFetch',
    initialState,
    extraReducers: {
        [getGqFeaturedBlogDataFetch.pending]: (state) => {
            state.loading = true;
        },
        [getGqFeaturedBlogDataFetch.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload?.data;
            state.error = null;
        },
        [getGqFeaturedBlogDataFetch.rejected]: (state, { payload }) => {
            state.loading = false;
            state.data = null;
            state.error = payload;
        },
    },
});

export const getGqFeaturedBlogDataFetchSelector = (state) => state.getGqFeaturedBlogDataFetch;
export const getGqFeaturedBlogDataFetchReducer = getGqFeaturedBlogDataFetchSlice.reducer;