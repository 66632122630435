import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomButton, CustomReactSelect } from '../../../Components/atoms';
import { MdOutlineSearch } from "react-icons/md";
import classes from './GileSearchForm.module.scss';
import { toast } from 'react-toastify';
import { gqLandingApiClient } from '../../../api-landing-client/axios';

const GileSearchForm = ({ pageData }) => {
    const { search_block: { gile_search } } = pageData;
    if (!gile_search?.fields) return null;

    // Group
    const [groupList, setGroupList] = useState([]);
    const [groupValue, setGroupValue] = useState(null);
    // Institute
    const [instituteList, setInstituteList] = useState([]);
    const [instituteValue, setInstituteValue] = useState(null);
    // Location
    const [locationList, setLocationList] = useState([]);
    const [locationValue, setLocationValue] = useState(null);
    // EducationType
    const [educationTypeList, setEducationTypeList] = useState([]);
    const [educationTypeValue, setEducationTypeValue] = useState(null);
    // URL 
    const [landingPageUrl, setLandingPageUrl] = useState("");

    const validationSchema = Yup.object({
        group_id: Yup.string().required('Please enter group name'),
        institute_id: Yup.string().required('Please select institute name'),
        location_id: Yup.string().required('Please select location'),
        education_type_id: Yup.string().required('Please select education type'),
	});

    const formik = useFormik({
		initialValues: {
            group_id: pageData?.institute_config?.group_id || '',
            institute_id: pageData?.institute_config?.institute_id || '',
            location_id: pageData?.institute_config?.location_id || '',
            education_type_id: pageData?.institute_config?.education_type_id || '',
		},
		validationSchema,
        enableReinitialize: true,
		onSubmit: (values) => {
            formik.setSubmitting(true);
            console.log("Form values: ", values);
            if (gile_search?.cta_config?.new_window) {
                window.open(landingPageUrl, '_blank').focus();
            } else {
                window.open(landingPageUrl, '_self').focus();
            }
            formik.setSubmitting(false);
        },
	});

    // fetch institute list 
    const fetchInstituteList = async ({ group_id }) => {
        gqLandingApiClient({
            method: "GET",
            url: `/v1/institute/gile-search/institutes?group_id=${group_id}`,
        }).then((res) => {
            if (res?.data?.data) {
                console.log("fetchInstituteList", res?.data?.data);
                setInstituteList(res?.data?.data || []);
            } else {
                toast.error("Oops! Something went wrong");
            }
        }).catch((err) => {
            console.error("fetchInstituteList error", err?.response?.data);
            toast.error(err?.response?.data?.message || "Oops! Something went wrong");
        });
    }

    // fetch Location list 
    const fetchLocationList = async ({ group_id, institute_id }) => {
        gqLandingApiClient({
            method: "GET",
            url: `/v1/institute/gile-search/locations?group_id=${group_id}&institute_id=${institute_id}`,
        }).then((res) => {
            if (res?.data?.data) {
                console.log("fetchLocationList", res?.data?.data);
                setLocationList(res?.data?.data || []);
            } else {
                toast.error("Oops! Something went wrong");
            }
        }).catch((err) => {
            console.error("fetchLocationList error", err?.response?.data);
            toast.error(err?.response?.data?.message || "Oops! Something went wrong");
        });
    }

    // fetch Education Type list 
    const fetchEducationTypeList = async ({ group_id, institute_id, location_id }) => {
        gqLandingApiClient({
            method: "GET",
            url: `/v1/institute/gile-search/education-types?group_id=${group_id}&institute_id=${institute_id}&location_id=${location_id}`,
        }).then((res) => {
            if (res?.data?.data) {
                console.log("fetchEducationTypeList", res?.data?.data);
                setEducationTypeList(res?.data?.data || []);
            } else {
                toast.error("Oops! Something went wrong");
            }
        }).catch((err) => {
            console.error("fetchEducationTypeList error", err?.response?.data);
            toast.error(err?.response?.data?.message || "Oops! Something went wrong");
        });
    }

    // fetch Landing Pages Data
    const fetchLandingPagesData = async ({ group_id, institute_id, location_id, education_type_id }) => {
        gqLandingApiClient({
            method: "GET",
            url: `/v1/institute/search-lp?group_id=${group_id}&institute_id=${institute_id}&location_id=${location_id}&education_type_id=${education_type_id}`,
        }).then((res) => {
            if (res?.data?.data) {
                console.log("fetchLandingPagesData", res?.data?.data);
                const lpdata = res.data.data;
                if (lpdata?.length > 0) {
                    setLandingPageUrl(lpdata[0]?.url);
                }
            } else {
                toast.error("Oops! Something went wrong");
            }
        }).catch((err) => {
            console.error("fetchLandingPagesData error", err?.response?.data);
            toast.error(err?.response?.data?.message || "Oops! Something went wrong");
        });
    }

    const onClear = async (e, context) => {
        try {
            const { action, name } = context || {};
            if (action === 'clear') {
                switch (context.name) { 
                    case 'group_id':
                        setGroupValue(null);
                        await formik.setFieldValue('group_id', '');
                        await formik.setFieldValue('institute_id', '');
                        setInstituteValue(null);
                        await formik.setFieldValue('location_id', '');
                        setLocationValue(null);
                        await formik.setFieldValue('education_type_id', '');
                        setEducationTypeValue(null);
                        break;
                    case 'institute_id':
                        setInstituteValue(null);
                        await formik.setFieldValue('institute_id', '');
                        await formik.setFieldValue('location_id', '');
                        setLocationValue(null);
                        await formik.setFieldValue('education_type_id', '');
                        setEducationTypeValue(null);
                        break;
                    case 'location_id':
                        setLocationValue(null);
                        await formik.setFieldValue('location_id', '');
                        await formik.setFieldValue('education_type_id', '');
                        setEducationTypeValue(null);
                        break;
                    case 'education_type_id':
                        setEducationTypeValue(null);
                        await formik.setFieldValue('education_type_id', '');
                        break;
                    default:
                        break;
                }
            }
        } catch (err) {
            console.error('StudentDetailsOnFieldClear', err)
        }
    }

    const handleReactSelectChange = async (e, context) => {
        try {

            console.log("context.action", context)
            if (context.action === 'clear') {
                onClear(e, context);
            } else {
                console.log("react select event value: : ", e.value);
                switch (context.name) { 
                    case 'group_id':
                        setGroupValue(e);
                        await formik.setFieldValue('group_id', e.value);
                        await formik.setFieldValue('institute_id', '');
                        setInstituteValue(null);
                        await fetchInstituteList({ group_id: e.value });
                        await formik.setFieldValue('location_id', '');
                        setLocationValue(null);
                        await formik.setFieldValue('education_type_id', '');
                        setEducationTypeValue(null);
                        break;
                    case 'institute_id':
                        setInstituteValue(e);
                        await formik.setFieldValue('institute_id', e.value);
                        await formik.setFieldValue('location_id', '');
                        setLocationValue(null);
                        await fetchLocationList({ group_id: groupValue?.value, institute_id: e.value });
                        await formik.setFieldValue('education_type_id', '');
                        setEducationTypeValue(null);
                        break;
                    case 'location_id':
                        setLocationValue(e);
                        await formik.setFieldValue('location_id', e.value);
                        await formik.setFieldValue('education_type_id', '');
                        setEducationTypeValue(null);
                        await fetchEducationTypeList({ group_id: groupValue?.value, institute_id: instituteValue?.value, location_id: e.value });
                        break;
                    case 'education_type_id':
                        await formik.setFieldValue('education_type_id', e.value);
                        setEducationTypeValue(e);
                        await fetchLandingPagesData({
                            group_id: groupValue?.value,
                            institute_id: instituteValue?.value,
                            location_id: locationValue?.value,
                            education_type_id: e.value,
                        });
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error("handleReactSelectChange error", error);
        }
    };
    

    const handleGroupInputChange = (e) => {
        const inputValue = e.trim();
        console.log("group input value", inputValue);
        // if (inputValue === "" && groupValue && Object.keys(groupValue).length === 0) {
        //     setGroupList([]);
        // }
    }

    useEffect(() => {
        if (pageData?.institute_config?.group_id && pageData?.institute_config?.group_label) {
            setGroupValue({
                label: pageData.institute_config.group_label,
                value: pageData.institute_config.group_id
            });
            if (pageData?.institute_config?.institute_id && pageData?.institute_config?.institute_label) {
                setInstituteValue({
                    label: pageData.institute_config.institute_label,
                    value: pageData.institute_config.institute_id
                });
                if (pageData?.institute_config?.location_id && pageData?.institute_config?.location_label) {
                    setInstituteValue({
                        label: pageData.institute_config.location_label,
                        value: pageData.institute_config.location_id
                    });
                    if (pageData?.institute_config?.education_type_id && pageData?.institute_config?.education_type_label) {
                        setInstituteValue({
                            label: pageData.institute_config.education_type_label,
                            value: pageData.institute_config.education_type_id
                        });
                    } else {
                        fetchEducationTypeList({ 
                            group_id: pageData.institute_config.group_id, 
                            institute_id: pageData.institute_config.institute_id, 
                            location_id: pageData.institute_config.location_id, 
                        });    
                    }
                } else {
                    fetchLocationList({ 
                        group_id: pageData.institute_config.group_id, 
                        institute_id: pageData.institute_config.institute_id, 
                    });
                }
            } else {
                fetchInstituteList({ group_id: pageData.institute_config.group_id });
            }
        }
    }, [])
    // TODO: FIXME: 4 fields => visible false 
    return (
        <form className={classes.GileSearchForm} onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-lg-6 mx-auto">
                    {(gile_search?.fields?.group_field?.visible && !pageData?.institute_config?.group_id) ? (
                        <div className={classes.FormFieldCol}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="group_id">{gile_search?.fields?.group_field?.label || 'Group'} <span className="required">*</span></label>
                                    <CustomReactSelect
                                        inputId="group_id"
                                        name="group_id"
                                        icon={<MdOutlineSearch />}
                                        maxMenuHeight={200}
                                        onChange={handleReactSelectChange}
                                        onInputChange={handleGroupInputChange}
                                        // onKeyDown={handleInstituteInputChange}
                                        options={groupList || []}
                                        value={groupValue || null}
                                        placeholder={gile_search?.fields?.group_field?.placeholder || "Search for group"}
                                        noOptionsText={groupList.length === 0 ? "Search for group (enter 3 or more characters)" : "No options"}
                                        showDisabledIcon
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable
                                    />
                                    {formik.touched.group_id && formik.errors.group_id ? (
                                        <span className="form-error">
                                            {gile_search?.fields?.group_field?.label ? `Please enter ${gile_search.fields.group_field.label}` : <>{formik.errors.group_id}</>}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {(gile_search?.fields?.institute_field?.visible && !pageData?.institute_config?.institute_id) ? (
                        <div className={classes.FormFieldCol}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="institute_id">{gile_search?.fields?.institute_field?.label || "Institute"} <span className="required">*</span></label>
                                    <CustomReactSelect
                                        inputId="institute_id"
                                        name="institute_id"
                                        icon={<MdOutlineSearch />}
                                        maxMenuHeight={200}
                                        onChange={handleReactSelectChange}
                                        options={instituteList || []}
                                        value={instituteValue || null}
                                        placeholder={gile_search?.fields?.institute_field?.placeholder || "Search for institute"}
                                        showDisabledIcon
                                        isDisabled={!groupValue?.value}
                                        isLoading={false}
                                        isClearable
                                    />
                                    {formik.touched.institute_id && formik.errors.institute_id ? (
                                        <span className="form-error">
                                            {gile_search?.fields?.institute_field?.label ? `Please select ${gile_search.fields.institute_field.label}` : <>{formik.errors.institute_id}</>}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {(gile_search?.fields?.location_field?.visible && !pageData?.institute_config?.location_id) ? (
                        <div className={classes.FormFieldCol}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="location_id">{gile_search?.fields?.location_field?.label || "Location"} <span className="required">*</span></label>
                                    <CustomReactSelect
                                        inputId="location_id"
                                        name="location_id"
                                        icon={<MdOutlineSearch />}
                                        maxMenuHeight={200}
                                        onChange={handleReactSelectChange}
                                        options={locationList || []}
                                        value={locationValue || null}
                                        placeholder={gile_search?.fields?.location_field?.placeholder || "Search for location"}
                                        showDisabledIcon
                                        isDisabled={!instituteValue?.value}
                                        isLoading={false}
                                        isClearable
                                    />
                                    {formik.touched.location_id && formik.errors.location_id ? (
                                        <span className="form-error">
                                            {gile_search?.fields?.location_field?.label ? `Please select ${gile_search.fields.location_field.label}` : <>{formik.errors.location_id}</>}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {(gile_search?.fields?.education_type_field?.visible && !pageData?.institute_config?.education_type_id) ? (
                        <div className={classes.FormFieldCol}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="education_type_id">{gile_search?.fields?.education_type_field?.label || "Education Type"} <span className="required">*</span></label>
                                    <CustomReactSelect
                                        inputId="education_type_id"
                                        name="education_type_id"
                                        icon={<MdOutlineSearch />}
                                        maxMenuHeight={200}
                                        onChange={handleReactSelectChange}
                                        options={educationTypeList || []}
                                        value={educationTypeValue || null}
                                        placeholder={gile_search?.fields?.education_type_field?.placeholder || "Search for education type"}
                                        showDisabledIcon
                                        isDisabled={!locationValue?.value}
                                        isLoading={false}
                                        isClearable
                                    />
                                    {formik.touched.education_type_id && formik.errors.education_type_id ? (
                                        <span className="form-error">
                                            {gile_search?.fields?.education_type_field?.label ? `Please select ${gile_search.fields.education_type_field.label}` : <>{formik.errors.education_type_id}</>}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {(gile_search?.fields?.group_field?.visible || 
                        gile_search?.fields?.institute_field?.visible ||
                        gile_search?.fields?.group_field?.visible || 
                        gile_search?.fields?.education_type_field?.visible) ? (
                            <div className={classes.FormFieldCol}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={classes.FormAction}>
                                            <CustomButton
                                                type="submit"
                                                text={gile_search?.cta_config?.label || "Proceed"}
                                                variant={(!formik.isValid && !formik.dirty) ? 'outline' : ''}
                                                className={classes.SubmitButton}
                                                isLoading={formik.isSubmitting}
                                                isDisabled={(formik.isSubmitting || !(formik.isValid && formik.dirty) || !landingPageUrl)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                </div>
            </div>
        </form>
    )
}

GileSearchForm.propTypes = {}

export default GileSearchForm;