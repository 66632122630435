import React, { useCallback, useEffect, useState } from 'react';
import './TextAnimation.css';

const TextAnimation = () => {
    const [text, setText] = useState('university');
    const toRotate = ['school', 'college', 'university'];
    const [textIndex, setTextIndex] = useState(0);

    const shuffle = useCallback(() => {
        setText(toRotate[textIndex]);
        setTextIndex(textIndex >= 2 ? 0 : textIndex + 1);
        console.log()
    }, [textIndex]);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 1500);
        return () => clearInterval(intervalID);
    }, [shuffle]);

    return (
        <>
            {textIndex===0?   <span
                className={`text--pinkGradient ${
                    textIndex === 0 ? 'text-in' : ''
                }`}
            >
                {text}
            </span>  : null }
           
            {textIndex===1?   <span
                className={`text--pinkGradient ${
                    textIndex === 1 ? 'text-in' : ''
                }`}
            >
                {text}
            </span>  : null }
           
            {textIndex===2?   <span
                className={`text--pinkGradient ${
                    textIndex === 2 ? 'text-in' : ''
                }`}
            >
                {text}
            </span>  : null }
           
        </>
    );
};

export default TextAnimation;
