import React from 'react';
import './Footer1.css';
import { CUSTOMER_BASE_URL, INSTITUTE_BASE_URL } from '../../../../constants';
import footerLogo from '../../../assets/images/footer/footerLogo.svg';
import footerEmail from '../../../assets/images/footer/footer-email.svg';
import footerlinked from '../../../assets/images/footer/footer-linked.svg';
import footerTwitter from '../../../assets/images/footer/footer-twitter.svg';
import footerfb from '../../../assets/images/footer/footer-fb.svg';
import { Link } from 'react-router-dom';

function Footer1() {
    return (
        <>
            <footer className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <a href='#' className='footer__logo'>
                                <img
                                    className='img-fluid'
                                    src={footerLogo}
                                    alt='grayQuest logo'
                                />
                                <span className='sr-only'>Footer logo</span>
                            </a>
                            <p className='text--regular footer__desc'>
                                If you are a parent/guardian of a student
                                studying in our partner Institute or if your
                                Institute name is available in our application
                                form, you are eligible for the plan.
                            </p>
                        </div>
                        <div className='col-lg-6'>
                            <div className='footer__social'>
                                {/* <a href="#" target="_blank">
                  <img
                    className="img-fluid"
                    src={footerEmail}
                    alt="email icon"
                  />
                  <span className="sr-only">Email link</span>
                </a> */}
                                <a
                                    href='
https://www.linkedin.com/company/grayquest/
'
                                    target='_blank'
                                >
                                    <img
                                        className='img-fluid'
                                        src={footerlinked}
                                        alt='linked in icon'
                                    />
                                    <span className='sr-only'>
                                        LinkedIn link
                                    </span>
                                </a>
                                {/* <a href="#" target="_blank">
                  <img
                    className="img-fluid"
                    src={footerTwitter}
                    alt="twitter icon"
                  />
                  <span className="sr-only">Twitter link</span>
                </a> */}
                                <a
                                    href='https://www.facebook.com/grayquest'
                                    target='_blank'
                                >
                                    <img
                                        className='img-fluid'
                                        src={footerfb}
                                        alt='fb icon'
                                    />
                                    <span className='sr-only'>
                                        facebook link
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='footer__bottom'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-12'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='footer__item'>
                                            <p className='footer__title text--semi'>
                                                Company
                                            </p>
                                            <ul>
                                                <li className='footer__list'>
                                                    <Link
                                                        to='/'
                                                        className='footer__link'
                                                    >
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className='footer__list'>
                                                    <Link
                                                        to='/about-us'
                                                        className='footer__link'
                                                    >
                                                        About us
                                                    </Link>
                                                </li>
                                                <li className='footer__list'>
                                                    <Link
                                                        to='/careers'
                                                        className='footer__link'
                                                    >
                                                        Careers
                                                    </Link>
                                                </li>
                                                {/* <li className='footer__list'>
                                                    <Link
                                                        to='/blogs'
                                                        className='footer__link'
                                                    >
                                                        Blogs
                                                    </Link>
                                                </li> */}
                                                <li className='footer__list'>
                                                    <Link
                                                        to='/contact'
                                                        className='footer__link'
                                                    >
                                                        Contact
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='footer__item'>
                                            <p className='footer__title text--semi'>
                                                For Partners
                                            </p>
                                            <ul>
                                                <li className='footer__list'>
                                                    <Link
                                                        to='/institute'
                                                        className='footer__link'
                                                    >
                                                        GQ for institute
                                                    </Link>
                                                </li>
                                                <li className='footer__list'>
                                                    <a
                                                        href={
                                                            INSTITUTE_BASE_URL
                                                        }
                                                        className='footer__link'
                                                        target='_blank'
                                                    >
                                                        Institute login
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='footer__item'>
                                            <p className='footer__title text--semi'>
                                                For Parents
                                            </p>
                                            <ul>
                                                <li className='footer__list'>
                                                    <Link
                                                        to='/parents'
                                                        className='footer__link'
                                                    >
                                                        GQ for parents
                                                    </Link>
                                                </li>
                                                <li className='footer__list'>
                                                    <a
                                                        href={CUSTOMER_BASE_URL}
                                                        className='footer__link'
                                                        target='_blank'
                                                    >
                                                        Parent login
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-12'>
                                <div className='mobile-border'>
                                    <div className='row'>
                                        <div className='col-md-6 col-12'>
                                            <div className='footer__item'>
                                                <p className='footer__title text--semi'>
                                                    Reach us at
                                                </p>
                                                <div className='footer__sub'>
                                                    <p className='footer__sub-title text--semi'>
                                                        Customer support
                                                    </p>
                                                    <a className='footer__sub-text'>
                                                        {/* 022-248931822 */}
                                                        +91 9324507084
                                                    </a>
                                                </div>
                                                <div className='footer__sub'>
                                                    <p className='footer__sub-title text--semi'>
                                                        Timings
                                                    </p>
                                                    <p className='footer__sub-text'>
                                                        10:00 AM - 7:00 PM (Mon
                                                        - Sat)
                                                    </p>
                                                </div>
                                                <div className='footer__sub'>
                                                    <p className='footer__sub-title text--semi'>
                                                        Email us at
                                                    </p>
                                                    <a
                                                        href='mailto:support@grayquest.com'
                                                        className='footer__sub-text'
                                                    >
                                                        support@grayquest.com
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-12 mt-md-0 mt-5'>
                                            <div className='footer__item'>
                                                <p className='footer__title text--semi'>
                                                    Registered Address
                                                </p>
                                                <p className='footer__address text--regular text--white'>
                                                    Famous Studios, 20, Dr.
                                                    Elijah Moses Rd, Gandhi
                                                    Nagar, Upper Worli,
                                                    Mahalakshmi, Mumbai,
                                                    Maharashtra 400011
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer__copy'>
                        <div className='left'>
                            <p>
                                &copy; 2023 Grayquest Education Finance Pvt Ltd
                            </p>
                        </div>
                        <div className='right'>
                            <ul>
                                <li className='footer__copy-list'>
                                    <Link
                                        to='/lending-partners'
                                        className='footer__copy-link'
                                    >
                                        Our Lending Partners
                                    </Link>
                                </li>
                                <li className='footer__copy-list'>
                                    <Link
                                        to='/terms-and-conditions'
                                        className='footer__copy-link'
                                    >
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li className='footer__copy-list'>
                                    <Link
                                        to='/terms-of-service'
                                        className='footer__copy-link'
                                    >
                                        Terms of use
                                    </Link>
                                </li>
                                <li className='footer__copy-list'>
                                    <Link
                                        to='/privacy-policy'
                                        className='footer__copy-link'
                                    >
                                        Privacy policy
                                    </Link>
                                </li>
                                <li className='footer__copy-list'>
                                    <Link
                                        to='/grievance-officer'
                                        className='footer__copy-link'
                                    >
                                        Grievance officer
                                    </Link>
                                </li>
                                {/* <li className='footer__copy-list'>
                                    <a
                                        href='https://sachet.rbi.org.in'
                                        className='footer__copy-link'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sachet portal RBI
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer1;
