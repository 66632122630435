// dependancies
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

import classes from './JobDescription.module.scss';
// utils
import { dateFormatter } from '../../../utils/utils';

// components
import {
    CustomButton,
    SpinnerLoader,
    CustomSlider,
} from '../../../Components/atoms';
import CustomHelmet from '../../../Components/CustomHelmet/CustomHelmet';
import { Header1, Footer1 } from '../../../Components/Common';

// images
import gqLogo from '../../../assets/images/utility_images/gq_logo.svg';
import emailIcon from '../../../assets/images/contact/email-icon.svg';

// api
import {
    getGqCareerJobDataFetch,
    getGqCareerJobDataFetchSelector,
} from '../../../reduxtoolkit/slices/gq-career';

const JobDescription = () => {
    const dispatch = useDispatch();
    const { job_role } = useParams();

    const {
        data: getGqCareerJobDataFetchData,
        loading: getGqCareerJobDataFetchLoading,
    } = useSelector(getGqCareerJobDataFetchSelector);

    const getInitialData = async () => {
        try {
            let payload = '';
            if (job_role) {
                payload = job_role;
            }
            const getGqCareerJobDataFetchRes = await dispatch(
                getGqCareerJobDataFetch(payload)
            ).unwrap();
            return getGqCareerJobDataFetchRes;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getInitialData();
    }, []);

    return (
        <>
            <CustomHelmet page='career' />
            <Header1 theme={'careers'} />
            <main className={classes.JobPageContainer}>
                <div className={`container`}>
                    {getGqCareerJobDataFetchLoading ? (
                        <div className={classes.Loader}>
                            <SpinnerLoader
                                size='large'
                                trackColor='#d5d5d5'
                                spinnerColor='#3e54ce'
                            />
                        </div>
                    ) : getGqCareerJobDataFetchData ? (
                        <>
                            <section
                                className={` ${classes.JobContainer} mx-auto container`}
                            >
                                <div className={classes.JobRole}>
                                    {`Job Title:
                                ${getGqCareerJobDataFetchData[0]?.attributes?.job_role}`}
                                </div>
                                <div className={classes.JobDepartment}>
                                    {`Department:
                                ${getGqCareerJobDataFetchData[0]?.attributes?.department?.data?.attributes?.label}`}
                                </div>
                                <div className={classes.JobDetail}>
                                    <div className={classes.JobText}>
                                        {`Location:
                                ${getGqCareerJobDataFetchData[0]?.attributes?.location}`}
                                    </div>
                                    <div className={classes.JobText}>
                                        {`Total Openings:
                                ${getGqCareerJobDataFetchData[0]?.attributes?.total_openings}`}
                                    </div>
                                    <div className={classes.JobText}>
                                        {`Job Type:
                                ${getGqCareerJobDataFetchData[0]?.attributes?.job_type}`}
                                    </div>
                                    <div className={classes.JobText}>
                                        {`Experience:
                                ${getGqCareerJobDataFetchData[0]?.attributes?.experience} yrs`}
                                    </div>
                                </div>
                                <div className={classes.JobMetaData}>
                                    <img src={gqLogo} alt='author' />
                                    <span className={classes.JobGrayquest}>
                                        Grayquest
                                    </span>
                                    <span className={classes.JobPublishedDate}>
                                        {dateFormatter(
                                            getGqCareerJobDataFetchData[0]
                                                ?.attributes?.publishedAt
                                        )}
                                    </span>
                                </div>

                                <ReactMarkdown
                                    className={classes.Markdown}
                                    remarkPlugins={[remarkGfm]}
                                    children={
                                        getGqCareerJobDataFetchData[0]?.attributes
                                            ?.description
                                    }
                                />
                            </section>
                            <section className={classes.ConnectToUs}>
                                <div className={classes.ConnectToUsContainer}>
                                    <p className={classes.ConnectToUsPara}>
                                        <span
                                            className={classes.ConnectToUsText}
                                        >
                                            To apply, send us your resume with title of the role your’re interested  in to
                                        </span>
                                    </p>

                                    <p className={classes.ConnectToUsPara}>
                                        <img
                                            className='img-fluid'
                                            src={emailIcon}
                                            alt='email icon'
                                        />
                                        <a
                                            href='mailto:support@grayquest.com'
                                            className={classes.ConnectToUsText}
                                        >
                                            director@grayquest.com
                                        </a>
                                    </p>
                                </div>
                            </section>
                        </>
                    ) : (
                        <div className={classes.NoJobAvailable}>
                            <h5>Job Not Found!</h5>
                        </div>
                    )}
                </div>
            </main>
            <Footer1 />
        </>
    );
};

export default JobDescription;
